export const nameValidator = (value, minLength=2, maxLength=200) => {
    if (value.length < minLength) return `Name must be at least ${minLength} characters long`;
    if (value.length > maxLength) return `Name must be less than ${maxLength} characters long`;
    return false;
};

export const emailValidator = value => {
    if (!/^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]+$/.test(value))
        return "Invalid email address";
    return false;
};

export const numberValidator = value => {
    if (isNaN(value)) return "Must be a number";
    return false;
}

export const phoneNumberValidator = value => {
    // Check if the cleaned number is 10 digits long (for US numbers)
    if (value.replace(/\D/g, '').length === 10) return false
    // Check if the cleaned number starts with +1 and is 11 digits long (for international numbers)
    if (value.replace(/\D/g, '').startsWith('1') && value.replace(/\D/g, '').length === 11)
      return false;
    return "Invalid phone number";
  }