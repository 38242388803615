import React, {useState} from 'react';
import { useParams } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { TableVirtuoso } from 'react-virtuoso';
import { Grid, Box, ButtonGroup, Tooltip, Typography } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import DateDialog from './DateDialog';
import LoadingButton from '@mui/lab/LoadingButton';
import { updateRosterMember } from '../../endpoints/annualReports';
import { useApiSend } from '../../utils/httpClient';
import IconButton from '@mui/material/IconButton';
import RosterMemberModal from './RosterMemberDetail';


const columns = [
  {
    width: 315,
    label: 'Action',
    dataKey: 'action',
    skip: true,
    link: true
  },
  {
    width: 120,
    label: 'Name',
    dataKey: 'full_name',
  },
  {
    width: 120,
    label: 'Member ID',
    dataKey: 'imis_id',
  },
  {
    width: 10,
    label: null,
    dataKey: 'info',
    skip: true
  },
];

const VirtuosoTableComponents = {
  Scroller: React.forwardRef((props, ref) => (
    <TableContainer component={Paper} {...props} ref={ref} />
  )),
  Table: (props) => (
    <Table {...props} sx={{ borderCollapse: 'separate', tableLayout: 'fixed' }} />
  ),
  TableHead: React.forwardRef((props, ref) => <TableHead {...props} ref={ref} />),
  TableRow,
  TableBody: React.forwardRef((props, ref) => <TableBody {...props} ref={ref} />),
};

function fixedHeaderContent() {
  return (
    <TableRow>
      {columns.map((column) => (
        <TableCell
          key={column.dataKey}
          variant="head"
          align={column.dataKey === 'action' ? 'left' : 'right'}
          style={{ width: column.width }}
          sx={{
            backgroundColor: 'background.paper',
          }}
        >
          {column.label}
        </TableCell>
      ))}
    </TableRow>
  );
}

export default function RosterToSort({rowData, refetch, refetchParent, editable}) {
    const [date, setDate] = useState(null);
    const [dateRequested, setDateRequested] = useState(false);
    const [action, setAction] = useState(null);
    const [memberId, setMemberId] = useState(null);
    const [inProgress, setInProgress] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [dateRestricted, setDateRestricted] = useState(true);
    let { id } = useParams();

    const { mutate } = useApiSend(
      updateRosterMember,
      (data) => {
          setAction(null)
          setMemberId(null)
          setDate(null)
          setInProgress(false)
          refetch()
          if (data?.parent_update) {
            refetchParent()
          }
      },
      (error) => console.log(error),
    )

    const buildRequest = (dateValue=null) => {
      let request = {
        rosterId: id,
        memberId: memberId,
      }
      switch (action) {
        case 'transfer':
          request['transfer_out'] = true
          request['transfer_date'] = dateValue.format('YYYY-MM-DD')
          break;
        case 'passed':
          request['passed'] = true
          request['passed_date'] = dateValue.format('YYYY-MM-DD')
          break;
        default:
          break;
      }
      return request
    }

    const handleClose = (dateValue) => {
        setDateRequested(false);
        if (dateValue) {
            // perform action
            mutate(buildRequest(dateValue))
        } else {
          setInProgress(false)
        }
    }

    const handleButtonClick = (action, member) => {
        setMemberId(member.id)
        setInProgress(true)
        let actionsRequiringDate = [
          'transfer', 'passed'
        ]
        if (actionsRequiringDate.includes(action)) {
          if (action === 'passed' && !['AA', 'AR'].includes(member.imis_status)) {
            setAction(action)
            setDateRestricted(false)
            setDateRequested(true)
          } else {
            setAction(action)
            setDateRestricted(true)
            setDateRequested(true)
          }
        } else {
            if (action === 'active') {
                mutate({
                    rosterId: id,
                    memberId: member.id,
                    is_active: true
                })
            } else if (action === 'inactive') {
                mutate({
                    rosterId: id,
                    memberId: member.id,
                    is_inactive: true
                })
            }
        }        
    }

    const handleOpenModal = (id) => {
      setMemberId(id)
      setShowModal(true)
    }

    const handleCloseModal = () => {
      setMemberId(null)
      setShowModal(false)
    }


    const rowContent = (_index, row) => {
        return (
            <React.Fragment>
                <TableCell
                  key={'action'}
                  align={'left'}
                >
                  <ButtonGroup size="small" variant='outlined'>
                    <Tooltip title="Places member in Active status." placement='top-end'>
                      <LoadingButton
                          key='active'
                          color="green"
                          sx={{ fontWeight: 'bold' }}
                          disabled={!editable}
                          loading={row.id === memberId ? inProgress: false}
                          onClick={() => handleButtonClick('active', row)}
                      >
                          Active
                      </LoadingButton>
                    </Tooltip>
                    <Tooltip title="Places member in Inactive status." placement='top-end'>
                      <LoadingButton 
                        key='inactive'
                        sx={{ fontWeight: 'bold' }}
                        disabled={!editable}
                        loading={row.id === memberId ? inProgress: false}
                        onClick={() => handleButtonClick('inactive', row)}
                      >
                        Inactive
                      </LoadingButton>
                    </Tooltip>
                    <Tooltip title="Transfers member out of the chapter." placement='top-end'>
                      <LoadingButton
                        key='transfer'
                        color="blue"
                        disabled={!editable}
                        loading={row.id === memberId ? inProgress: false}
                        sx={{ fontWeight: 'bold' }}
                        onClick={() => handleButtonClick('transfer', row)}
                      >
                        Transferred
                      </LoadingButton>
                    </Tooltip>
                    <Tooltip title="Member has passed away." placement='top-end'>
                      <LoadingButton
                        key='passed'
                        color="black"
                        disabled={!editable}
                        loading={row.id === memberId ? inProgress: false}
                        sx={{ fontWeight: 'bold' }}
                        onClick={() => handleButtonClick('passed', row)}
                      >
                        Passed
                      </LoadingButton>
                    </Tooltip>
                  </ButtonGroup>
                  <DateDialog
                    open={dateRequested}
                    dateRestricted={dateRestricted}
                    onClose={handleClose}
                    dateValue={date}
                    action={action}/>
                </TableCell>
              {columns.filter((item) => !item.skip).map((column) => (
                <TableCell
                  key={column.dataKey}
                  align={'right'}
                >
                  {row[column.dataKey]}
                </TableCell>
              ))}
              <TableCell
                  key={'info'}
                  align={'right'}
                >
                  <IconButton onClick={() => handleOpenModal(row.id)}>
                    <InfoOutlinedIcon color="primary"/>
                  </IconButton>
                </TableCell>
            </React.Fragment>
          );
    }
    return (
        <Grid container>
            <Grid item xs={12} padding>
              <Typography variant="body1" color="primary">
                Please select the status for each member below.
              </Typography>
              <Typography variant="body1" color="primary">
                Once a status is selected, the member will automatically move to the corresponding category shown on the tabs above.
              </Typography>
              <Typography variant="body1" color="primary">
                Once all members have been assigned a status, the Current Active Members tab will disappear and you will proceed to the Inactive Members tab and continue working tabs from left to right.
              </Typography>
              <Typography variant="body1" color="primary">
                If an active member is missing after working the tabs in order, she can be added on the Active Members as of March 1 tab.
              </Typography>
              <Typography variant="body1" color="primary">
                Use the Member Update form for contact information updates. If membership dates are incorrect, please contact the membership department.
              </Typography>
              
            </Grid>
            <Grid item xs={12} padding>
                <Box sx={{ height: 500 }}>
                    <TableVirtuoso
                        data={rowData}
                        components={VirtuosoTableComponents}
                        fixedHeaderContent={fixedHeaderContent}
                        itemContent={rowContent}
                    />
                </Box>
            </Grid>
            <RosterMemberModal
              modalOpen={showModal}
              setModalOpen={handleCloseModal}
              userId={memberId} />
        </Grid>
  );
}