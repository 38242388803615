import React, {useEffect, useState} from 'react';
import { Grid, Typography } from '@mui/material';
import CheckoutForm from './CheckoutForm';
import Finish from './Finish';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useApiSend } from '../../utils/httpClient';
import { getPaymentIntent } from '../../endpoints/payments';
import { useSearchParams } from "react-router-dom";

// Public key for stripe
// const stripePromise = loadStripe('pk_test_51Q0khFP3MWgMPR32lfnPOnDxzPTUvf94RGTXx3RigY8dDGhT6KUGgMRBBmM5X6DEZnwZZP3DZePCnEkDEaMIgJ5K0064TG4zGC');
const stripePromise = loadStripe('pk_live_51Q0qbhCTZOsP0uqd1kWxWHWUIMhGpvOzOSq7VxMwCeTduZDEE3JrWJ79CmaScidRVN53Vyni1xzPyrzh4ZDdaKy000UCHMayNb')

export default function Billing({donationId, step, handleBack, donationObject}) {

    let [searchParams, ] = useSearchParams()

    const [clientSecret, setClientSecret] = useState(null)
    const [getClientSecretError, setGetClientSecretError] = useState(false)
    const [localDonationId, setLocalDonationId] = useState(null)

    const options = {}

    const { mutate } = useApiSend(
        getPaymentIntent,
        (data) => {
            setClientSecret(data.client_secret)
            setGetClientSecretError(false)
        },
        (error) => {
            console.error(error)
            setGetClientSecretError(true)
        },
    )

    useEffect(() => {
        if (!clientSecret && getClientSecretError === false && step === 2 && donationObject) {
            mutate({
                "amount": donationObject.amount,
                "donation_id": donationId || localDonationId
            })
        }
    }, [clientSecret, getClientSecretError, step, donationObject, donationId, localDonationId, mutate])

    useEffect(() => {
        if (step === 3) {
            setLocalDonationId(searchParams.get('donationId'))
            setClientSecret(searchParams.get('payment_intent_client_secret'))
        }
    }, [step, searchParams])
    if (!donationObject && step === 2) {
        return (
            <Grid container>
                <Grid item xs={12}>
                    <Typography>Loading...</Typography>
                </Grid>
            </Grid>
        )
    }
    if (!clientSecret) {
        return (
            <Grid container>
                <Grid item xs={12}>
                    {getClientSecretError && <Typography>Error setting up card information!</Typography>}
                    <Typography>Loading...</Typography>
                </Grid>
            </Grid>
        )
    } else {
        options.clientSecret = clientSecret
        return (
            <Elements stripe={stripePromise} options={options}>
                {step === 2 && (
                    <Grid container>
                        <Grid item xs={12} textAlign={'center'}>
                            <CheckoutForm
                                goBack={handleBack}
                                donationId={donationId} />
                        </Grid>
                            
                    </Grid>)}
                {step === 3 && (
                    <Finish
                        donationId={localDonationId}/>)}
            </Elements>
        )

    }
}