import React, {useEffect, useState} from 'react';
import { Grid, Typography, Button, ButtonGroup, TextField, Select, Skeleton, FormControl, InputLabel,
    MenuItem, Divider, Dialog, DialogActions, DialogContent, DialogTitle, DialogContentText, Box,
    Autocomplete, ListItem, ListItemIcon, ListItemText, FormHelperText, Paper, List} from '@mui/material';
import { emailValidator, numberValidator } from '../../utils/validators';
import ValidatedTextField from '../../Components/ValidatedTextField';
import CountrySelector from '../../Components/CountrySelector';
import StateSelector from '../../Components/StateSelector';
import { useApiGet, useApiSend } from '../../utils/httpClient';
import { getDonationProjects, getDonationFunds, createDonationLines, removeDonationLine, removeDonationNotification } from '../../endpoints/donations';
import { getPostalInfo } from '../../endpoints/lookups';
import DeleteIcon from '@mui/icons-material/Delete';
import { projects } from '../../utils/variables';
import { formatCurrency } from '../../utils/formatters';
import CircleIcon from '@mui/icons-material/Circle';
import { Edit } from '@mui/icons-material';
import elfLogo from '../../assets/ELF_Icon+Type_CMYK.png'
import ipsLogo from '../../assets/IPS_Icon+Type_CMYK.png'
import pceLogo from '../../assets/PCE_Icon+Type_CMYK.png'
import starLogo from '../../assets/STAR_Icon+Type_CMYK.png'
import psaLogo from '../../assets/PSA_Icon+Type_CMYK.png'
import foundationLogo from '../../assets/Foundation_IconOnly_CMYK.png';
import logo from '../../assets/PEO_InformalLogo_Color.png';


const foundationsId = projects.Foundations
const defaultFund = '0349d0b4dcb540139d90f6c0ac6c38b4'
const generalDonationId = 'gd'
const logoProjects = {
    [projects.ELF]: elfLogo,
    [projects.IPS]: ipsLogo,
    [projects.PCE]: pceLogo,
    [projects.STAR]: starLogo,
    [projects.PSA]: psaLogo,
    [projects.Foundations]: foundationLogo,
}

export default function DonationInfo({handleNext, donationId, handleBack, donationObject, refetch}) {

    const [donationLines, setDonationLines] = useState([])
    const [emailError, setEmailError] = useState(false)
    const [emailValid, setEmailValid] = useState(false)
    const [countryIdError, setCountryIdError] = useState(false)
    const [stateIdError, setStateIdError] = useState(false)
    const [cityError, setCityError] = useState(false)
    const [postalCodeError, setPostalCodeError] = useState(false)
    const [notFullNameError, setNotFullNameError] = useState(false)
    const [fullNameError, setFullNameError] = useState(false)
    const [addressError, setAddressError] = useState(false)
    const [projectError, setProjectError] = useState(false)
    const [donationAmount, setDonationAmount] = useState(0)
    const [donationAmountError, setDonationAmountError] = useState(false)
    const [remainAnonymous, setRemainAnonymous] = useState(false)
    const [project, setProject] = useState({"id": generalDonationId, "name": "P.E.O. General Donation"})
    const [fund, setFund] = useState(null)
    const [onBehalfOType, setOnBehalfOfType] = useState('1')
    const [chapter, setChapter] = useState('')
    const [fullName, setFullName] = useState('')
    const [allowNotification, setAllowNotification] = useState(false)
    const [notificationType, setNotificationType] = useState('mail')
    const [notificationEmail, setNotificationEmail] = useState('')
    const [notificationAddress, setNotificationAddress] = useState('')
    const [notificationCity, setNotificationCity] = useState('')
    const [notificationStateId, setNotificationStateId] = useState('')
    const [notificationPostalCode, setNotificationPostalCode] = useState('')
    const [notificationCountryId, setNotificationCountryId] = useState('')
    const [notificationFullName, setNotificationFullName] = useState('')
    const [notificationStateRequired, setNotificationStateRequired] = useState(false)
    const [dialogOpen, setDialogOpen] = useState(false)
    const [delayPostalCode, setDelayPostalCode] = useState('')
    const [getPostalInfoActive, setGetPostalInfoActive] = useState(false)
    const [loadedFromDonation, setLoadedFromDonation] = useState(false)
    const [focusAmount, setFocusAmount] = useState(false)
    const [notifications, setNotifications] = useState([])
    const [chapterCredit, setChapterCredit] = useState(false)
    const [chapterCreditError, setChapterCreditError] = useState(false)
    const [notificationDialogOpen, setNotificationDialogOpen] = useState(false)
    const [initialFundLoad, setInitialFundLoad] = useState(false)
    const [showErrors, setShowErrors] = useState(false)
    const [lineId, setLineId] = useState(null)
    const [notificationId, setNotificationId] = useState(null)

    const { mutate } = useApiSend(
        createDonationLines,
        (data) => {
            refetch()
            resetState()
        },
        (error) => {
            console.error(error)
        },
    )

    const { mutate: removeLine } = useApiSend(
        removeDonationLine,
        (data) => {
            refetch()
            setDialogOpen(false)
        },
        (error) => {
            console.error(error)
        },
    )

    const { mutate: removeNotificationLine } = useApiSend(
        removeDonationNotification,
        (data) => {
            setNotificationDialogOpen(false)
        },
        (error) => {
            console.error(error)
        },
    )

    const { data: projects } = useApiGet(
        ['donation-project'],
        getDonationProjects,
        {
            enabled: true,
        },
    )

    const { data: funds } = useApiGet(
        ['donation-funds'],
        getDonationFunds,
        {
            enabled: true,
        },
    )

    const { data: postalInfo } = useApiGet(
        ['postal-info', notificationPostalCode, notificationCountryId],
        getPostalInfo,
        {
            enabled: getPostalInfoActive,
        },
        { postalCode: notificationPostalCode, countryId: notificationCountryId }
    )

    useEffect(() => {
        const timeoutId = setTimeout(() => {
          setNotificationPostalCode(delayPostalCode)
        }, 500)
        return () => clearTimeout(timeoutId)
      }, [delayPostalCode])

    useEffect(() => {
        if (notificationPostalCode.length > 4 && notificationCountryId.length > 0 && !loadedFromDonation) {
            setGetPostalInfoActive(true)
        } else {
            setGetPostalInfoActive(false)
        }
    }, [notificationPostalCode, notificationCountryId, loadedFromDonation])

    useEffect(() => {
        if (postalInfo) {
            if (postalInfo.city) {
                setNotificationCity(postalInfo.city)
            }
            if (postalInfo.state_id) {
                setNotificationStateId(postalInfo.state_id)
            }
        }
    }, [postalInfo])

    useEffect(() => {
        if (donationObject) {

            let tempLines = []
            donationObject.donation_lines.forEach((line) => {
                let tempLine = {
                    donationAmount: line.donation_amount,
                    project: line.project_name,
                    project_id: line.project_id,
                    fund: line.fund_name,
                    fund_id: line.fund_id,
                    onBehalfOType: line.behalf_of_type_name,
                    lineId: line.id,
                    chapter: line.chapter,
                    fullName: line.full_name,
                    remainAnonymous: line.remain_anonymous,
                    notifications: line.notifications,
                    id: line.id,
                }
                tempLines.push(tempLine)
            })
            setDonationLines(tempLines)
        }
    }, [donationObject])

    useEffect(() => {
        if (funds && fund === null && !initialFundLoad) {
            setFund(funds.find((f) => f.id === defaultFund))
            setInitialFundLoad(true)
        }
    }, [funds, fund, initialFundLoad])

    useEffect(() => {
        if (donationAmount.length > 0) {
            setFocusAmount(false)
        }
    }, [donationAmount])

    useEffect(() => {
        // need to check if any error is set to true if not then set showErrors to false
        if (!emailError && !emailValid && !countryIdError && !stateIdError && !cityError && !postalCodeError && !notFullNameError && !fullNameError && !addressError && !projectError && !donationAmountError && !chapterCreditError) {
            setShowErrors(false)
        }
    }, [emailError, emailValid, countryIdError, stateIdError, cityError, postalCodeError, notFullNameError, fullNameError, addressError, projectError, donationAmountError, chapterCreditError])


    const handleCountryChange = (value) => {
        setLoadedFromDonation(false)
        if (value) {
            if (value.id !== notificationCountryId) {
                setNotificationStateId('')
            }
            setCountryIdError(false)
            setNotificationCountryId(value.id)
            setNotificationStateRequired(value.has_states)
        } else {
            setNotificationCountryId('')
        }
    }

    const handleStateChange = (value) => {
        if (value) {
            setStateIdError(false)
            setNotificationStateId(value.id)
        } else {
            if (notificationStateRequired) {
                setStateIdError(true)
            }
            setNotificationStateId('')
        }
    }

    const handleDonationAmountChange = (valid, value) => {
        if (valid) {
            setDonationAmountError(false)
        } else {
            setDonationAmountError(true)
        }
        setDonationAmount(value)
        try {
            parseFloat(value)
            setDonationAmountError(false)
        } catch (e) {
            setDonationAmountError(true)
        }
    }

    const handleChangeDonationAmount = (amount) => {
        setDonationAmount(amount)
        setDonationAmountError(false)
    }

    const handleEmailChange = (valid, value) => {
        setNotificationEmail(value)
        if (valid) {
            setEmailValid(true)
            if (value.length > 0) {
                setEmailError(false)
            }
        } else {
            setEmailValid(false)
        }
    }

    const handleScrollToTop = () => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
    };

    const handleAddLine = () => {
        let tempLine = handleLineComplete()
        console.log(tempLine)
        if (tempLine) {
            let tempBehalfOf = null
            if (tempLine.onBehalfOType !== '1') {
                let tempNotifications = []
                if (tempLine.allowNotification) {
                    tempNotifications = notifications
                    if (tempNotifications.length > 0 && tempLine.notification?.id) {
                        tempNotifications = tempNotifications.filter((notification) => notification.id !== tempLine.notification.id)
                    }
                    if (tempLine.notification) {
                        tempNotifications.push(tempLine.notification)
                    }
                }
                tempBehalfOf = {
                    full_name: tempLine.fullName,
                    remain_anonymous: tempLine.remainAnonymous,
                    behalf_of_type: tempLine.onBehalfOType,
                    chapter: tempLine.chapter,
                    notifications: tempNotifications,
                }
            }

            let retLine = {
                id: lineId,
                amount: tempLine.donationAmount,
                project_id: tempLine.project.id === generalDonationId ? foundationsId : tempLine.project.id,
                fund_id: tempLine.fund ? tempLine.fund.id
                                       : tempLine.project.id === generalDonationId ? defaultFund : null,
                behalf_of_person: tempLine.onBehalfOType === '1' ? false : true,
                behalf_of: tempBehalfOf,
                line_id: tempLine.lineId,
            }
            mutate({
                donation_id: donationId,
                donation_line: retLine
            })
        } else {
            setShowErrors(true)
            handleScrollToTop()
        }
    }

    const resetState = () => {
        resetNotification()
        setDonationAmount('')
        setDonationAmountError(false)
        setProject({"id": generalDonationId, "name": "P.E.O. General Donation"})
        setProjectError(false)
        setFund('')
        setOnBehalfOfType('1')
        setFullName('')
        setFullNameError(false)
        setAllowNotification(false)
        setRemainAnonymous(false)
        setDialogOpen(false)
        setGetPostalInfoActive(false)
        setLoadedFromDonation(false)
        setChapterCredit(false)
        setChapter('')
        setChapterCreditError(false)
        setNotifications([])
        setInitialFundLoad(false)
        setLineId(null)
    }

    const handleSetFund = (value) => {
        setFund(value)
    }

    const handlePostalCodeChange = (e) => {
        setLoadedFromDonation(false)
        let value = e.target.value
        setDelayPostalCode(value)
        if (value.length > 0) {
            setPostalCodeError(false)
        }
    }

    const handleNotificationFullNameChange = (e) => {
        let value = e.target.value
        setNotificationFullName(value)
        if (value.length > 0) {
            setNotFullNameError(false)
        }
    }

    const handleCityChange = (e) => {
        let value = e.target.value
        setNotificationCity(value)
        if (value.length > 0) {
            setCityError(false)
        }
    }

    const handleAddressChange = (e) => {
        let value = e.target.value
        setNotificationAddress(value)
        if (value.length > 0) {
            setAddressError(false)
        }
    }

    const handleFullNameChange = (e) => {
        let value = e.target.value
        setFullName(value)
        if (value.length > 0) {
            setFullNameError(false)
        }
    }

    const handleSetProject = (e) => {
        let projectId = e.target.value
        if (!projectId) {
            setProject(null)
            setProjectError(true)
            return
        }
        if (projectId === generalDonationId) {
            setProject({"id": generalDonationId, "name": "P.E.O. General Donation"})
            setProjectError(false)
            return
        }
        setProject(projects.find((p) => p.id === projectId))
        setProjectError(false)
        if (projectId !== foundationsId) {
            setFund(null)
        }
    }

    const handleSetChapter = (e) => {
        let value = e.target.value
        setChapter(value)
        if (value.length > 0) {
            setChapterCreditError(false)
        }
    }

    const removeDonLine = (line) => {
        removeLine({
            id: line.lineId
        })
    }

    const selectDonLine = (line) => {
        // Load back in from the object selected back into state
        // Hold onto ID
        setDonationAmount(line.donationAmount)
        setProject(line.project_id === generalDonationId ? {"id": generalDonationId, "name": "P.E.O. General Donation"} : projects.find((p) => p.id === line.project_id))
        setFund(line.fund_id ? funds.find((f) => f.id === line.fund_id) : null)
        setFullName(line.fullName)
        setAllowNotification(line.notifications.length > 0 ? true : false)
        setRemainAnonymous(line.remainAnonymous)
        setChapterCredit(line.chapter ? true : false)
        setChapter(line.chapter)
        setLineId(line.id)
        setOnBehalfOfType(line.onBehalfOType ? line.onBehalfOType : '1')
        if (line.notifications.length > 0) {
            let tempNotifications = []
            line.notifications.forEach((notification) => {
                let tempNotification = {
                    id: notification.id,
                    notification_type: notification.notification_type,
                    email: notification.email,
                    address: notification.address1,
                    city: notification.city,
                    state_id: notification.state_id,
                    postal_code: notification.postal_code,
                    country_id: notification.country_id,
                    notification_full_name: notification.full_name,
                }
                tempNotifications.push(tempNotification)
            })
            setNotifications(tempNotifications)
        }
        setDialogOpen(false)
    }

    const resetNotification = () => {
        setNotificationType('mail')
        setNotificationEmail('')
        setNotificationAddress('')
        setNotificationCity('')
        setNotificationStateId('')
        setNotificationPostalCode('')
        setDelayPostalCode('')
        setNotificationCountryId('')
        setNotificationFullName('')
        setNotFullNameError(false)
        setAddressError(false)
        setCityError(false)
        setStateIdError(false)
        setPostalCodeError(false)
        setCountryIdError(false)
        setRemainAnonymous(false)
        setNotificationStateRequired(false)
    }

    const getNotification = () => {
        let tempNotification = {
            id: notificationId,
            notification_type: allowNotification ? notificationType : null,
            email: allowNotification && notificationType === 'email' ? notificationEmail : null,
            address: allowNotification && notificationType === 'mail' ? notificationAddress : null,
            city: allowNotification && notificationType === 'mail' ? notificationCity : null,
            state_id: allowNotification && notificationType === 'mail' ? notificationStateId : null,
            postal_code: allowNotification && notificationType === 'mail' ? notificationPostalCode : null,
            country_id: allowNotification && notificationType === 'mail' ? notificationCountryId : null,
            notification_full_name: allowNotification && notificationType === 'mail' ? notificationFullName : null,
        }
        return tempNotification
    }

    const addNotification = () => {
        if (validateNotification()) {
            let tempNotification = getNotification()
            setNotifications([...notifications, tempNotification])
            return true
        }
        setShowErrors(true)
        return false
    }

    const selectNotification = (notification) => {
        // Load notification from object to state and remove from DB
        // Hold onto ID
        setNotificationType(notification.notification_type)
        setNotificationEmail(notification.email)
        setNotificationAddress(notification.address)
        setNotificationCity(notification.city)
        setNotificationStateId(notification.state_id)
        setNotificationPostalCode(notification.postal_code)
        setDelayPostalCode(notification.postal_code)
        setNotificationCountryId(notification.country_id)
        setNotificationFullName(notification.notification_full_name)
        setNotificationId(notification.id)
        setNotificationStateRequired(notification.state_id ? true : false)
        setNotificationDialogOpen(false)
        setRemainAnonymous(notification.remain_anonymous)
    }

    const handleLineComplete = (submit=false) => {
        let valid = true
        let notification = null
        if (onBehalfOType !== '1' && allowNotification  && notificationFullName.length > 0) {
            valid = validateNotification()
            if (valid) {
                notification = getNotification()
            }
        }

        if (chapterCredit && chapter.length === 0) {
            valid = false
            setChapterCreditError(true)
        }

        if (donationAmount.length === 0) {
            setDonationAmountError(true)
            valid = false
        }
        try {
            if (parseFloat(donationAmount) <= 0) {
                setDonationAmountError(true)
                valid = false
            }
        } catch (e) {
            setDonationAmountError(true)
            valid = false
        }

        if (!project) {
            valid = false
            setProjectError(true)
        }

        if (onBehalfOType !== '1') {
            if (fullName.length === 0) {
                valid = false
                setFullNameError(true)
            }
        }

        if (valid) {
            let donationLine = {
                donationAmount: donationAmount,
                project: project,
                fund: project.id === foundationsId ? !fund ? funds.find((f) => f.id === defaultFund) : fund
                                                   : null,
                onBehalfOType: onBehalfOType,
                fullName: fullName,
                allowNotification: allowNotification,
                remainAnonymous: remainAnonymous,
                chapter: chapter,
                notification: notification,
            }
            return donationLine
        }

        return null
    }

    const validateNotification = () => {
        let valid = true
        if (notificationType.length === 0) {
            valid = false
        }

        if (notificationType === 'email' && notificationEmail.length === 0) {
            valid = false
            setEmailError(true)
        }

        if (notificationType === 'email' && !emailValid) {
            valid = false
        }

        if (notificationType === 'mail') {
            if (notificationFullName.length === 0) {
                valid = false
                setNotFullNameError(true)
            }

            if (notificationAddress.length === 0) {
                valid = false
                setAddressError(true)
            }

            if (notificationCity.length === 0) {
                valid = false
                setCityError(true)
            }

            if (notificationStateRequired && notificationStateId.length === 0) {
                valid = false
                setStateIdError(true)
            }

            if (notificationPostalCode.length === 0) {
                valid = false
                setPostalCodeError(true)
            }

            if (notificationCountryId.length === 0) {
                valid = false
                setCountryIdError(true)
            }
        }
        return valid
    }
        
    const addAnotherNotification = () => {
        let valid = addNotification()
        if (valid) {
            resetNotification()
        }
    }

    const getTotalDonation = () => {
        let totalDonation = 0
        donationLines.forEach((line) => {
            totalDonation += parseFloat(line.donationAmount)
        })

        return totalDonation
    }

    const removeNotification = (line, index) => {
        if (line.id) {
            removeNotificationLine({
                id: line.id
            })
        }
        let tempNotifications = notifications
        tempNotifications.splice(index, 1)
        setNotifications([...tempNotifications])
    }

    const handleChapterCredit = (e) => {
        let value = e.target.value
        setChapterCredit(value)
        if (!value) {
            setChapter('')
        }
    }


    const errorMessage = () => {
        return (
        <Grid item xs={12} paddingBottom>
            <Typography variant='body1' color='white'>
                Please correct the errors before continuing:
            </Typography>
            <List>
                {emailError && (
                    <ListItem dense>
                        <ListItemIcon>
                            <CircleIcon sx={{ fontSize: 'small'}}/>
                        </ListItemIcon>
                        <ListItemText>Email is required</ListItemText>
                    </ListItem>
                )}
                {emailValid && (
                    <ListItem dense>
                        <ListItemIcon>
                            <CircleIcon sx={{ fontSize: 'small'}}/>
                        </ListItemIcon>
                        <ListItemText>Valid email is required</ListItemText>
                    </ListItem>
                )}
                {countryIdError && (
                    <ListItem dense>
                        <ListItemIcon>
                            <CircleIcon sx={{ fontSize: 'small'}}/>
                        </ListItemIcon>
                        <ListItemText>Country is required</ListItemText>
                    </ListItem>
                )}
                {stateIdError && (
                    <ListItem dense>
                        <ListItemIcon>
                            <CircleIcon sx={{ fontSize: 'small'}}/>
                        </ListItemIcon>
                        <ListItemText>State is required</ListItemText>
                    </ListItem>
                )}
                {cityError && (
                    <ListItem dense>
                        <ListItemIcon>
                            <CircleIcon sx={{ fontSize: 'small'}}/>
                        </ListItemIcon>
                        <ListItemText>City is required</ListItemText>
                    </ListItem>
                )}
                {postalCodeError && (
                    <ListItem dense>
                        <ListItemIcon>
                            <CircleIcon sx={{ fontSize: 'small'}}/>
                        </ListItemIcon>
                        <ListItemText>Postal Code is required</ListItemText>
                    </ListItem>
                )}
                {notFullNameError && (
                    <ListItem dense>
                        <ListItemIcon>
                            <CircleIcon sx={{ fontSize: 'small'}}/>
                        </ListItemIcon>
                        <ListItemText>Full name is required</ListItemText>
                    </ListItem>
                )}
                {fullNameError && (
                    <ListItem dense>
                        <ListItemIcon>
                            <CircleIcon sx={{ fontSize: 'small'}}/>
                        </ListItemIcon>
                        <ListItemText>Full name is required</ListItemText>
                    </ListItem>
                )}
                {addressError && (
                    <ListItem dense>
                        <ListItemIcon>
                            <CircleIcon sx={{ fontSize: 'small'}}/>
                        </ListItemIcon>
                        <ListItemText>Address is required</ListItemText>
                    </ListItem>
                )}
                {projectError && (
                    <ListItem dense>
                        <ListItemIcon>
                            <CircleIcon sx={{ fontSize: 'small'}}/>
                        </ListItemIcon>
                        <ListItemText>Project is required</ListItemText>
                    </ListItem>
                )}
                {donationAmountError && (
                    <ListItem dense>
                        <ListItemIcon>
                            <CircleIcon sx={{ fontSize: 'small'}} />
                        </ListItemIcon>
                        <ListItemText>Valid donation amount is required</ListItemText>
                    </ListItem>
                )}
                {chapterCreditError && (
                    <ListItem dense>
                        <ListItemIcon>
                            <CircleIcon sx={{ fontSize: 'small'}} />
                        </ListItemIcon>
                        <ListItemText>Chapter is required</ListItemText>
                    </ListItem>
                )}
            </List>
        </Grid>)
    }

    return (
        <Grid container>
            {showErrors && (
                <Grid item xs={12} padding>
                    <Paper elevation={3} sx={{ padding: 2, backgroundColor: 'error.light', color: 'white'}}>
                        <Grid container>
                            {errorMessage()}
                        </Grid>
                    </Paper>
                </Grid>
            )}
            {getTotalDonation() > 0 && (
            <Grid item xs={12} textAlign={'center'} padding paddingBottom={3}>
                <Button
                    size='large'
                    variant='contained'
                    onClick={() => setDialogOpen(true)}
                    sx={{ fontWeight: 'bold'}}
                    color='success'>
                        {`Review / Finalize Donation: ${formatCurrency(getTotalDonation())}`}
                </Button>
            </Grid>)}
            <Grid item xs={12} textAlign={'center'} paddingBottom>
                <ButtonGroup variant='contained' color='primary' size='medium' sx={{ fontWeight: 'bold' }}>
                    <Button sx={{ fontWeight: 'bold'}} onClick={() => handleChangeDonationAmount('100.00')}> $100 </Button>
                    <Button sx={{ fontWeight: 'bold'}} onClick={() => handleChangeDonationAmount('50.00')}> $50 </Button>
                    <Button sx={{ fontWeight: 'bold'}} onClick={() => handleChangeDonationAmount('25.00')}> $25 </Button>
                    <Button sx={{ fontWeight: 'bold'}} onClick={() => setFocusAmount(true)}>Other</Button>
                </ButtonGroup>
            </Grid>
            <Grid item xs={1} sm={2} md={3} lg={4} />
            <Grid item xs={10} sm={8} md={6} lg={4} textAlign={'center'} paddingTop={3}>
                <ValidatedTextField
                    label={'Donation Amount'}
                    size='small'
                    financial={true}
                    focus={focusAmount}
                    validator={numberValidator}
                    startAdornment='$'
                    onChange={(valid, value) => handleDonationAmountChange(valid, value)}
                    errorOverride={donationAmountError ? 'Valid donation amount is required' : ''}
                    valueRequired={true}
                    value={donationAmount}/>
            </Grid>
            <Grid item xs={1} sm={2} md={3} lg={4} />
            <Grid item xs={12} paddingLeft>
                <Typography variant='body2' color='primary'>
                    Direct Gift To:
                </Typography>
            </Grid>
            {projects ? (
                <Grid item xs={12} padding>
                    <FormControl fullWidth size='small' error={projectError}>
                        <Select
                            id='projects'
                            fullWidth
                            size='small'
                            value={project ? project.id : ''}
                            onChange={handleSetProject}
                        >
                            <MenuItem value={generalDonationId}>
                                <Typography variant='inherit'>
                                    <Box
                                        component="img"
                                        sx={{
                                            height: 25,
                                            width: 50,
                                            pr: 1,
                                            marginBottom: -1,
                                        }}
                                        alt="Logo."
                                        src={logo}/>
                                    P.E.O. General Donation
                                </Typography>
                            </MenuItem>
                            {projects.map((project) => (
                                <MenuItem value={project.id} key={project.id}>
                                    <Typography variant='inherit'>
                                        <Box
                                            component="img"
                                            sx={{
                                                height: 25,
                                                width: Object.keys(logoProjects).includes(project.id) ? 25 : 50,
                                                pr: 1,
                                                marginBottom: -1,
                                            }}
                                            alt="Logo."
                                            src={Object.keys(logoProjects).includes(project.id) ? logoProjects[project.id]
                                                                                                : logo
                                            }/>
                                        {project.name}
                                    </Typography>
                                </MenuItem>
                            ))}
                        </Select>
                        <FormHelperText>{projectError ? 'Project is required' : ''}</FormHelperText>
                    </FormControl>
                </Grid>
            ) : <Skeleton>
                    <Select
                        id='projects'
                        fullWidth
                        label='Project'/>
                </Skeleton>}
            
            {funds && project?.id === foundationsId && (
                <Grid item xs={12} padding size='small'>
                    <FormControl fullWidth size='small'>
                        <Autocomplete
                            id='funds'
                            fullWidth
                            size='small'
                            options={funds}
                            getOptionLabel={(option) => option.name}
                            value={fund ? fund : null}
                            onChange={(e, value) => handleSetFund(value)}
                            renderInput={(params) => <TextField {...params} label='Fund' />}
                        />
                    </FormControl>
                </Grid>
            )}

            <Grid item xs={12} md={6} padding>
                <FormControl fullWidth size='small'>
                    <InputLabel id='on-behalf-of-label'>Chapter Credit</InputLabel>
                    <Select
                        id='chapter-credit'
                        fullWidth
                        size='small'
                        label='Chapter Credit'
                        value={chapterCredit}
                        onChange={handleChapterCredit}
                    >
                        <MenuItem value={false}>No</MenuItem>
                        <MenuItem value={true}>Yes</MenuItem>
                    </Select>
                </FormControl>
            </Grid>

            {chapterCredit && (
                <Grid item xs={12} md={6} padding>
                    <TextField
                        size='small'
                        variant="outlined"
                        fullWidth
                        error={chapterCreditError}
                        helperText={chapterCreditError ? 'Chapter is required' : ''}
                        id="chapter"
                        label="P.E.O. Chapter (Chapter\SPD)"
                        value={chapter}
                        onChange={handleSetChapter}
                    />
                </Grid>)}

            <Grid item xs={12} paddingLeft>
                <Typography variant='body2' color='primary'>
                    In Memory / In Honor Of:
                </Typography>
            </Grid>

            <Grid item xs={12} md={6} padding size='small'>
                <FormControl fullWidth>
                    <Select
                        id='on-behalf-of'
                        fullWidth
                        size='small'
                        value={onBehalfOType}
                        onChange={(e) => setOnBehalfOfType(e.target.value)}
                    >
                        <MenuItem value={'1'}>None</MenuItem>
                        <MenuItem value={'memory'}>In Memory Of</MenuItem>
                        <MenuItem value={'honor'}>In Honor Of</MenuItem>
                    </Select>
                </FormControl>
            </Grid>

            {onBehalfOType !== '1' && (
                <Grid item container xs={12}>
                    <Grid item xs={12} padding>
                        <TextField
                            fullWidth
                            label={onBehalfOType === 'memory' ? 'In memory of full name' : 'Honoree full name'}
                            size='small'
                            value={fullName}
                            error={fullNameError}
                            helperText={fullNameError ? 'Full name is required' : ''}
                            onChange={handleFullNameChange}
                        />
                    </Grid>
                    <Grid item xs={12}  sm={6} paddingTop paddingLeft paddingRight>
                        <Typography variant='body2' color='primary'>
                            Would you like us to notify {onBehalfOType === 'memory' ? 'anyone of the memorial' : 'the honoree'}?
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} paddingTop paddingLeft paddingRight>
                        <FormControl fullWidth size='small'>
                            <InputLabel id='on-behalf-of-label'>Notify</InputLabel>
                            <Select
                                id='notification'
                                fullWidth
                                size='small'
                                label='Notify'
                                value={allowNotification}
                                onChange={(e) => setAllowNotification(e.target.value)}
                            >
                                <MenuItem value={false}>No</MenuItem>
                                <MenuItem value={true}>Yes</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>

                    {allowNotification && (
                    <Grid item container xs={12}>
                        <Grid item xs={12} sm={6} paddingBottom/>
                    <Grid item xs={12} sm={6} paddingBottom textAlign={'center'}>
                        <Typography variant='body2' color='primary' fontStyle={'italic'}>
                            The donation amount will not be included.
                        </Typography>
                    </Grid>
                    </Grid>)}


                    {allowNotification && (
                        <Grid item container xs={12}>
                            <Grid item xs={6} padding/>
                            <Grid item xs={6} padding>
                                <FormControl fullWidth size='small'>
                                    <InputLabel id='notification-type-label'>Remain Anonymous</InputLabel>
                                    <Select
                                        id='notification-type'
                                        fullWidth
                                        size='small'
                                        label='Remain Anonymous'
                                        value={remainAnonymous}
                                        onChange={(e) => setRemainAnonymous(e.target.value)}
                                    >
                                        <MenuItem value={false}>No</MenuItem>
                                        <MenuItem value={true}>Yes</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                    )}

                    {allowNotification && (
                        <Grid item xs={12} padding>
                            <Divider>
                                {notifications.length > 0 && (
                                <Button variant='contained' size='small' color='primary' onClick={() => setNotificationDialogOpen(true)}>
                                    Notifications: {notifications.length}
                                </Button>)}
                            </Divider>
                        </Grid>
                    )}
                    {allowNotification && (
                        <Grid item xs={6} padding>
                            <FormControl fullWidth size='small'>
                                <InputLabel id='notification-type-label'>Notification Type</InputLabel>
                                <Select
                                    id='notification-type'
                                    fullWidth
                                    size='small'
                                    label='Notification Type'
                                    value={notificationType}
                                    onChange={(e) => setNotificationType(e.target.value)}
                                >
                                    <MenuItem value={'mail'}>Mail</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                    )}
                    {notificationType === 'email' && allowNotification && (
                        <Grid item xs={12} padding>
                            <ValidatedTextField
                                label={'Email Address'}
                                size='small'
                                validator={emailValidator}
                                onChange={(valid, value) => handleEmailChange(valid, value)}
                                valueRequired={true}
                                errorOverride={emailError ? 'Email is required' : ''}
                                value={notificationEmail}/>
                        </Grid>
                    )}

                    {notificationType === 'mail' && allowNotification && (
                        <Grid container item xs={12}>
                            <Grid item xs={12} padding>
                                <TextField
                                    fullWidth
                                    label='Notification Full Name'
                                    size='small'
                                    value={notificationFullName}
                                    error={notFullNameError}
                                    helperText={notFullNameError ? 'Full name is required' : ''}
                                    onChange={handleNotificationFullNameChange}
                                />
                            </Grid>
                            <Grid item xs={6} padding paddingBottom={2}>
                                <CountrySelector
                                    value={notificationCountryId}
                                    handleChange={(value) => handleCountryChange(value)}
                                    error={countryIdError}
                                    errorMessage={countryIdError ? 'Country is required' : ''}
                                    size='small'/>
                            </Grid>
                            <Grid item xs={6} padding paddingBottom={2}>
                                <TextField
                                    variant="outlined"
                                    required
                                    size='small'
                                    fullWidth
                                    id="past-code"
                                    label="Postal Code"
                                    error={postalCodeError}
                                    helperText={postalCodeError ? 'Postal Code is required' : ''}
                                    value={delayPostalCode}
                                    onChange={handlePostalCodeChange}
                                />
                            </Grid>
                            <Grid item xs={6} padding paddingBottom={2}>
                                <TextField
                                    variant="outlined"
                                    required
                                    fullWidth
                                    size='small'
                                    id="address-1"
                                    label="Address"
                                    error={addressError}
                                    helperText={addressError ? 'Address is required' : ''}
                                    value={notificationAddress}
                                    onChange={handleAddressChange}
                                />
                            </Grid>
                            <Grid item xs={6} padding paddingBottom={2}>
                                <TextField
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="city"
                                    label="City"
                                    size='small'
                                    error={cityError}
                                    helperText={cityError ? 'City is required' : ''}
                                    value={notificationCity}
                                    onChange={handleCityChange}
                                />
                            </Grid>
                            {notificationStateRequired && (
                                <Grid item xs={6} padding paddingBottom={2}>
                                    <StateSelector
                                        value={notificationStateId}
                                        countryId={notificationCountryId}
                                        handleChange={(value) => handleStateChange(value)}
                                        error={stateIdError}
                                        errorMessage={stateIdError ? 'State is required' : ''}
                                        size='small'/>
                                </Grid>)}
                            <Grid item xs={notificationStateRequired ? 6 : 12} padding paddingBottom={2} textAlign={'center'}>
                                <Button
                                    size='medium'
                                    variant='text'
                                    color='primary'
                                    onClick={addAnotherNotification}>
                                        Add Another Notification
                                </Button>
                            </Grid>
                        </Grid>)}

                    
                </Grid>
            )}
            <Grid item xs={12} padding>
                <Divider />
            </Grid>
            <Grid item textAlign={'center'} xs={12} padding paddingBottom={2}>
                <Button
                    size={'medium'}
                    color="primary"
                    onClick={handleBack}
                >
                    {'Back'}
                </Button>
                <Button
                    size={'medium'}
                    variant="contained"
                    color="primary"
                    sx={{ fontWeight: 'bold'}}
                    onClick={() => handleAddLine()}
                >
                    {lineId ? 'Save' : 'Donate / Add To Donation'}
                </Button>
            </Grid>
            <Dialog
                open={dialogOpen}
                onClose={() => setDialogOpen(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Current Donations List"}
                </DialogTitle>
                <DialogContent>

                    {donationLines.map((line, index) => (
                        <Grid container key={index}>
                            <Grid item xs={10}>
                                <Typography variant='body2'>
                                    {`Donation Amount: ${formatCurrency(line.donationAmount)}`} 
                                </Typography>
                                
                            </Grid>
                            
                            <Grid item xs={10}>
                                <Typography variant='body2'>
                                    {`Project: ${line.project}`}
                                </Typography>
                            </Grid>
                            {line.fund && (
                                <Grid item xs={10}>
                                    <Typography variant='body2'>
                                        {`Fund: ${line.fund}`}
                                    </Typography>
                                </Grid>
                            )}
                            {line.onBehalfOType && (
                                <Grid item xs={10}>
                                    <Typography variant='body2'>
                                        {line.onBehalfOType === 'memory' ? 'In memory of ' : 'Honoree '}  {line.fullName}
                                    </Typography>
                                </Grid>
                            )}
                            <Grid item xs={12} textAlign={'right'}>
                                <Button
                                    size='small'
                                    variant='text'
                                    color='blue'
                                    sx={{ paddingRight: 2}}
                                    startIcon={<Edit />}
                                    onClick={() => selectDonLine(line)}>
                                        Edit
                                </Button>
                                <Button
                                    size='small'
                                    variant='text'
                                    color='error'
                                    sx={{ paddingLeft: 2}}
                                    startIcon={<DeleteIcon />}
                                    onClick={() => removeDonLine(line)}>
                                        Remove
                                </Button>
                            </Grid>

                            <Grid item xs={12} padding>
                                <Divider />
                            </Grid>
                        </Grid>
                    ))}

                </DialogContent>
                <DialogActions>
                <Button onClick={() => setDialogOpen(false)} autoFocus>
                    Close
                </Button>
                <Button onClick={handleNext} autoFocus variant='contained' sx={{ fontWeight: 'bold'}}>
                    Donate Now
                </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={notificationDialogOpen}
                onClose={() => setNotificationDialogOpen(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Current Notifications List"}
                </DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {notifications.map((line, index) => (
                        <Grid container>
                            <Grid item xs={10}>
                                <Typography variant='body2'>
                                    Full Name: {line.notification_full_name}
                                </Typography>
                            </Grid>
                            <Grid item xs={10}>
                                <Typography variant='body2'>
                                    Delivery Method: {line.notification_type === 'mail' ? 'Mail' : 'Email'}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} textAlign={'right'}>
                                <Button
                                    size='small'
                                    variant='text'
                                    color='blue'
                                    sx={{ paddingRight: 2}}
                                    startIcon={<Edit />}
                                    onClick={() => selectNotification(line)}>
                                        Edit
                                </Button>
                                <Button
                                    size='small'
                                    variant='text'
                                    color='error'
                                    sx={{ paddingLeft: 2}}
                                    startIcon={<DeleteIcon />}
                                    onClick={() => removeNotification(line, index)}>
                                        Remove
                                </Button>
                            </Grid>
                            <Grid item xs={12} padding>
                                <Divider />
                            </Grid>
                        </Grid>
                    ))}
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button onClick={() => setNotificationDialogOpen(false)} autoFocus>
                    Close
                </Button>
                </DialogActions>
            </Dialog>
        </Grid>
    )
}