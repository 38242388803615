import { BrowserRouter, Routes, Route, Outlet, Navigate } from "react-router-dom"
import Dashboard from "./Pages/Dashboard/Dashboard"
import Documents from "./Pages/Documents/Documents"
import NotFound from "./Pages/NotFound"
import Login from "./Pages/Login/Login"
import Roster from "./Pages/Roster/Roster"
import RosterDetail from "./Pages/Roster/RosterDetail"
import Irs from "./Pages/Irs/Irs"
import IrsDetail from "./Pages/Irs/IrsDetail"
import Reports from "./Pages/Reports/Reports"
import Training from "./Pages/Training/Training"
import TrainingCreate from "./Pages/Training/TrainingCreate"
import SiteAppBar from "./SiteAppBar"
import ReleaseNotes from "./Pages/ReleaseNotes"
import { Grid } from "@mui/material"
import Footer from "./Footer"
import TrainingDetail from "./Pages/Training/TrainingDetail"
import Donations from "./Pages/Donations/Donations"
import UserList from "./Pages/Users/UserList"
import UserDetail from "./Pages/Users/UserDetail"
import CreateUser from "./Pages/Users/CreateUser"
import { applications } from "./utils/variables"

const PrivateRoutes = () => {
    let auth = localStorage.getItem("user_id")
  return (
      auth ? <Outlet/> : <Navigate to='/login'/>
    )
  }

const UserManagement = () => {
    let apps = localStorage.getItem("apps")
    return (
        apps.includes(applications.UserManagement) ? <Outlet/> : <Navigate to='/'/>
    )
}

const Router = () => {
    return (
        <BrowserRouter>
            <Grid sx={{ position: 'relative', minHeight: '100vh'}}>
                <SiteAppBar />
                <Grid item xs={12} paddingBottom={18}>
                    <Routes>
                        <Route element={<PrivateRoutes/>}>
                            <Route path="/roster" element={<Roster />} />
                            <Route path="/roster/:id" element={<RosterDetail />} />
                            <Route path="/irs" element={<Irs />} />
                            <Route path="/irs/:id" element={<IrsDetail />} />
                            <Route path="/reports" element={<Reports />} />
                            <Route path="/training" element={<Training />} />
                            <Route path="/training/:id" element={<TrainingDetail />} />
                            <Route path="/training/create" element={<TrainingCreate />} />
                            <Route path="/documents" element={<Documents />} />
                            <Route path="/" element={<Dashboard />} />
                            <Route path="/release-notes" element={<ReleaseNotes />} />
                            <Route path="/donations" element={<Donations />} />
                            <Route element={<UserManagement />}>
                                <Route path="/users" element={<UserList />} />
                                <Route path="/users/create" element={<CreateUser />} />
                            </Route>
                            <Route path="/users/:id" element={<UserDetail />} />
                        </Route>
                        <Route path="*" element={<NotFound />} />
                        <Route path="/login" element={<Login />} />
                    </Routes>
                </Grid>
                <Grid item xs={12}>
                    <Footer />
                </Grid>
            </Grid>
        </BrowserRouter>
    )
}

export default Router