import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Router from './Router';
import { ThemeProvider, createTheme } from '@mui/material';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { HTML5Backend } from 'react-dnd-html5-backend'
import { DndProvider } from 'react-dnd'


const theme = createTheme({
  palette: {
    error: {
      main: '#D2042D',
      contrastText: '#fff',
      light: 'rgba(210, 4, 45, 0.1)',
    },
    secondary: {
      main: '#9C1555',
      contrastText: '#F9F9F9',
    },
    primary: {
      main: '#83013E',
      contrastText: '#F9F9F9',
      light: 'rgba(131, 1, 62, 0.4)',
      veryLight: 'rgba(131, 1, 62, 0.1)',
      hover: 'rgba(131, 1, 62, 0.8)',
    },
    warning: {
      main: '#FFCD00',
      contrastText: '#F9F9F9',
      veryLight: 'rgba(255, 205, 0, 0.1)',
    },
    success: {
      main: '#00A859',
      contrastText: '#F9F9F9',
    },
    white: {
      main: '#F9F9F9',
      contrastText: '#83013E',
    },
    black: {
      main: '#000000',
      contrastText: '#F9F9F9',
    },
    green: {
      main: '#00A859',
      contrastText: '#F9F9F9',
    },
    blue: {
      main: '#0072CE',
      contrastText: '#F9F9F9',
    },
    orange: {
      main: "#CC7722",
      contrastText: '#F9F9F9',
    }
  },
});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // Switching focus will cause the data to be refetched after 4 seconds
      staleTime: 5 * 60 * 1000, // 5 minutes
      // This will garbage collect any data that hasn't been used in 10 minutes in cache
      gcTime: 10 * 60 * 1000, // 10 minutes
    }
  }
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DndProvider backend={HTML5Backend}>
            <Router />
          </DndProvider>
        </LocalizationProvider>
      </QueryClientProvider>
    </ThemeProvider>
  </React.StrictMode>
);
