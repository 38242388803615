import React, {useCallback, useEffect, useState} from 'react';

import { Grid, Paper, TableContainer, TableHead, Table, TableRow,
         TableBody, TableCell, Box, Button, TableSortLabel } from '@mui/material';
import { useApiSend } from '../../utils/httpClient';
import { getReport, getReportExcel } from '../../endpoints/reports';
import ReportSelector from './ReportSelector';
import { TableVirtuoso } from 'react-virtuoso';
import Filters from './Filters';
import DownloadIcon from '@mui/icons-material/Download';
import PageHeader from '../../Components/PageHeader';

const VirtuosoTableComponents = {
  Scroller: React.forwardRef((props, ref) => (
    <TableContainer component={Paper} {...props} ref={ref} />
  )),
  Table: (props) => (
    <Table {...props} size='small' sx={{ borderCollapse: 'separate', tableLayout: 'fixed' }} />
  ),
  TableHead: React.forwardRef((props, ref) => <TableHead {...props} ref={ref} />),
  TableRow,
  TableBody: React.forwardRef((props, ref) => <TableBody {...props} ref={ref} />),
};


function Reports () {
  const [reportUrl, setReportUrl] = useState(null);
  const [columns, setColumns] = useState([]);
  const [filters, setFilters] = useState({});
  const [report, setReport] = useState('');
  const [rowData, setRowData] = useState([]);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');

  const { mutate } = useApiSend(
    getReport,
    (data) => {
        setRowData(data);
    },
    (error) => console.log(error),
  )

  const { mutate: excel } = useApiSend(
    getReportExcel,
    (data) => {
      const url = window.URL.createObjectURL(new Blob(
        [data],
        {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,"}
      ));
      const link = document.createElement("a");
      link.href = url;
      link.download = "downloaded-report";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    },
    (error) => console.log(error),
  )


  const handleReportSelect = (report) => {
    setRowData([]);
    setOrderBy('');
    setOrder('asc');
    setReport(report.id);
    setColumns(report.columns);
    setReportUrl(report.route);
    let filt = {}
    report.filters.forEach((fil) => {
      filt[fil] = null;
    });
    setFilters(filt);
  }


  const handleSort = (columnDataKey) => {
    const isAsc = orderBy === columnDataKey && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(columnDataKey);
    // virtuoso.current.scrollToIndex(0);
  };

  const handleGetDownload = () => {
    excel({
      url: reportUrl,
      export_excel: true,
      ...filters
    })
  }

  const fixedHeaderContent = () => {
    return (
      <TableRow>
        {columns.map((column) => (
          <TableCell
            key={column.dataKey}
            variant="head"
            align={column.dataKey === 'action' ? 'left' : 'right'}
            style={{ width: column.width }}
            sx={{
              backgroundColor: 'background.paper',
            }}
          >
            <TableSortLabel
              active={orderBy === column.dataKey}
              direction={orderBy === column.dataKey ? order : 'asc'}
              onClick={() => handleSort(column.dataKey)}
            >
              {column.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    );
  }

  const rowContent = (_index, row) => {
    return (
        <React.Fragment>
          {columns.map((column) => (
            <TableCell
              key={column.dataKey}
              align={'right'}
            >
              {row[column.dataKey]}
            </TableCell>
          ))}
        </React.Fragment>
      );
  }

  const handleGetReport = () => {
    let data = {
      url: reportUrl,
      export_excel: false,
      ...filters
    }
    mutate(data);
  }

  // prepare sort comparators
  const descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
};

  const getComparator = useCallback((order, orderBy) => {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
  }, []);

  useEffect(() => {
    setRowData((rowData) => [...rowData].sort(getComparator(order, orderBy)));
  }, [order, orderBy, getComparator]);

  return (
    <Grid container>
      <Grid item xs={12}>
        <PageHeader title="Reports" />
      </Grid>
      <Grid item xs={12} paddingLeft={5} paddingRight={5} paddingTop={2}>
        <ReportSelector report={report} handleReportSelect={handleReportSelect}/>
      </Grid>
      
      {report &&
        <Grid item xs={12} paddingLeft={4} paddingRight={4}>
          <Filters filters={filters} updateFilters={(value) => setFilters(value)} reportId={report}/>
        </Grid>}
      {report &&
      <Grid item xs={12} textAlign={'right'} paddingRight={2} paddingTop={1}>
        <Button
          variant="text"
          startIcon={<DownloadIcon />}
          sx={{ paddingRight: 5 }}
          onClick={() => handleGetDownload()}
        >
          Download
        </Button>
        <Button
          variant="contained"
          onClick={() => handleGetReport()}
        >
          Generate Report
        </Button>
      </Grid>}
      <Grid item xs={12} padding={3}>
        <Box sx={{ height: 500 }}>
          <TableVirtuoso
              data={rowData}
              components={VirtuosoTableComponents}
              fixedHeaderContent={fixedHeaderContent}
              itemContent={rowContent}
          />
        </Box>
      </Grid>
    </Grid>
  );
}

export default Reports;