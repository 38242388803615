import React from "react";
import { Autocomplete, TextField, Skeleton } from "@mui/material";
import { useApiGet } from "../utils/httpClient";
import { getChapters } from "../endpoints/annualReports";

export default function ChapterSelector({ chapter, handleChapterSelect, spd, reportId }) {
    const { data: chapterList } = useApiGet(
        ["chapters", spd],
        getChapters,
        {
            enabled: true,
            retry: 1,
        },
        { spd_id: spd }
    );
    
    const onChapterSelect = (chapterId) => {
        handleChapterSelect(chapterId);
    };
    
    return (
        chapterList ? (
            <Autocomplete
                key={reportId}
                options={chapterList}
                variant='standard'
                size='small'
                clearOnBlur={true}
                getOptionLabel={(option) => option.search_name}
                value={chapterList.find((chapter) => chapter.id === chapter)}
                onChange={(event, newValue) => onChapterSelect(newValue ? newValue.id : null)}
                renderInput={(params) => (<TextField {...params} label="Chapter" />)}
                />
            ) : (
            <Skeleton>
                <TextField label="Chapter" />
            </Skeleton>
        )
    );
    }