import React, {useCallback, useEffect, useState} from 'react';

import { Grid, Paper, TableContainer, TableHead, Table, TableRow,
         TableBody, TableCell, Box, InputAdornment, TableSortLabel, TextField,
         IconButton} from '@mui/material';
import { useApiGet } from '../../utils/httpClient';
import { getDocuments } from '../../endpoints/documents';
import { TableVirtuoso } from 'react-virtuoso';
import SearchIcon from '@mui/icons-material/Search';
import DownloadIcon from '@mui/icons-material/Download';
import DocumentCategorySelector from './DocumentsCategory';
import DocumentTypeSelector from './DocumentsType';
import PageHeader from '../../Components/PageHeader';


const columns = [
    {
        width: 200,
        label: 'Name',
        dataKey: 'name',
    },
    {
        width: 200,
        label: 'Type',
        dataKey: 'type',
    },
    {
        width: 200,
        label: 'Category',
        dataKey: 'category',
    },
];

const VirtuosoTableComponents = {
  Scroller: React.forwardRef((props, ref) => (
    <TableContainer component={Paper} {...props} ref={ref} />
  )),
  Table: (props) => (
    <Table {...props} size='small' sx={{ borderCollapse: 'separate', tableLayout: 'fixed' }} />
  ),
  TableHead: React.forwardRef((props, ref) => <TableHead {...props} ref={ref} />),
  TableRow,
  TableBody: React.forwardRef((props, ref) => <TableBody {...props} ref={ref} />),
};


export default function Document () {
    const [category, setCategory] = useState('');
    const [type, setType] = useState('');
    const [search, setSearch] = useState('');
    const [delaySearch, setDelaySearch] = React.useState('');
    const [rowData, setRowData] = useState([]);
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('');

    const { data, isSuccess, isFetching } = useApiGet(
        ["documents", category, type, search],
        getDocuments,
        {
        enabled: true,
        retry: 1,
        },
        { category, type, search }
    );

    useEffect(() => {
        if (isSuccess && isFetching === false) {
            setRowData(data);
        } else if (isSuccess === false) {
            setRowData([]);
        } else if (isFetching) {
            setRowData([]);
        }
    }, [data, isSuccess, isFetching]);


    useEffect(() => {
        const timeoutId = setTimeout(() => {
          setSearch(delaySearch)
        }, 500)
        return () => clearTimeout(timeoutId)
      }, [delaySearch])


    const handleSort = (columnDataKey) => {
        const isAsc = orderBy === columnDataKey && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(columnDataKey);
        // virtuoso.current.scrollToIndex(0);
    };

    const fixedHeaderContent = () => {
        return (
            <TableRow>
                <TableCell
                    key={'download'}
                    variant="head"
                    align={'left'}
                    style={{ width: 50 }}
                    sx={{
                    backgroundColor: 'background.paper',
                    }}
                >
                    Document
                </TableCell>
                {columns.map((column) => (
                <TableCell
                    key={column.dataKey}
                    variant="head"
                    align={column.dataKey === 'action' ? 'left' : 'right'}
                    style={{ width: column.width }}
                    sx={{
                    backgroundColor: 'background.paper',
                    }}
                >
                    <TableSortLabel
                    active={orderBy === column.dataKey}
                    direction={orderBy === column.dataKey ? order : 'asc'}
                    onClick={() => handleSort(column.dataKey)}
                    >
                    {column.label}
                    </TableSortLabel>
                </TableCell>
                ))}
            </TableRow>
        );
    }

    const rowContent = (_index, row) => {
        return (
            <React.Fragment>
                <TableCell
                key={'download'}
                align={'right'}
                >
                    <IconButton>
                        <DownloadIcon />
                    </IconButton>
                </TableCell>
            {columns.map((column) => (
                <TableCell
                key={column.dataKey}
                align={'right'}
                >
                {row[column.dataKey]}
                </TableCell>
            ))}
            </React.Fragment>
        );
    }

  // prepare sort comparators
    const descendingComparator = (a, b, orderBy) => {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    };

    const getComparator = useCallback((order, orderBy) => {
        return order === 'desc'
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    }, []);

    useEffect(() => {
        setRowData([...rowData].sort(getComparator(order, orderBy)));
    }, [order, orderBy, getComparator, rowData]);

    return (
        <Grid container>
            <Grid item xs={12}>
                <PageHeader
                    title="Documents"/>
            </Grid>
            <Grid item xs={12} md={6} padding>
                <TextField
                    label="Search"
                    id="search-field"
                    fullWidth={true}
                    onChange={(event) => setDelaySearch(event.target.value)}
                    InputProps={{
                        startAdornment: <InputAdornment position="start"><SearchIcon/></InputAdornment>,
                    }}
                    />
            </Grid>
            
            <Grid item xs={12} md={3} padding>
                <DocumentCategorySelector
                    handleCategorySelect={(value) => setCategory(value)}
                    category={category}
                />
            </Grid>
            <Grid item xs={12} md={3} padding>
                <DocumentTypeSelector
                    handleTypeSelect={(value) => setType(value)}
                    type={type}
                />
            </Grid>

            <Grid item xs={12} padding={3}>
                <Box sx={{ height: 500 }}>
                <TableVirtuoso
                    data={rowData}
                    components={VirtuosoTableComponents}
                    fixedHeaderContent={fixedHeaderContent}
                    itemContent={rowContent}
                />
                </Box>
            </Grid>
        </Grid>
    );
}