import React, {useState} from 'react';
import { Grid, Typography, Button, TextField, InputAdornment, Box, Divider } from '@mui/material';
import ValidatedTextField from '../../Components/ValidatedTextField';
import ImageSelectorButton from '../../Components/ImageSelectorButton';
import Question from './Question';
import QuestionList from './QuestionList';
import AddIcon from '@mui/icons-material/Add';
import CancelIcon from '@mui/icons-material/Cancel';
import { useApiSend } from '../../utils/httpClient';
import { submitTrainingAPI } from '../../endpoints/training';
import { nameValidator } from '../../utils/validators';
import TrainingCategorySelector from '../../Components/TrainingCategorySelector';
import TrainingTopicSelector from '../../Components/TrainingTopicSelector';
import TrainingGroupSelector from '../../Components/TrainingGroupSelector';
import TrainingRolesSelector from '../../Components/TrainingRolesSelector';
import { LoadingButton } from '@mui/lab';


export default function TrainingCreate () {
  const [trainingName, setTrainingName] = useState('')
  const [nameIsValid, setNameIsValid] = useState(false)
  const [nameError, setNameError] = useState(false)
  const [trainingDescription, setTrainingDescription] = useState('')
  const [descriptionError, setDescriptionError] = useState(null)
  const [trainingImage, setTrainingImage] = useState(null)
  const [videoLink, setVideoLink] = useState('')
  const [videoLinkError, setVideoLinkError] = useState(null)
  const [transcript, setTranscript] = useState('')
  const [transcriptError, setTranscriptError] = useState(null)
  const [summary, setSummary] = useState('')
  const [summaryError, setSummaryError] = useState(null)
  const [addQuestion, setAddQuestion] = useState(false)
  const [questions, setQuestions] = useState([])
  const [categoies, setCategories] = useState([])
  const [topics, setTopics] = useState([])
  const [group, setGroup] = useState(null)
  const [createGroup, setCreateGroup] = useState(false)
  const [newGroupName, setNewGroupName] = useState('')
  const [createTopic, setCreateTopic] = useState(false)
  const [newTopicName, setNewTopicName] = useState('')
  const [createCategory, setCreateCategory] = useState(false)
  const [newCategoryName, setNewCategoryName] = useState('')
  const [roles, setRoles] = useState([])

  const { mutate, isPending } = useApiSend(
    submitTrainingAPI,
    (data) => {
      resetState()
      window.location.href = '/training'
    },
    (error) => {
      console.log(error)
    }
  )

  const resetState = () => {
    setTrainingName('')
    setNameIsValid(false)
    setNameError(false)
    setTrainingDescription('')
    setDescriptionError(null)
    setTrainingImage(null)
    setVideoLink('')
    setVideoLinkError(null)
    setTranscript('')
    setTranscriptError(null)
    setSummary('')  
    setSummaryError(null)
    setAddQuestion(false)
    setQuestions([])
    setCategories([])
    setTopics([])
    setGroup(null)
    setCreateGroup(false)
    setNewGroupName('')
    setCreateTopic(false)
    setNewTopicName('')
    setCreateCategory(false)
    setNewCategoryName('')
    setRoles([])
}

  const handleNameChange = (isValid, value) => {
    isValid ? setNameIsValid(false) : setNameIsValid(true)
    setTrainingName(value)
    value.length > 255 ? setNameError(true) : setNameError(false)
  }

  const handleDescriptionChange = (e) => {
    let value = e.target.value
    setTrainingDescription(value)
    if (value.length > 255) {
        setDescriptionError("Description must be less than 255 characters")
    } else if (value.length === 0) {
        setDescriptionError("Description is required")
    } else {
        setDescriptionError(null)
    }
  }

  const handleQuestionUpdate = (updatedQuestions) => {
    let currentOrder = 0
    updatedQuestions.forEach((question) => {
        question.order = currentOrder + 1
        currentOrder++
    })
    setQuestions(updatedQuestions)
    }

  const handleAddQuestion = (question) => {
    let totalQuestions = questions.length
    question.order = totalQuestions + 1
    setQuestions([...questions, question])
    setAddQuestion(false)
  }

  const handleVideoLinkChange = (e) => {
    let value = e.target.value
    setVideoLink(value)
    value.length === 0 ? setVideoLinkError("Video Link is required") : setVideoLinkError(null)
  }

  const handleTranscriptChange = (e) => {
    let value = e.target.value
    setTranscript(value)
    value.length === 0 ? setTranscriptError("Transcript is required") : setTranscriptError(null)
  }

  const handleSummaryChange = (e) => {
    let value = e.target.value
    setSummary(value)
    value.length === 0 ? setSummaryError("Summary is required") : setSummaryError(null)
  }

  const handleFileUpload = (event) => {
    setTrainingImage(event.target.files[0])
    //const formData = new FormData()
    //formData.append('file', event.target.files[0])
    // mutate(formData)
  }

  const handleCancelNew = (type) => {
    switch (type) {
        case 'group':
            setCreateGroup(false)
            setNewGroupName('')
            break
        case 'topic':
            setCreateTopic(false)
            setNewTopicName('')
            break
        case 'category':
            setCreateCategory(false)
            setNewCategoryName('')
            break
        default:
            break
    }
  }

  const buildRequest = () => {
    let ret = {
        name: trainingName,
        description: trainingDescription,
        video_url: videoLink,
        summary: summary,
        transcript: transcript,
    }
    let tempCategories = []
    categoies.forEach((category) => {
        tempCategories.push({id: category.id})
    })
    if (createCategory) {
        tempCategories.push({name: newCategoryName})
    }
    if (tempCategories.length > 0) {
        ret.categories = tempCategories
    }

    let tempTopics = []
    topics.forEach((topic) => {
        tempTopics.push({id: topic.id})
    })
    if (createTopic) {
        tempTopics.push({name: newTopicName})
    }
    if (tempTopics.length > 0) {
        ret.topics = tempTopics
    }

    let tempGroup = {}
    if (group) {
        tempGroup = {id: group, step: 1}
    } else if (createGroup) {
        tempGroup = {name: newGroupName, step: 1}
    }
    if (Object.keys(tempGroup).length > 0) {
        ret.group = tempGroup
    }

    let tempRoles = []
    roles.forEach((role) => {
        tempRoles.push(role.id)
    })
    if (tempRoles.length > 0) {
        ret.roles = tempRoles
    }

    if (questions.length > 0) {
        ret.questions = questions
    }

    return ret
  }

  const validateForm = () => {
    let valid = true
    if (trainingName.length === 0) {
        setNameError(true)
        valid = false
    }
    if (trainingDescription.length === 0) {
        setDescriptionError("Description is required")
        valid = false
    }
    if (videoLink.length === 0) {
        setVideoLinkError("Video Link is required")
        valid = false
    }
    if (transcript.length === 0) {
        setTranscriptError("Transcript is required")
        valid = false
    }
    if (summary.length === 0) {
        setSummaryError("Summary is required")
        valid = false
    }
    if (nameIsValid || nameError || descriptionError || videoLinkError || transcriptError || summaryError) {
        valid = false
    }
    return valid
  }

  const submitTraining = (e) => {
    e.preventDefault()
    if (validateForm()) {
        let request = buildRequest()
        mutate(request)
    }
  }

  return (
    <Grid container>
      <Grid item xs={12} paddingTop={2} paddingBottom={5}>
        <Typography variant="h4" component="div" textAlign={"center"} color="primary">
            Training Create
        </Typography>
      </Grid>
      <Grid item xs={12} md={6} padding>
        <ValidatedTextField
            label="Training Name"
            value={trainingName}
            validator={nameValidator}
            valueRequired={true}
            errorOverride={nameError ? 'Name must be less than 255 characters' : null}
            onChange={(isValid, value) => handleNameChange(isValid, value)}
        />
      </Grid>
      <Grid item xs={12} md={6} padding>
        <TextField
            id="description"
            label="Description"
            size={'small'}
            fullWidth
            multiline
            helperText={descriptionError}
            error={!!descriptionError}
            rows={4}
            value={trainingDescription}
            InputProps={{startAdornment: <InputAdornment position='start'>{null}</InputAdornment>}}
            onChange={handleDescriptionChange}
        />
      </Grid>
      <Grid item xs={12} padding>
        <TextField
            id="summary"
            label="Summary"
            size={'small'}
            fullWidth
            multiline
            helperText={summaryError}
            error={!!summaryError}
            rows={4}
            value={summary}
            InputProps={{startAdornment: <InputAdornment position='start'>{null}</InputAdornment>}}
            onChange={handleSummaryChange}
        />
      </Grid>
      <Grid item xs={12} padding>

        <Divider>
            <Typography variant="overline" component="div" textAlign={"center"} color="primary">
                Image and Video Content
            </Typography>
        </Divider>
      </Grid>
      <Grid item container xs={12} md={6}>
      
        {trainingImage ? (
            <Grid item xs={12} padding textAlign={'center'}>
                <Typography variant="h6" component="div" textAlign={"center"} color="primary">
                    Logo Preview
                </Typography>
                <Grid item>
                    <Box
                        component="img"
                        sx={{
                            height: 75,
                            width: 'auto',
                            maxWidth: 75,
                            borderRadius: '10px'
                        }}
                        alt='pic'
                        src={URL.createObjectURL(trainingImage)}
                    />
                </Grid>
                <Button
                    variant="text"
                    onClick={() => setTrainingImage(null)}
                >
                    Clear
                </Button>
            </Grid>
        ) : (
            <Grid item xs={12} padding textAlign={'center'}>
                <ImageSelectorButton
                    label="Class Image"
                    handleFileUpload={handleFileUpload}/>
            </Grid>
        )}
        <Grid item xs={12} padding>
            <Typography variant="overline" component="div" textAlign={"center"} color="primary">
                Training Image to be used as the logo for the training to help the user identify the training.
            </Typography>
        </Grid>
      </Grid>
      <Grid item xs={12} md={6} padding>
        <TextField
            id="video-link"
            label="Video Link"
            size={'small'}
            fullWidth
            helperText={videoLinkError}
            error={!!videoLinkError}
            value={videoLink}
            InputProps={{startAdornment: <InputAdornment position='start'>{null}</InputAdornment>}}
            onChange={handleVideoLinkChange}
        />
      </Grid>
      <Grid item xs={12} padding>
        <TextField
            id="transcript"
            label="Video Transcript"
            size={'small'}
            fullWidth
            multiline
            helperText={transcriptError}
            error={!!transcriptError}
            rows={4}
            value={transcript}
            InputProps={{startAdornment: <InputAdornment position='start'>{null}</InputAdornment>}}
            onChange={handleTranscriptChange}
        />
      </Grid>
      <Grid item xs={12} padding>
        <Divider>
            <Typography variant="overline" component="div" textAlign={"center"} color="primary">
                Quiz Content
            </Typography>
        </Divider>
      </Grid>
        <Grid item xs={12} padding>
            <Typography variant="overline" component="div" textAlign={"center"} color="primary">
                Add a quiz to the training to test the user's knowledge.
            </Typography>
        </Grid>
        <Grid item xs={12} padding textAlign={'center'}>
            {!addQuestion && (
                <Button
                    variant="contained"
                    color="primary"
                    size='small'
                    startIcon={<AddIcon />}
                    onClick={() => setAddQuestion(true)}>
                    Add Question
                </Button>)}
            {addQuestion && (
                <Question
                    onAddQuestion={(question) => handleAddQuestion(question)}
                    onCancel={() => setAddQuestion(false)}/>
            )}
        </Grid>
        {questions.length > 0 &&
            <Grid item xs={12} padding>
                <Typography variant="overline" component="div" textAlign={"center"} color="primary">
                    Current Questions
                </Typography>
                <QuestionList
                    questions={questions}
                    onRemoveQuestion={(index) => setQuestions(questions.filter((question, i) => i !== index))}
                    onQuestionOrderUpdate={handleQuestionUpdate}/>
            </Grid>}
      <Grid item xs={12} padding>
        <Divider>
            <Typography variant="overline" component="div" textAlign={"center"} color="primary">
                Training Taxonomy
            </Typography>
        </Divider>
      </Grid>
      <Grid item xs={12} md={6} padding>
        <TrainingCategorySelector
            value={categoies}
            handleValueChange={(value) => setCategories(value)}
            label={'Training Category'}/>
      </Grid>
      <Grid item xs={12} md={6} padding paddingTop={createCategory ? 2 : 3}>
        {createCategory ? (
            <TextField
                id="new-category"
                label="New Category"
                size={'small'}
                fullWidth
                value={newCategoryName}
                InputProps={{
                    endAdornment: (
                        <InputAdornment onClick={() => handleCancelNew('category')} position='end'>
                            <CancelIcon color={'primary'}/>
                        </InputAdornment>
                    )}}
                onChange={
                    (e) => setNewCategoryName(e.target.value)
                }
            />
        ) : (
            <Button
                size='small'
                onClick={() => setCreateCategory(true)}
                startIcon={<AddIcon/>}>
                Create New Category
            </Button>
        )}
      </Grid>
      <Grid item xs={12} md={6} padding>
        <TrainingTopicSelector
            value={topics}
            handleValueChange={(value) => setTopics(value)}
            label={'Training Topic'}/>
      </Grid>
      <Grid item xs={12} md={6} padding paddingTop={createTopic ? 2 : 3}>
        {createTopic ? (
            <TextField
                id="new-topic"
                label="New Topic"
                size={'small'}
                fullWidth
                value={newTopicName}
                InputProps={{
                    endAdornment: (
                        <InputAdornment onClick={() => handleCancelNew('topic')} position='end'>
                            <CancelIcon color={'primary'}/>
                        </InputAdornment>
                    )}}
                onChange={
                    (e) => setNewTopicName(e.target.value)
                }
            />
        ) : (
            <Button
                size='small'
                onClick={() => setCreateTopic(true)}
                startIcon={<AddIcon/>}>
                Create New Topic
            </Button>
        )}
      </Grid>
      <Grid item xs={12} md={6} padding>
        <TrainingGroupSelector
            value={group}
            handleValueUpdate={(value) => setGroup(value)}
            label={'Training Series'}/>
      </Grid>
      <Grid item xs={12} md={6} padding paddingTop={3}>
        {createGroup ? (
            <TextField
                id="new-series"
                label="New Series"
                size={'small'}
                fullWidth
                value={newGroupName}
                InputProps={{
                    endAdornment: (
                        <InputAdornment onClick={() => handleCancelNew('group')} position='end'>
                            <CancelIcon color={'primary'}/>
                        </InputAdornment>
                    )}}
                onChange={
                    (e) => setNewGroupName(e.target.value)
                }
            />
        ) : (
            <Button
                size='small'
                onClick={() => setCreateGroup(true)}
                startIcon={<AddIcon/>}>
                Create New Series
            </Button>
        )}
      </Grid>
      <Grid item xs={12} md={6} padding>
        <TrainingRolesSelector
            value={roles}
            handleValueChange={(value) => setRoles(value)}
            label={'Training Roles'}/>
      </Grid>
      <Grid item xs={12} padding textAlign={'right'}>
        <LoadingButton
            disabled={isPending}
            loading={isPending}
            variant="contained"
            color="primary"
            onClick={(e) => submitTraining(e)}>
            Create Training
        </LoadingButton>
      </Grid>
        
    </Grid>
  );
}
