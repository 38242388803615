import React, {useState} from "react";
import { Grid, Typography, Select, MenuItem, TextField, Autocomplete, InputAdornment,
         FormControl, InputLabel, Card, CardContent, Button, 
         FormHelperText} from "@mui/material";
import { useApiGet, useApiSend } from '../../utils/httpClient';
import { getRoles, getApplications, getReports, createUser } from '../../endpoints/users';
import ValidatedTextField from "../../Components/ValidatedTextField";
import { emailValidator } from "../../utils/validators";
import MemberSearchModal from "../../Components/MemberSearchModal";
import Add from "@mui/icons-material/Add";


export default function CreateUser() {
    const [firstName, setFirstName] = useState('');
    const [firstNameError, setFirstNameError] = useState(null);
    const [lastName, setLastName] = useState('');
    const [lastNameError, setLastNameError] = useState(null);
    const [email, setEmail] = useState('');
    const [emailValid, setEmailValid] = useState(false);
    const [emailError, setEmailError] = useState(null);
    const [role, setRole] = useState('');
    const [roleError, setRoleError] = useState(null);
    const [application, setApplication] = useState([]);
    const [report, setReport] = useState([]);
    const [imisUser, setImisUser] = useState(false);
    const [openMemberSearch, setOpenMemberSearch] = useState(false);
    const [memberId, setMemberId] = useState(null);

    const { mutate } = useApiSend(
        createUser,
        (data) => {
            console.log(data)
        },
        (error) => {
            console.log(error)
        }
    )

    const { data: applications } = useApiGet(
        ['applications'],
        getApplications,
        {enabled: true}
    )

    const { data: reports } = useApiGet(
        ['reports'],
        getReports,
        {enabled: true}
    )

    const { data: roles } = useApiGet(
        ['roles'],
        getRoles,
        {enabled: true}
    )

    const handleEmailChange = (valid, value) => {
        setEmail(value)
        setEmailValid(valid)
        if (value.length === 0) {
            setEmailError('Email is required')
        } else {
            setEmailError(null)
        }
    }

    const handleFirstNameChange = (value) => {
        setFirstName(value)
        if (value.length === 0) {
            setFirstNameError('First name is required')
        } else {
            setFirstNameError(null)
        }
    }

    const handleLastNameChange = (value) => {
        setLastName(value)
        if (value.length === 0) {
            setLastNameError('Last name is required')
        } else {
            setLastNameError(null)
        }
    }

    const handleRoleChange = (value) => {
        setRole(value)
        if (value.length === 0) {
            setRoleError('Role is required')
        } else {
            setRoleError(null)
        }
    }

    const handleMemberSelect = (member) => {
        handleFirstNameChange(member.first_name)
        handleLastNameChange(member.last_name)
        setEmailValid(!emailValidator(member.email))
        setEmail(member.email)
        setMemberId(member.id)
        setOpenMemberSearch(false)
    }

    const formValid = () => {
        let valid = true
        if (firstName.length === 0) {
            setFirstNameError('First name is required')
            valid = false
        }
        if (lastName.length === 0) {
            setLastNameError('Last name is required')
            valid = false
        }
        if (email.length === 0) {
            setEmailError('Email is required')
            valid = false
        }
        if (role.length === 0) {
            setRoleError('Role is required')
            valid = false
        }
        return valid
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        let valid = formValid()
        if (emailValid && valid) {
            mutate({
                first_name: firstName,
                last_name: lastName,
                email: email,
                role_id: role,
                is_active: true,
                applications: application.map((app) => app.id),
                reports: report.map((rep) => rep.id),
                imis_user: imisUser,
                member_id: memberId
            })
        } else {
            setEmailError('Invalid email address')
        }
    }

    return (
        <Grid container>
            <Grid item xs={12} padding textAlign={'center'}>
                <Typography variant="h4">Create User</Typography>
            </Grid>
            <Grid container item>
                <Grid item xs={12} sm={6} padding>
                    <Typography variant="h6">User Information</Typography>
                </Grid>
                <Grid item xs={12} sm={6} padding textAlign={'right'}>
                    <Button
                        variant="text"
                        color="primary"
                        size={'small'}
                        onClick={() => setOpenMemberSearch(true)}
                    >
                        Search Member
                    </Button>
                </Grid>
                <Grid item xs={12} padding>
                    <Card>
                        <CardContent>
                            <Grid container>
                                <Grid item xs={12} sm={6} padding>
                                    <TextField
                                        variant="standard"
                                        label="First Name"
                                        value={firstName}
                                        onChange={(e) => handleFirstNameChange(e.target.value)}
                                        size={'small'}
                                        error={!!firstNameError}
                                        helperText={firstNameError}
                                        InputProps={{startAdornment: <InputAdornment position='start'>{null}</InputAdornment>}}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} padding>
                                    <TextField
                                        variant="standard"
                                        label="Last Name"
                                        value={lastName}
                                        onChange={(e) => handleLastNameChange(e.target.value)}
                                        size={'small'}
                                        error={!!lastNameError}
                                        helperText={lastNameError}
                                        InputProps={{startAdornment: <InputAdornment position='start'>{null}</InputAdornment>}}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} padding>
                                    <ValidatedTextField
                                        label="Email"
                                        value={email}
                                        onChange={(valid, value) => handleEmailChange(valid, value)}
                                        valueRequired={true}
                                        variant="standard"
                                        errorOverride={emailError}
                                        validator={emailValidator}/>
                                </Grid>
                                <Grid item xs={12} sm={6} padding>
                                    <FormControl fullWidth error={!!roleError}>
                                        <InputLabel id="role-label">Role</InputLabel>
                                        <Select
                                            variant="standard"
                                            label="Role"
                                            value={role}
                                            onChange={(e) => handleRoleChange(e.target.value)}
                                            fullWidth
                                            size={'small'}
                                        >
                                            {roles?.map((role) => (
                                                <MenuItem key={role.id} value={role.id}>{role.name}</MenuItem>
                                            ))}
                                        </Select>
                                        {!!roleError && <FormHelperText>{roleError}</FormHelperText>}
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={6} padding>
                                    <FormControl fullWidth>
                                        <InputLabel id="imis-user-label">iMIS User</InputLabel>
                                        <Select
                                            label="iMIS User"
                                            value={imisUser}
                                            variant="standard"
                                            onChange={(e) => setImisUser(e.target.value)}
                                            fullWidth
                                            size={'small'}
                                        >
                                            <MenuItem value={true}>Yes</MenuItem>
                                            <MenuItem value={false}>No</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>


            <Grid item xs={12} padding>
                <Typography variant="h6">Permissions</Typography>
                <Card>
                    <CardContent>
                        <Grid container>
                            <Grid item xs={12} sm={6} padding>
                                <Autocomplete
                                    value={application}
                                    options={applications || []}
                                    getOptionLabel={(option) => option.name}
                                    onChange={(event, newValue) => setApplication(newValue)}
                                    fullWidth
                                    size='small'
                                    multiple
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="standard"
                                            label={'Applications'}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} padding>
                                <Autocomplete
                                    value={report}
                                    options={reports || []}
                                    getOptionLabel={(option) => option.name}
                                    onChange={(event, newValue) => setReport(newValue)}
                                    fullWidth
                                    size='small'
                                    multiple
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="standard"
                                            label={'Reports'}
                                        />
                                    )}
                                />
                            </Grid>

                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={12} padding textAlign={'right'}>
                <Button
                    variant="contained"
                    size='small'
                    color="primary"
                    startIcon={<Add />}
                    onClick={handleSubmit}
                >
                    Create User
                </Button>
            </Grid>
            <MemberSearchModal
                modalOpen={openMemberSearch}
                setModalOpen={(value) => setOpenMemberSearch(value)}
                setUser={(member) => handleMemberSelect(member)}/>
            
        </Grid>
    );
}