import { request } from "../utils/httpClient"

export const login = (data) => request({
    url: "/login",
    method: "POST",
    data
})

export const logout = () => request({
    url: "/logout",
    method: "POST",
    data: {"refresh_token": localStorage.getItem("refresh")}
})

export const getMe = () => request({
    url: "/refresh",
    method: "GET",
    headers: {
        Authorization: `Bearer ${localStorage.getItem("refresh")}`,
    }
})