import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Grid, Typography, Divider, ButtonGroup, Chip } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import QuestionLine from '../../Components/QuestionLine';
import { formatCurrency } from '../../utils/formatters';
import { irsSubmitAnswers } from '../../endpoints/annualReports';
import { getIRSDocument, deleteDocument, downloadDocument } from '../../endpoints/documents';
import { formClient, useApiGet, useApiSend } from '../../utils/httpClient';
import { receiptQuestions } from '../../utils/variables';


const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });

export default function Receipts ({questions, refetch, editable, otherQuestions, alertOpen, alertMessage,
                                   alertSeverity, userEditing, updateUserEditing}) {
    let { id } = useParams();
    const formValid = useRef({
        question1: true, question2: true, question3: true, question7: true, question8: true,
        question9: true, question10: true, subQuestion3: true, subQuestion4: true,
        question11: true
    });
    const [question1, setQuestion1] = useState(0);
    const [question2, setQuestion2] = useState(0);
    const [subQuestion3, setSubQuestion3] = useState(false);
    const [subQuestion3Error, setSubQuestion3Error] = useState(null);
    const [subQuestion4, setSubQuestion4] = useState(0);
    const [subQuestion4Error, setSubQuestion4Error] = useState(null);
    const [question3, setQuestion3] = useState(0);
    const [question7, setQuestion7] = useState(0);
    const [question8, setQuestion8] = useState(0);
    const [question9, setQuestion9] = useState(0);
    const [question10Text, setQuestion10Text] = useState('');
    const [question10Number, setQuestion10Number] = useState(0);
    const [question10Error, setQuestion10Error] = useState(null);
    const [question11Text, setQuestion11Text] = useState('');
    const [question11Number, setQuestion11Number] = useState(0);
    const [question11Error, setQuestion11Error] = useState(null);
    const [documentLoading, setDocumentLoading] = useState(false);
    const [downloadDocId, setDownloadDocId] = useState(null);
    const [answersSet, setAnswersSet] = useState(false);

    const handleScrollToTop = () => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
      };

    const { mutate } = useApiSend(
        irsSubmitAnswers,
        (data) => {
            handleScrollToTop()
            alertMessage("Receipts successfully updated.")
            alertSeverity("success")
            alertOpen()
            refetch()
        },
        (error) => {
            console.log(error)
            alertMessage("Error updating Receipts. If the problem persists, please contact support.")
            alertSeverity("error")
            alertOpen()
        }
    )

    const { data: documents, refetch: docRefetch } = useApiGet(
        ['receipt_documents', id],
        getIRSDocument,
        {
            enabled: true,
            retry: 1,
        },
        {
            id: id,
        }
    )

    const { mutate: deleteDoc } = useApiSend(
        deleteDocument,
        (data) => {
            docRefetch()
        },
        (error) => {
            console.log(error)
        }
    )

    const { data: downloadDoc } = useApiGet(
        ['download_document', downloadDocId],
        downloadDocument,
        {
            enabled: !downloadDocId ? false : true,
            retry: 1,
        },
        { id: downloadDocId }
    )


    const setAnswers = useCallback(() => {
        let q10Handed = false
        questions.sort((a, b) => a.id - b.id).forEach((answers) => {
            if (answers.question_id === receiptQuestions.question1) {
                setQuestion1(answers.answer)
            } else if (answers.question_id === receiptQuestions.question2) {
                setQuestion2(answers.answer)
            } else if (answers.question_id === receiptQuestions.subQuestion3) {
                setSubQuestion3(answers.answer)
            } else if (answers.question_id === receiptQuestions.subQuestion4) {
                setSubQuestion4(answers.answer)
            } else if (answers.question_id === receiptQuestions.question3) {
                setQuestion3(answers.answer)
            } else if (answers.question_id === receiptQuestions.question7) {
                setQuestion7(answers.answer)
            } else if (answers.question_id === receiptQuestions.question8) {
                setQuestion8(answers.answer)
            } else if (answers.question_id === receiptQuestions.question9) {
                setQuestion9(answers.answer)
            } else if (answers.question_id === receiptQuestions.question10) {
                if (!q10Handed) {
                    setQuestion10Number(answers.answer_2)
                    setQuestion10Text(answers.answer)
                    q10Handed = true
                } else {
                    setQuestion11Number(answers.answer_2)
                    setQuestion11Text(answers.answer)
                }
            }
        })
    }, [questions])

    useEffect(() => {
        setAnswers()
        setAnswersSet(true)
    }, [questions, setAnswers])

    const answersUpdated = useCallback(() => {
        let answersUpdated = false
        let q10Handed = false
        questions.sort((a, b) => a.id - b.id).forEach((answers) => {
            if (answers.question_id === receiptQuestions.question1 && question1 !== answers.answer) {
                answersUpdated = true
            } else if (answers.question_id === receiptQuestions.question2 && question2 !== answers.answer) {
                answersUpdated = true
            } else if (answers.question_id === receiptQuestions.subQuestion3 && subQuestion3 !== answers.answer) {
                answersUpdated = true
            } else if (answers.question_id === receiptQuestions.subQuestion4 && subQuestion4 !== answers.answer) {
                answersUpdated = true
            } else if (answers.question_id === receiptQuestions.question3 && question3 !== answers.answer) {
                answersUpdated = true
            } else if (answers.question_id === receiptQuestions.question7 && question7 !== answers.answer) {
                answersUpdated = true
            } else if (answers.question_id === receiptQuestions.question8 && question8 !== answers.answer) {
                answersUpdated = true
            } else if (answers.question_id === receiptQuestions.question9 && question9 !== answers.answer) {
                answersUpdated = true
            } else if (answers.question_id === receiptQuestions.question10) {
                if (!q10Handed) {
                    if (question10Number !== answers.answer_2 || question10Text !== answers.answer) {
                        answersUpdated = true
                    }
                    q10Handed = true
                } else {
                    if (question11Number !== answers.answer_2 || question11Text !== answers.answer) {
                        answersUpdated = true
                    }
                }
            }
        })
        return answersUpdated
    }, [question1, question2, subQuestion3, subQuestion4, question3, question7, question8, question9, question10Text,
        question10Number, question11Text, question11Number, questions])

    useEffect(() => {
        if (answersUpdated() && answersSet && !userEditing) {
            updateUserEditing(true)
        }
    }, [answersUpdated, updateUserEditing, answersSet, userEditing])

    const updateQuestion2 = (value) => {
        setQuestion2(value)
        if (value && value < 5000) {
            setSubQuestion3(null)
            setSubQuestion4(null)
        }
    }

    const updateSubQuestion3 = (value) => {
        setSubQuestion3(value)
        if (value === '1') {
            setSubQuestion3Error('Please enter a value')
        } else {
            setSubQuestion3Error(null)
            if (!value) {
                setSubQuestion4(null)
            }
        }
        
    }

    const updateSubQuestion4 = (value) => {
        setSubQuestion4(value || 0)
        if (parseFloat(value || 0) > parseFloat(question2)) {
            setSubQuestion4Error('Amount must be less than or equal to the total')
        } else {
            setSubQuestion4Error(null)
        }
    }

    const updateQuestion10Text = (value) => {
        setQuestion10Text(value)
        if (value && question10Number) {
            setQuestion10Error(null)
        } else if (!value && !question10Text) {
            setQuestion10Error(null)
        }
    }

    const updateQuestion10Number = (value) => {
        setQuestion10Number(value || 0)
        if (value && question10Text) {
            setQuestion10Error(null)
        } else if (!value && !question10Text) {
            setQuestion10Error(null)
        }
    }

    const updateQuestion11Text = (value) => {
        setQuestion11Text(value)
        if (value && question11Number) {
            setQuestion11Error(null)
        } else if (!value && !question11Text) {
            setQuestion11Error(null)
        }
    }

    const updateQuestion11Number = (value) => {
        setQuestion11Number(value || 0)
        if (value && question11Text) {
            setQuestion11Error(null)
        } else if ((!value || value !== 0) && !question11Text) {
            setQuestion11Error(null)
        }
    }

    const onSubmitForm = (completed=true) => {
        let formValidCopy = { ...formValid.current }
        let validForm = true
        if (!formValidCopy.question1) {
            validForm = false
        }
        if (!formValidCopy.question2) {
            validForm = false
        }
        if (question2 && question2 >= 5000) {
            if (subQuestion3Error || subQuestion4Error) {
                validForm = false
            }
            if (subQuestion3 === '1' || subQuestion3 === null || !formValidCopy.subQuestion3) {
                validForm = false
                setSubQuestion3Error('Please enter a value')
            }
            if (subQuestion3 && (!subQuestion4 || !formValidCopy.subQuestion4)) {
                validForm = false
                setSubQuestion4Error('Amount must be entered')
            }
        }
        if (!formValidCopy.question3) {
            validForm = false
        }
        if (!formValidCopy.question7) {
            validForm = false
        }
        if (!formValidCopy.question8) {
            validForm = false
        }
        if (!formValidCopy.question9) {
            validForm = false
        }
        if (!formValidCopy.question10) {
            validForm = false
        }
        if ((question10Text || (question10Number && question10Number > 0)) && (!question10Text || !question10Number)) {
            validForm = false
            setQuestion10Error('Please enter both a description and a number')
        }
        if ((question11Text || (question11Number && question11Number > 0)) && (!question11Text || !question11Number)) {
            validForm = false
            setQuestion11Error('Please enter both a description and a number')
        }

        if (!validForm) {
            console.log('invalid form')
            return
        }
        let answers = [
            {
                question_id: questions.filter(q => q.question_id === receiptQuestions.question1)[0]?.id,
                answer: question1 === null ? null : question1.toString(),
            },
            {
                question_id: questions.filter(q => q.question_id === receiptQuestions.question2)[0]?.id,
                answer: question2 === null ? null : question2.toString(),
            },
            {
                question_id: questions.filter(q => q.question_id === receiptQuestions.subQuestion3)[0]?.id,
                answer: subQuestion3 === null ? null : subQuestion3.toString(),
            },
            {
                question_id: questions.filter(q => q.question_id === receiptQuestions.subQuestion4)[0]?.id,
                answer: subQuestion4 === null ? null : subQuestion4.toString(),
            },
            {
                question_id: questions.filter(q => q.question_id === receiptQuestions.question3)[0]?.id,
                answer: question3 === null ? null : question3.toString(),
            },
            {
                question_id: questions.filter(q => q.question_id === receiptQuestions.question7)[0]?.id,
                answer: question7 === null ? null : question7.toString(),
            },
            {
                question_id: questions.filter(q => q.question_id === receiptQuestions.question8)[0]?.id,
                answer: question8 === null ? null : question8.toString(),
            },
            {
                question_id: questions.filter(q => q.question_id === receiptQuestions.question9)[0]?.id,
                answer: question9 === null ? null : question9.toString(),
            },
            {
                question_id: questions.filter(q => q.question_id === receiptQuestions.question10)[0]?.id,
                answer: question10Text ? question10Text.toString() : null,
                answer_2: question10Number === null ? null : question10Number.toString(),
            },
            {
                question_id: questions.filter(q => q.question_id === receiptQuestions.question10)[1]?.id,
                answer: question11Text ? question11Text.toString() : null,
                answer_2: question11Number === null ? null : question11Number.toString(),
            },
        ]
        mutate({
            id: id,
            answers: answers,
            section_completed: completed,
            section: 'receipts'
        })
    }

    const sendDocument = async (fileData) => {
        setDocumentLoading(true)
        await formClient.post('/document/file_upload', fileData)
        setDocumentLoading(false)
        docRefetch()
    }

    const handleFileUpload = (event) => {
        const formData = new FormData()
        formData.append('file', event.target.files[0])
        let data = {
            'file_type': 'irs_form',
            'related_id': id
        }
        formData.append('doc_info', JSON.stringify(data))
        sendDocument(formData)
    }

    const handleDocumentDelete = (id) => {
        deleteDoc({id: id})
    }

    const documentPreview = (id) => {
        setDownloadDocId(id)
    }

    useEffect(() => {
        if (downloadDoc && downloadDoc.download_url) {
            window.open(downloadDoc.download_url, '_blank')
            setDownloadDocId(null)
        }
    }, [downloadDoc])

    return (
        <Grid container>
            {questions.filter(q => q.question_id === receiptQuestions.question1).map((question) => (
                <QuestionLine
                    key={1}
                    questionNumber={1}
                    disabled={!editable}
                    question={question.question}
                    questionId={question.question_id}
                    answerType={question.answer_type}
                    answer={question1}
                    onChange={(value) => setQuestion1(value)}
                    onFormChange={(isValid) => formValid.current.question1 = isValid}
                />))}
            {questions.filter(q => q.question_id === receiptQuestions.question2).map((question) => (
                <QuestionLine
                    key={2}
                    questionNumber={2}
                    disabled={!editable}
                    question={question.question}
                    questionId={question.question_id}
                    answerType={question.answer_type}
                    answer={question2}
                    onChange={(value) => updateQuestion2(value)}
                    onFormChange={(isValid) => formValid.current.question2 = isValid}
                />))}
            {question2 >= 5000 &&
                questions.filter(q => q.question_id === receiptQuestions.subQuestion3).map((question) => (
                    <QuestionLine
                        key={3}
                        questionNumber={'2a'}
                        disabled={!editable}
                        question={question.question}
                        questionId={question.question_id}
                        answerType={question.answer_type}
                        answer={subQuestion3}
                        errorOverride={subQuestion3Error}
                        onChange={(value) => updateSubQuestion3(value)}
                        onFormChange={(isValid) => formValid.current.subQuestion3 = isValid}
                    />))}
            {question2 >= 5000 && subQuestion3 && 
                questions.filter(q => q.question_id === receiptQuestions.subQuestion4).map((question) => (
                    <QuestionLine
                        key={4}
                        questionNumber={'2b'}
                        disabled={!editable}
                        question={question.question}
                        questionId={question.question_id}
                        answerType={question.answer_type}
                        answer={subQuestion4}
                        errorOverride={subQuestion4Error}
                        onChange={(value) => updateSubQuestion4(value)}
                        onFormChange={(isValid) => formValid.current.subQuestion4 = isValid}
                    />))}
            {question2 >= 5000 && subQuestion3 &&
                <Grid container item>
                    <Grid item xs={9} padding>
                        <Typography variant="body1">
                            <b>2c.</b>  Upload bequest documentation. A copy of the will or trust is preferred.
                        </Typography>
                    </Grid>
                    {documents && documents.length > 0 && (
                        <Grid item xs={3} padding>
                            {documents.map((doc) => (
                                <Chip
                                    variant='outlined'
                                    color='orange'
                                    clickable={true}
                                    onClick={() => documentPreview(doc.id)}
                                    disabled={!editable || documentLoading}
                                    key={doc.id}
                                    label={doc.description}
                                    onDelete={() => handleDocumentDelete(doc.id)}/>
                                ))}
                        </Grid>
                    )}
                    {documents && documents.length > 0 && (
                        <Grid item xs={9} padding />
                        )}
                    <Grid item xs={3} padding>
                        <LoadingButton
                            size='small'
                            disabled={!editable || documentLoading}
                            component="label"
                            role={undefined}
                            variant="contained"
                            loading={documentLoading}
                            tabIndex={-1}
                            fullWidth
                            onChange={handleFileUpload}
                            startIcon={<CloudUploadIcon />}
                        >
                            Upload file
                            <VisuallyHiddenInput type="file" />
                        </LoadingButton>
                    </Grid>
                </Grid> }
            {questions.filter(q => q.question_id === receiptQuestions.question3).map((question) => (
                <QuestionLine
                    key={5}
                    questionNumber={3}
                    disabled={!editable}
                    question={question.question}
                    questionId={question.question_id}
                    answerType={question.answer_type}
                    answer={question3}
                    onChange={(value) => setQuestion3(value)}
                    onFormChange={(isValid) => formValid.current.question3 = isValid}
                />))}
            {questions.filter(q => q.question_id === receiptQuestions.question7).map((question) => (
                <QuestionLine
                    key={9}
                    questionNumber={4}
                    disabled={!editable}
                    question={question.question}
                    questionId={question.question_id}
                    answerType={question.answer_type}
                    answer={question7}
                    onChange={(value) => setQuestion7(value)}
                    onFormChange={(isValid) => formValid.current.question7 = isValid}
                />))}
            {questions.filter(q => q.question_id === receiptQuestions.question8).map((question) => (
                <QuestionLine
                    key={10}
                    questionNumber={5}
                    disabled={!editable}
                    question={question.question}
                    questionId={question.question_id}
                    answerType={question.answer_type}
                    answer={question8}
                    onChange={(value) => setQuestion8(value)}
                    onFormChange={(isValid) => formValid.current.question8 = isValid}
                />))}
            {questions.filter(q => q.question_id === receiptQuestions.question9).map((question) => (
                <QuestionLine
                    key={11}
                    questionNumber={6}
                    disabled={!editable}
                    question={question.question}
                    questionId={question.question_id}
                    answerType={question.answer_type}
                    answer={question9}
                    onChange={(value) => setQuestion9(value)}
                    onFormChange={(isValid) => formValid.current.question9 = isValid}
                />))}
            {questions.filter(q => q.question_id === receiptQuestions.question10).slice(0, 1).map((question) => (
                <QuestionLine
                    key={12}
                    questionNumber={7}
                    disabled={!editable}
                    question={question.question}
                    answerType={question.answer_type}
                    answer={question10Text}
                    onChange={(value) => updateQuestion10Text(value)}
                    onFormChange={(isValid) => formValid.current.question10 = isValid}
                    answer2={question10Number}
                    answer2OnChange={(value) => updateQuestion10Number(value)}
                    errorOverride={question10Error}
                    answer3={question11Text}
                    answer3OnChange={(value) => updateQuestion11Text(value)}
                    onFormChange2={(isValid) => formValid.current.question11 = isValid}
                    answer4={question11Number}
                    answer4OnChange={(value) => updateQuestion11Number(value)}
                    errorOverride2={question11Error}
                    twoAnswers={true}
                />))}
            <Grid item xs={12} padding={2}>
                <Divider />
            </Grid>
            <Grid item xs={12}>
                <Typography variant="body1" fontWeight={'bold'} textAlign={'right'}>
                    Total: {
                      formatCurrency(
                        parseFloat(question10Number || 0) +
                        parseFloat(question11Number || 0) +
                        parseFloat(question1 || 0) +
                        parseFloat(question2 || 0) +
                        parseFloat(question3 || 0) +
                        parseFloat(question7 || 0) +
                        parseFloat(question8 || 0) +
                        parseFloat(question9 || 0)
                    )}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="body1" fontWeight={'bold'} textAlign={'right'}>
                    + Receipts from Goods Sold: {
                        formatCurrency(otherQuestions.filter(q => q.question_id === '4c50bf2c5451427e8bc487dafaa70060')[0]?.answer || 0)
                    }
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="body1" fontWeight={'bold'} textAlign={'right'}>
                    + Receipts from Operating Bed and Breakfast: {
                        formatCurrency(otherQuestions.filter(q => q.question_id === '1885ea03cb094fe8847ba269a8b07c61')[0]?.answer || 0)
                    }
                </Typography>
            </Grid>
            <Grid item xs={12} padding={2}>
                <Divider />
            </Grid>
            <Grid item xs={12} paddingBottom={2}>
                <Typography variant="body1" fontWeight={'bold'} textAlign={'right'}>
                    Receipts Total: {
                      formatCurrency(
                        parseFloat(question10Number || 0) +
                        parseFloat(question11Number || 0) +
                        parseFloat(question1 || 0) +
                        parseFloat(question2 || 0) +
                        parseFloat(question3 || 0) +
                        parseFloat(question7 || 0) +
                        parseFloat(question8 || 0) +
                        parseFloat(question9 || 0) +
                        (otherQuestions.filter(q => q.question_id === '4c50bf2c5451427e8bc487dafaa70060')[0]?.answer || 0) +
                        (otherQuestions.filter(q => q.question_id === '1885ea03cb094fe8847ba269a8b07c61')[0]?.answer || 0)
                    )}
                </Typography>
            </Grid>
            <Grid item xs={12} padding={2}>
                <Divider />
            </Grid>
            <Grid item xs={12} textAlign={'right'}>
                <ButtonGroup>
                    <LoadingButton
                        color="green"
                        loading={false}
                        variant='contained'
                        size='small'
                        sx={{ fontWeight: 'bold' }}
                        disabled={!editable}
                        onClick={() => onSubmitForm(true)}
                    >
                        Save and Complete Receipts
                    </LoadingButton>
                </ButtonGroup>
            </Grid>

        </Grid>
    );
}