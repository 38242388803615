import React, {useState, useEffect} from 'react';
import { TableVirtuoso } from 'react-virtuoso';
import { Card, CardContent, CardHeader, Grid, Paper, Table, TableContainer, Link,
         Typography, TableHead, TableRow, TableBody, TableCell, Box, 
         TextField} from '@mui/material';


function DashboardCard ({ title, value, color, selected, handleSelected, id }) {
  return (
      <Card height={100} onClick={() => handleSelected(id)}>
        <CardContent sx={{ backgroundColor: selected ? 'rgba(131, 1, 62, 0.2)' : 'white'}}>
          <Grid container>
            <Grid item xs={12} textAlign={'center'}>
              {color === 'primary'  ? (
                <Typography variant="h3" component="h2" color={color}>
                  {value}
                </Typography>
              ): (
                <Typography variant="h3" component="h2" sx={{ color: {color} }}>
                  {value}
                </Typography>
              )}
              
            </Grid>
            <Grid item xs={12} textAlign={'center'}>
              <Typography variant="overline">
                {title}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
  );
}

function DashboardCardTable ({ data, title, irs=false }) {
  const [search, setSearch] = useState('');
  const [filteredData, setFilteredData] = useState(data);

  useEffect(() => {
    if (search === '') {
      setFilteredData(data);
    } else {
      setFilteredData(data.filter((item) => item.name.toLowerCase().includes(search.toLowerCase())));
    }
  }, [search, data]);

  const columns = [
    {
      width: 50,
      label: 'Chapter',
      dataKey: 'name',
    },
    {
      width: 50,
      label: 'State / Province / District',
      dataKey: 'state',
    },
    {
      width: 50,
      label: 'Disbanded?',
      dataKey: 'disbanded',
    },
    {
      width: 150,
      label: 'Step',
      dataKey: 'formState',
      skip: true
    },
  ];

  const irsColumns = [
    {
      width: 50,
      label: 'Chapter',
      dataKey: 'name',
    },
    {
      width: 50,
      label: 'State / District',
      dataKey: 'state',
    },
    {
      width: 200,
      label: 'Step',
      dataKey: 'formState',
      skip: true
    },
  ]
  
  const VirtuosoTableComponents = {
    Scroller: React.forwardRef((props, ref) => (
      <TableContainer component={Paper} {...props} ref={ref} />
    )),
    Table: (props) => (
      <Table size="small" {...props} sx={{ borderCollapse: 'separate', tableLayout: 'fixed' }} />
    ),
    TableHead: React.forwardRef((props, ref) => <TableHead {...props} ref={ref} />),
    TableRow,
    TableBody: React.forwardRef((props, ref) => <TableBody {...props} ref={ref} />),
  };

  const columnsToUse = irs ? irsColumns : columns;
  
  function fixedHeaderContent() {
    return (
      <TableRow>
        {columnsToUse.map((column) => (
          <TableCell
            key={column.dataKey}
            variant="head"
            align={column.dataKey === 'action' ? 'center' : 'center'}
            style={{ width: column.width }}
            sx={{
              backgroundColor: 'background.paper',
            }}
          >
            {column.label}
          </TableCell>
        ))}
      </TableRow>
    );
  }
  const rowContent = (_index, row) => {
    return (
        <React.Fragment>
          {columnsToUse.map((column) => (
            <TableCell
              key={column.dataKey}
              align={'center'}
              onClick={() => window.location.href = irs ? `/irs/${row.id}` : `/roster/${row.id}`}>
                <Link href={irs ? `/irs/${row.id}` : `/roster/${row.id}`}>
                  {row[column.dataKey]}
                </Link>
            </TableCell>))}
        </React.Fragment>
      );
  }
  return (
    <Card height={50}>
      <CardHeader
        title={title}
        sx={{ color: (theme) => theme.palette.primary.main}}
        action={
          <TextField
            id="search"
            label="Search"
            variant="standard"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
        />}/>
      <CardContent>
        <Grid container>
          <Grid item xs={12} textAlign={'center'}>
            <Box sx={{ height: 200, width: 'auto' }}>
                <TableVirtuoso
                    data={filteredData}
                    components={VirtuosoTableComponents}
                    fixedHeaderContent={fixedHeaderContent}
                    itemContent={rowContent}
                />
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
);
}

export default function SPDDashboard ({data}) {

  const [selected, setSelected] = useState('total-assigned-forms');
  const [selectedIrs, setSelectedIrs] = useState('total-assigned-irs');

  let dashboardRosterData = [
    {
      title: 'Pending Roster and Dues',
      value: data?.totalPendingForms,
      id: 'total-pending-forms',
      color: 'blue',
      dataKey: 'pendingForms'
    },
    {
      title: 'Assigned Roster and Dues',
      value: data?.totalAssignedForms,
      id: 'total-assigned-forms',
      color: 'primary',
      dataKey: 'assignedForms'
    },
    {
      title: 'Completed Roster and Dues',
      value: data?.totalCompletedForms,
      id: 'total-completed-forms',
      color: 'green',
      dataKey: 'completedForms'
    },
  ]

  let dashboardIrsData = [
    {
      title: 'Pending IRS-LC',
      value: data?.totalPendingIrs,
      id: 'total-pending-irs',
      color: 'blue',
      dataKey: 'pendingIrs'
    },
    {
      title: 'Assigned IRS-LC',
      value: data?.totalAssignedIrs,
      id: 'total-assigned-irs',
      color: 'primary',
      dataKey: 'assignedIrs'
    },
    {
      title: 'Completed IRS-LC',
      value: data?.totalCompletedIrs,
      id: 'total-completed-irs',
      color: 'green',
      dataKey: 'completedIrs'
    },
  ]


  return (
    <Grid container>
        {dashboardRosterData.map((card) => (
          <Grid item xs={12} sm={6} md={4} lg={2} padding key={card.id}>
            <DashboardCard
              title={card.title}
              value={card.value}
              color={card.color}
              selected={card.id === selected}
              id={card.id}
              handleSelected={(value) => setSelected(value)}
              />
          </Grid>
        ))}
        {dashboardIrsData.map((card) => (
          <Grid item xs={12} sm={6} md={4} lg={2} padding key={card.id}>
            <DashboardCard
              title={card.title}
              value={card.value}
              color={card.color}
              selected={card.id === selectedIrs}
              id={card.id}
              handleSelected={(value) => setSelectedIrs(value)}
              />
          </Grid>
        ))}

        <Grid item xs={12} md={6} padding={2}>
          <DashboardCardTable
            data={data[dashboardRosterData.find((item) => item.id === selected).dataKey]}
            title={dashboardRosterData.find((item) => item.id === selected).title}/>
        </Grid>
        <Grid item xs={12} md={6} padding={2}>
          <DashboardCardTable
            data={data[dashboardIrsData.find((item) => item.id === selectedIrs).dataKey]}
            title={dashboardIrsData.find((item) => item.id === selectedIrs).title}
            irs={true}/>
        </Grid>
    </Grid>
  );
}
