import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { useSearchParams } from "react-router-dom";
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import { visuallyHidden } from '@mui/utils';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import { useApiGet } from '../../utils/httpClient';
import { getUsers } from '../../endpoints/users';
import { useCallback } from 'react';
import { Add } from '@mui/icons-material';


const headCells = [
  {
    id: 'first_name',
    firstCell: true,
    label: 'First Name',
    width: '30%'
  },
  {
    id: 'last_name',
    numeric: false,
    disablePadding: false,
    label: 'Last Name',
    width: '30%'
  },
  {
    id: 'email',
    numeric: false,
    disablePadding: false,
    label: 'Email',
    width: '30%'
  },
  {
    id: 'is_active',
    numeric: false,
    disablePadding: false,
    label: 'Active?',
    width: '10%'
  },
];

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.primary.veryLight,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));


function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } =
    props;
  const createSortHandler =
    (property) => (event) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <StyledTableCell
            color="white"
            key={headCell.id}
            align={headCell.firstCell ? 'left' : 'right'}
            variant='head'
            padding={'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{
              fontWeight: 'bold',
              fontSize: 16,
              "&hover": {
                cursor: 'pointer',
                color: 'primary'
              },
              width: headCell.width
            }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

function FakeLoadTable ({tableRowCount}) {
  return (
    <TableBody>
      {Array.from(new Array(tableRowCount)).map((_, index) => (
        <TableRow key={index}>
          <TableCell align="left" padding='normal'>
            <Skeleton variant="text" width="100%"/>
          </TableCell>
          <TableCell align="right" padding='normal'>
            <Skeleton variant="text" width="100%" />
          </TableCell>
          <TableCell align="right" padding='normal'>
            <Skeleton variant="text" width="100%" />
          </TableCell>
          <TableCell align="right" padding='normal'>
            <Skeleton variant="text" width="100%" />
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  )

}

export default function EnhancedTable() {
  let [searchParams, setSearchParams] = useSearchParams()

  const updateSearchParams = useCallback((key, value) => {
    if (!value || value === '') {
      searchParams.delete(key)
    } else {
      searchParams.set(key, value)
    }
    setSearchParams(searchParams)
  }, [searchParams, setSearchParams])

  const [order, setOrder] = useState('');
  const [orderBy, setOrderBy] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [search, setSearch] = useState(searchParams.get('search', ''))
  const [delaySearch, setDelaySearch] = useState(searchParams.get('search', ''))
  
  // need up update search params when search changes
  useEffect(() => {
    updateSearchParams('search', search)
  }, [search, updateSearchParams])

  const { 
    data, isFetching
  } = useApiGet(
    ['user-list', rowsPerPage, page, search],
    getUsers,
    {
      enabled: true,
      retry: 1,
    },
    {
      rowsPerPage, page, search
    }
  )

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setSearch(delaySearch)
    }, 500)
    return () => clearTimeout(timeoutId)
  }, [delaySearch])

  const handleRequestSort = (
    event,
    property,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleClick = (event, id) => {
    window.location.href = `/users/${id}`
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleCreate = () => {
    window.location.href = '/users/create';
  }

  return (
    <Grid container padding={3}>
      <Grid item xs={12} paddingTop={2} paddingBottom={5}>
        <Typography variant="h4" component="div" textAlign={"center"} color="primary">
          Users
        </Typography>
      </Grid>
      <Grid container>
        <Grid item xs={12} sm={6} md={4} paddingRight={2} paddingBottom={3}>
          <TextField
              label="Search"
              id="search-field"
              size='small'
              value={delaySearch}
              fullWidth={true}
              onChange={(event) => setDelaySearch(event.target.value)}
              InputProps={{
                startAdornment: <InputAdornment position="start"><SearchIcon/></InputAdornment>,
              }}
            />
        </Grid>
        <Grid item xs={12} sm={6} md={8} padding paddingBottom={3} textAlign={'right'}>
            <Button
                onClick={() => handleCreate()}
                variant='contained'
                sx={{ fontWeight: 'bold' }}
                size='small'
                startIcon={<Add />}>
                Create User
            </Button>
        </Grid>
      </Grid>
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={'medium'}
          >
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            {isFetching && (<FakeLoadTable tableRowCount={rowsPerPage} />)}
            {!isFetching && <TableBody>
              {data?.map((row, index) => {
                return (
                  <StyledTableRow
                    hover
                    onClick={(event) => handleClick(event, row.id)}
                    role="checkbox"
                    tabIndex={-1}
                    key={row.id}
                    sx={{ cursor: 'pointer' }}
                  >
                    <TableCell align="left" padding='normal' sx={{ width: '10%' }}>
                      {row.first_name}
                    </TableCell>
                    <TableCell align="right" padding='normal' sx={{ width: '30%' }}>
                      {row.last_name}
                    </TableCell>
                    <TableCell align="right" padding='normal' sx={{ width: '30%' }}>
                      {row.email}
                    </TableCell>
                    <TableCell align="right" padding='normal'sx={{ width: '30%' }}>
                      {row.is_active ? 'Yes' : 'No'}
                    </TableCell>
                  </StyledTableRow>
                );
              })}
            </TableBody>}
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[25, 50, 100]}
          component="div"
          count={-1}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          slotProps={{
            'actions': {
              "nextButton": { 'disabled': data?.length < rowsPerPage },
            }
          }}
        />
      </Paper>
    </Box>
    </Grid>
  );
}