import React, {useState, useEffect, useCallback} from 'react';
import { useParams } from 'react-router-dom';
import { Grid, Typography, Divider, IconButton, ButtonGroup, Button, TextField, Link } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { LoadingButton } from '@mui/lab';
import { formatCurrency } from '../../utils/formatters';
import { useApiSend, documentClient } from '../../utils/httpClient';
import { irsApprove, irsReject } from '../../endpoints/annualReports';
import { receiptQuestions, disbursementQuestions, assetQuestions } from '../../utils/variables';
import dayjs from 'dayjs';
import AlertItem from '../../Components/AlertItem';
import { irsStates } from '../../utils/variables';


function LineItem ({label, value, subTotal=false, icon=null, error=false, errorDescrption=''}) {
  return (
    <Grid container padding={1}>
      <Grid item xs={9} paddingLeft={subTotal ? 5 : 0}>
        {icon ? (
          <Typography variant="body1" fontWeight={'bold'}>{label}{icon}</Typography>
        ): (
          <Typography variant="body1" fontWeight={subTotal ? 'normal' : 'bold'}>{label}</Typography>
        )}
      </Grid>
      {subTotal ? (
        <Grid item xs={3} textAlign={'right'} paddingRight={3}>
          <Typography variant="body1">{value}</Typography>
        </Grid>
      ) : (
        <Grid item xs={3} textAlign={'right'}>
          <Typography variant="body1" fontWeight={"bold"} color={error ? 'error' : 'black'}>{value}</Typography>
          {error && <Typography variant="caption" color={'error'}>{errorDescrption}</Typography>}
        </Grid>
      )}
      
    </Grid>
  )
}

const totalReceiptsQuestions = [
  receiptQuestions.question1,
  receiptQuestions.question2,
  receiptQuestions.question3,
  receiptQuestions.question7,
  receiptQuestions.question8,
  receiptQuestions.question9,
  receiptQuestions.question10,
]

const totalDisbursementsQuestions = [
  disbursementQuestions.question1,
  disbursementQuestions.question2,
  disbursementQuestions.question3,
  disbursementQuestions.question4,
  // disbursementQuestions.question5,
  disbursementQuestions.question6,
  disbursementQuestions.question9,
  disbursementQuestions.question10,
  disbursementQuestions.question0,
]

const totalAssetsQuestions = [
  assetQuestions.question1,
  assetQuestions.question3,
  assetQuestions.question4,
  assetQuestions.question5,
  assetQuestions.question6,
  assetQuestions.question7,
]

export default function Totals ({questions, previousTotal, refetchParent, editable, formNotes, handleRedirectTab,
                                 parentAlertOpen, parentAlertMessage, parentAlertSeverity, setFormSubmitOpen,
                                 setFormSubmitMessage, setParentKeepOpen, currentState, fedIdNumber, updateUserEditing}) {
  const [totalReceipts, setTotalReceipts] = useState(0)
  const [totalDisbursements, setTotalDisbursements] = useState(0)
  const [totalAssets, setTotalAssets] = useState(0)
  const [totalGoodsSold, setTotalGoodsSold] = useState(0)
  const [totalBedNBreakfast, setTotalBedNBreakfast] = useState(0)
  const [hideContent, setHideContent] = useState(true)
  const [hideDisbursements, setHideDisbursements] = useState(true)
  const [notes, setNotes] = useState('');
  const [notesError, setNotesError] = useState(false);
  const [notesErrorDescrption, setNotesErrorDescrption] = useState('');
  const [name, setName] = useState('');
  const [nameError, setNameError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [dispursementsBedNBreakfast, setDisbursementsBedNBreakfast] = useState(0);
  const [disbursementsGoodsSold, setDisbursementsGoodsSold] = useState(0);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertSeverity, setAlertSeverity] = useState('success');
  const [documentLoading, setDocumentLoading] = useState(false);
  let { id } = useParams();

  const calcTotalReceipts = useCallback(() => {
    let total = 0
    totalReceiptsQuestions.forEach((question) => {
      let thisQuestion = questions?.receipts.filter((q) => q?.question_id === question)
      thisQuestion.forEach((q) => {
        if (isNaN(q?.answer)) {
          total += q?.answer_2 || 0
        } else {
          total += q?.answer || 0
        }
      })
    })
    setTotalReceipts(total)
  }, [questions])

  const calcTotalDisbursements = useCallback(() => {
    let total = 0
    totalDisbursementsQuestions.forEach((question) => {
      let thisQuestion = questions?.disbursements.filter((q) => q?.question_id === question)[0]
      if (isNaN(thisQuestion?.answer)) {
        total += thisQuestion?.answer_2 || 0
      } else {
        total += thisQuestion?.answer || 0
      }
    })
    setTotalDisbursements(total)
  }, [questions])

  const calcBnBDisbursements = useCallback(() => {
    let total = 0
    let bedNBreakfast = questions?.bed_and_breakfast.filter((q) => q?.question_id === 'd94ef3e0e30a45c6a15134b5521a6387')[0]
    total += bedNBreakfast?.answer || 0
    setDisbursementsBedNBreakfast(total)
  }, [questions])

  const calcGoodsSoldDisbursements = useCallback(() => {
    let total = 0
    let goodsSold = questions?.goods_sold.filter((q) => q?.question_id === 'ecea95f923634856af8800ac1c026bca')[0]
    total += goodsSold?.answer || 0
    setDisbursementsGoodsSold(total)
  }, [questions])

  const calcTotalAssets = useCallback(() => {
    let total = 0
    totalAssetsQuestions.forEach((question) => {
      let thisQuestion = questions?.assets.filter((q) => q?.question_id === question)[0]
      if (isNaN(thisQuestion?.answer)) {
        total += thisQuestion?.answer_2 || 0
      } else {
        total += thisQuestion?.answer || 0
      }
    })
    setTotalAssets(total)
  }, [questions])

  const calcTotalGoodsSold = useCallback(() => {
    let total = 0
    let goodsSold = questions?.goods_sold.filter((q) => q?.question_id === '4c50bf2c5451427e8bc487dafaa70060')[0]
    total += goodsSold?.answer || 0
    setTotalGoodsSold(total)
  }, [questions])

  const calcTotalBedNBreakfast = useCallback(() => {
    let total = 0
    let bedNBreakfast = questions?.bed_and_breakfast.filter((q) => q?.question_id === '1885ea03cb094fe8847ba269a8b07c61')[0]
    total += bedNBreakfast?.answer || 0
    setTotalBedNBreakfast(total)
  }, [questions])

  useEffect(() => {
    calcTotalReceipts()
    calcTotalDisbursements()
    calcTotalAssets()
    calcTotalGoodsSold()
    calcTotalBedNBreakfast()
    calcBnBDisbursements()
    calcGoodsSoldDisbursements()
    updateUserEditing(false)
  }, [questions, calcTotalReceipts, calcTotalDisbursements, calcTotalAssets, calcTotalGoodsSold,
      calcTotalBedNBreakfast, calcBnBDisbursements, calcGoodsSoldDisbursements, updateUserEditing])

  let errorTotal = ((Math.round(totalAssets * 100) / 100) !== (Math.round((
    previousTotal + totalReceipts + totalGoodsSold + totalBedNBreakfast -
    totalDisbursements - dispursementsBedNBreakfast - disbursementsGoodsSold) * 100) / 100))
  
  let errorAmount = (Math.round(totalAssets * 100) / 100) - (Math.round((
    previousTotal + totalReceipts + totalGoodsSold + totalBedNBreakfast -
    totalDisbursements - dispursementsBedNBreakfast - disbursementsGoodsSold) * 100) / 100)

    const handleScrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    };

  const { mutate: approveForm } = useApiSend(
    irsApprove,
    (data) => {
      handleScrollToTop()
      setFormSubmitMessage("Thank you, your submission of your IRS-LC Form was successful.")
      setParentKeepOpen(true)
      setFormSubmitOpen(true)
      parentAlertMessage("Success! The form has been submitted.")
      parentAlertSeverity("success")
      parentAlertOpen(true)
      resetState()
      refetchParent()
      setIsLoading(false)
    },
    (error) => {
      setIsLoading(false)
      console.log(error)
      setAlertMessage("Error approving IRS Form. If the problem persists, please contact support.")
      setAlertSeverity("error")
      setShowAlert(true)
    },
  )

  const { mutate: rejectForm } = useApiSend(
    irsReject,
    (data) => {
      handleScrollToTop()
      setFormSubmitMessage("The form has been rejected.")
      setParentKeepOpen(true)
      setFormSubmitOpen(true)
      parentAlertMessage("The form has been rejected.")
      parentAlertSeverity("success")
      parentAlertOpen(true)
      resetState()
      refetchParent()
      setIsLoading(false)

    },
    (error) => {
      setIsLoading(false)
      console.log(error)
      setAlertMessage("Error rejecting IRS Form. If the problem persists, please contact support.")
      setAlertSeverity("error")
      setShowAlert(true)
    },
  )

  const handleApprove = () => {
    setIsLoading(true)
    approveForm({
      id,
      notes,
      signature_name: name,
    })
  }

  const handleReject = () => {
    setIsLoading(true)
    rejectForm({
      id,
      notes,
    })
  }


  const resetState = () => {
    setNotes('')
    setName('')
    setNotesError(false)
    setNotesErrorDescrption('')
    setNameError(false)
  }

  const onNotesChange = (event) => {
    setNotes(event.target.value)
    if (event.target.value.length > 4000) {
      setNotesError(true)
      setNotesErrorDescrption('Notes must be less than 4000 characters')
    } else {
      setNotesError(false)
      setNotesErrorDescrption('')
    }
  }

  const handleNameChange = (event) => {
    setName(event.target.value)
    if (event.target.value === `${localStorage.getItem('first_name')} ${localStorage.getItem('last_name')}`) {
      setNameError(false)
    }
  }

  const onReject = (e) => {
    e.preventDefault()
    let valid = true
    if (notes.length === 0) {
      setNotesError(true)
      setNotesErrorDescrption('Notes are Required')
      valid = false
    } else if (notes.length > 4000) {
      setNotesError(true)
      setNotesErrorDescrption('Notes must be less than 4000 characters')
      valid = false
    }
    if (valid) {
      handleReject()
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    let valid = true
    if (notes.length > 4000) {
      setNotesError(true)
      setNotesErrorDescrption('Notes must be less than 4000 characters')
      valid = false
    }

    if (errorTotal) {
      valid = false
    }

    if (name !== `${localStorage.getItem('first_name')} ${localStorage.getItem('last_name')}`) {
      setNameError(true)
      valid = false
    } else {
      setNameError(false)
    }
    if (valid) {
      handleApprove()
    }
  }

  const handleGetDocument = async() => {
    setDocumentLoading(true)
    try {
      const response = await documentClient.get(`/irs/${id}/document`);
      const blob = new Blob([response.data], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);
      window.open(url);
      window.focus();
    } catch (error) {
      console.log(error)
      setAlertMessage("Error retrieving document. If the problem persists, please contact support.")
      setAlertSeverity("error")
      setShowAlert(true)
    }
    setDocumentLoading(false)
  }

  return (
    <Grid container>
      <Grid item xs={12}textAlign={'center'}>
        <Typography variant="h4">Reconciliation</Typography>
      </Grid>
      <Grid item xs={12} sm={2}/>
      <Grid item xs={12} sm={8} textAlign={'center'}>
        <Typography variant="body2">Fed ID Number: {fedIdNumber}</Typography>
      </Grid>
      <Grid item xs={12} sm={2} textAlign={'right'}>
        <LoadingButton
          variant="contained"
          color="primary"
          loading={documentLoading}
          disabled={documentLoading}
          size='small'
          onClick={handleGetDocument}>
          View Document
        </LoadingButton>
      </Grid>
        <LineItem
          label={'Chapter Assets, beginning of fiscal year'}
          value={formatCurrency(previousTotal)}
        />
        <LineItem
          icon={<IconButton size='small' onClick={() => setHideContent(!hideContent)}>
                  {hideContent ? <ExpandMoreIcon fontSize='small'/> : <ExpandLessIcon fontSize='small'/>}
                </IconButton>}
          label={'Total Receipts'}
          value={`+ ${formatCurrency(
            totalReceipts + totalGoodsSold + totalBedNBreakfast
          )}`}
        />
        {!hideContent && (
          <Grid container item>
            <LineItem
              label={<Link onClick={() => handleRedirectTab('receipts')}>{'Receipts'}</Link>}
              value={formatCurrency(totalReceipts)}
              subTotal={true}
            />
            <LineItem
              label={<Link onClick={() => handleRedirectTab('goods_sold')}>{'Receipts (Goods Sold)'}</Link>}
              value={`+ ${formatCurrency(totalGoodsSold)}`}
              subTotal={true}
            />
            <LineItem
              label={<Link onClick={() => handleRedirectTab('bed_and_breakfast')}>{'Receipts (Bed and Breakfast)'}</Link>}
              value={`+ ${formatCurrency(totalBedNBreakfast)}`}
              subTotal={true}
            />
          </Grid>)}
        
        <LineItem
          icon={<IconButton size='small' onClick={() => setHideDisbursements(!hideDisbursements)}>
                  {hideDisbursements ? <ExpandMoreIcon fontSize='small'/> : <ExpandLessIcon fontSize='small'/>}
                </IconButton>}
          label={'Total Disbursements'}
          value={`- ${formatCurrency(
            totalDisbursements + dispursementsBedNBreakfast + disbursementsGoodsSold
          )}`}
        />
        {!hideDisbursements && (
          <Grid container item>
            <LineItem
              label={<Link onClick={() => handleRedirectTab('disbursements')}>{'Disbursements'}</Link>}
              value={formatCurrency(totalDisbursements)}
              subTotal={true}
            />
            <LineItem
              label={<Link onClick={() => handleRedirectTab('goods_sold')}>{'Disbursements (Goods Sold)'}</Link>}
              value={`+ ${formatCurrency(disbursementsGoodsSold)}`}
              subTotal={true}
            />
            <LineItem
              label={<Link onClick={() => handleRedirectTab('bed_and_breakfast')}>{'Disbursements (Bed and Breakfast)'}</Link>}
              value={`+ ${formatCurrency(dispursementsBedNBreakfast)}`}
              subTotal={true}
            />
          </Grid>)}
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <LineItem
          label={'Chapter Assets, end of fiscal year (calculated)'}
          value={formatCurrency(
            previousTotal +
            totalReceipts +
            totalGoodsSold +
            totalBedNBreakfast -
            totalDisbursements -
            dispursementsBedNBreakfast -
            disbursementsGoodsSold
          )}
        />
        <Grid item xs={12} padding>
          <Divider />
        </Grid>
        <LineItem
          label={'Chapter Assets, end of fiscal year (as input on Assets tab)'}
          error={errorTotal}
          value={formatCurrency(totalAssets)}
        />
        {errorTotal && (
          <LineItem
            label={'Difference'}
            error={true}
            errorDescrption={"Calculated Chapter Assets must equal input Chapter Assets"}
            value={formatCurrency(errorAmount)}
          />)}
        <Grid item xs={12} padding>
          <Divider />
        </Grid>
        {!errorTotal && (
          <Grid item container>
            <Grid item xs={12}>
              <Typography variant="body2" paddingBottom={2}>
                Add any additional notes.  {![irsStates.LCTreas].includes(currentState) && ' If the IRC-LC is being rejected, please provide an explanation in the box below.'}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="detailed-notes"
                label="Detailed Notes"
                multiline
                disabled={!editable}
                error={notesError}
                helperText={notesErrorDescrption}
                value={notes}
                onChange={onNotesChange}
                rows={3}
                fullWidth/>
            </Grid>
            {formNotes.length > 0 && (
              <Grid item xs={12} paddingTop={2}>
                <Typography variant="body1" fontWeight={'bold'}>
                  Previous Notes:
                </Typography>
              </Grid>
            )}
            {formNotes.map((note, index) => (
              <Grid item container key={index} xs={12}>
                <Grid item xs={12} sm={12} md={5} lg={4}>
                  <Typography variant="body1">
                    {note.created_by} on {dayjs(note.created_at).format('MM/DD/YYYY hh:mm A')}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={7} lg={8}>
                  <Typography variant="body1">
                    {note.note}
                  </Typography>
                </Grid>
              </Grid>
            ))}
            <Grid item xs={12} paddingTop={2}>
              <Typography variant="body1" fontWeight={'bold'}>
                Electronic Signature:
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2">
                The information contained in this report prepared and/or verified by me is correct and complete to the best of my knowledge.
              </Typography>
            </Grid>
            <Grid item xs={12} paddingBottom={2}>
              <Typography variant="body2">
              Please type your name in the box. The name you enter must match the name shown below the box, including punctuation and spacing.
              </Typography>
            </Grid>
          
            <Grid item xs={12} sm={8} md={5} lg={4}>
              <TextField
                id="name"
                label="Name"
                size="small"
                required
                fullWidth
                value={name}
                error={nameError}
                helperText={nameError ? 'Name must match your name below' : ''}
                onChange={handleNameChange}
                />
            </Grid>
            <Grid item lg={8} md={7} sm={4} xs={12}/>
            <Grid item xs={12} paddingTop={1}>
              <Typography variant="body2">
                {localStorage.getItem('first_name')} {localStorage.getItem('last_name')}
              </Typography>
            </Grid>
            <Grid item xs={12} textAlign={'right'}>
              <ButtonGroup>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={!editable || isLoading}
                  sx={{fontWeight: 'bold'}}
                  onClick={handleSubmit}>
                  Submit as Approved
                </Button>
                {currentState !== irsStates.LCTreas && (
                  <Button
                    variant="contained"
                    color="warning"
                    disabled={!editable || isLoading}
                    sx={{fontWeight: 'bold'}}
                    onClick={onReject}>
                    Submit as Rejection
                  </Button>)}
              </ButtonGroup>
            </Grid>
          </Grid>
        )}
        <Grid item xs={12}>
        {showAlert && (
            <AlertItem
              severity={alertSeverity}
              description={alertMessage}
              onClose={() => setShowAlert(false)} />
          )}
        </Grid>
    </Grid>
  );
}