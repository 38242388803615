import { request } from "../utils/httpClient"


export const getDonationFunds = () => request({
    url: "/donation/funds",
    method: "GET",
})

export const getDonationProjects = () => request({
    url: "/donation/projects",
    method: "GET",
})

export const getBehalfOfTypes = () => request({
    url: "/donation/behalf_of_types",
    method: "GET",
})

export const createDonation = (data) => request({
    url: "/donation",
    method: "POST",
    data: data,
})

export const createDonationLines = (data) => request({
    url: "/donation/lines",
    method: "POST",
    data: data,
})

export const getInProgressDonation = (data) => request({
    url: `/donation/inprogress/${data.id}`,
    method: "GET",
})

export const removeDonationLine = (data) => request({
    url: `/donation/lines/${data.id}`,
    method: "DELETE",
})

export const removeDonationNotification = (data) => request({
    url: `/donation/behalf_of/notification/${data.id}`,
    method: "DELETE",
})
