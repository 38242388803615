import React, {useEffect, useState} from 'react';
import Button from '@mui/material/Button';
import DateTimePicker from '../../Components/DateTimePicker';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import Grid from '@mui/material/Grid';
import dayjs from 'dayjs';
import { Tooltip } from '@mui/material';


const actionMap = {
    "reinstate": "Reinstatement Date",
    'lost-contact': 'Lost Contact Date',
    'transfer': 'Transfer Date',
    'passed': 'Passed Date'
}

const actionToolTip = {
    "reinstate": "The date the member was reinstated",
    'lost-contact': 'The date the member was last contacted',
    'transfer': 'The date the member was transferred',
    'passed': 'The date the member passed away'
}

export default function DateDialog({ onClose, dateValue, open, action, dateRestricted=true }) {
  const [value, setValue] = useState(dateValue);
  const [actionType, setActionType] = useState(action);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (open) {
      setValue(dateValue);
      setActionType(actionMap[action]);
    }
  }, [dateValue, open, action]);

  const handleCancel = () => {
    onClose();
  };

  const onErrorUpdate = (newError) => {
    setError(newError);
  }

  const handleSetDate = (newValue) => {
    setValue(newValue);
    if (newValue !== null) {
      setError(false);
      setErrorMessage('');
    }
  }

  const handleOk = () => {
    if (error) {
      return;
    }
    if (value === null) {
      setError(true);
      setErrorMessage('Date must be selected');
      return;
    }
    onClose(value)
  };

  return (
    <Dialog
      sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
      maxWidth="xs"
      open={open}
      keepMounted
    >
      <DialogTitle>{actionType}</DialogTitle>
      <DialogContent dividers>
        <Grid container>
            <Grid item xs={12} align={"center"}>
              <Tooltip title={actionToolTip[action]} placement='top'>
                <DateTimePicker
                  date={value}
                  label={actionType}
                  minDate={dateRestricted ? dayjs('2024-03-01') : null}
                  maxDate={dateRestricted ? dayjs('2025-02-28') : null}
                  autoFocus={true}
                  extraError={error}
                  handleErrorUpdate={onErrorUpdate}
                  shown={open}
                  extraErrorMessage={errorMessage}
                  handleDateChange={handleSetDate}/>
                </Tooltip>
            </Grid>
        </Grid>
        
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleCancel}>
          Cancel
        </Button>
        <Button onClick={handleOk}>Ok</Button>
      </DialogActions>
    </Dialog>
  );
}
