import React, { useEffect, useState } from "react"
import { Grid, TextField, InputAdornment, TableContainer, Paper, Table,
         TableHead, TableRow, TableBody, TableCell, 
         Typography, Button,
         Skeleton} from '@mui/material'
import Modal from '@mui/material/Modal';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import { searchUsers } from "../endpoints/users";
import { useApiGet } from "../utils/httpClient";
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { TableVirtuoso } from 'react-virtuoso';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 435,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
};

const columns = [
    {
      width: 50,
      label: 'Name',
      dataKey: 'full_name',
    },
    {
      width: 50,
      label: 'iMIS ID',
      dataKey: 'imis_id',
    },
    {
      width: 50,
      label: null,
      dataKey: 'info',
      skip: true
    },
  ];
  
  const VirtuosoTableComponents = {
    Scroller: React.forwardRef((props, ref) => (
      <TableContainer component={Paper} {...props} ref={ref} />
    )),
    Table: (props) => (
      <Table size="small" {...props} sx={{ borderCollapse: 'separate', tableLayout: 'fixed' }} />
    ),
    TableHead: React.forwardRef((props, ref) => <TableHead {...props} ref={ref} />),
    TableRow,
    TableBody: React.forwardRef((props, ref) => <TableBody {...props} ref={ref} />),
  };
  
  function fixedHeaderContent() {
    return (
      <TableRow>
        {columns.map((column) => (
          <TableCell
            key={column.dataKey}
            variant="head"
            align={column.dataKey === 'action' ? 'left' : 'right'}
            style={{ width: column.width }}
            sx={{
              backgroundColor: 'background.paper',
            }}
          >
            {column.label}
          </TableCell>
        ))}
      </TableRow>
    );
  }


export default function UserSelectModal({modalOpen, setUser, setModalOpen}) {
    const [searchText, setSearchText] = useState('');
    const [delaySearch, setDelaySearch] = useState('')

    const {data, isFetching} = useApiGet(
        ['user-search', searchText],
        searchUsers,
        {
          enabled: searchText.length > 2,
          retry: 1,
        },
        {
            searchText
        }
    )
    
    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setSearchText(delaySearch)
        }, 500)
        return () => clearTimeout(timeoutId)
    }, [delaySearch])

    const handleUserSelected = (userId) => {
      setUser(userId)
      setDelaySearch('')
      setSearchText('')
    }

    const rowContent = (_index, row) => {
        return (
            <React.Fragment>
              {columns.filter((item) => !item.skip).map((column) => (
                <TableCell
                  key={column.dataKey}
                  align={'right'}
                  onClick={() => handleUserSelected(row.id)}
                >
                  {row[column.dataKey]}
                </TableCell>
              ))}
              <TableCell
                  key={'select'}
                  align={'right'}
                  onClick={() => handleUserSelected(row.id)}
                >
                    <IconButton onClick={() => handleUserSelected(row.id)}>
                        <TaskAltIcon color="primary"/>
                    </IconButton>
                </TableCell>
            </React.Fragment>
          );
    }

    const handleCloseModal = () => {
      setDelaySearch('')
      setSearchText('')
      setModalOpen(false)
    }

    const handleSetToMe = () => {
      setUser(localStorage.getItem('user_id'))
      setDelaySearch('')
      setSearchText('')
    }

  return (
    <Modal
        open={modalOpen}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        >
        <Box sx={style}>
            <Grid container>
                <Grid item xs={12} align={"right"}>
                    <IconButton onClick={handleCloseModal}>
                        <CloseIcon fontSize="small"/>
                    </IconButton>
                </Grid>
                <Grid item xs={12} paddingTop={2} paddingRight={2} textAlign={'right'}>
                  <Button
                    color="primary"
                    size='small'
                    onClick={handleSetToMe}>
                    Set to Me
                  </Button>
                </Grid>
            </Grid>
            
            <Grid container>
                <Grid item xs={12} padding={2}>
                    <TextField
                        label="Search"
                        id="search-field"
                        variant='standard'
                        size='small'
                        fullWidth={true}
                        onChange={(event) => setDelaySearch(event.target.value)}
                        InputProps={{
                            startAdornment: <InputAdornment position="start"><SearchIcon/></InputAdornment>,
                        }}
                        />
                </Grid>
                <Grid item xs={12} padding={2}>
                    {(isFetching) ? (
                      <Skeleton variant="rectangular" width={400} height={400} />
                    ) : (
                    <Box sx={{ height: 400, width: 400 }}>
                        {data && data.length > 0 ? (
                          <TableVirtuoso
                              data={data}
                              components={VirtuosoTableComponents}
                              fixedHeaderContent={fixedHeaderContent}
                              itemContent={rowContent}
                          />
                        ) : (
                          <Typography variant="h6" component="h2" color={"primary"} paddingBottom={2}>
                              {searchText.length > 2 ? 'No results found' : 'Please enter at least 3 characters'}
                          </Typography>
                        )}
                    </Box>)}
                </Grid>
            </Grid>
            
        </Box>
    </Modal>
  );
}