import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { useSearchParams } from "react-router-dom";
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Skeleton from '@mui/material/Skeleton';
import Paper from '@mui/material/Paper';
import { visuallyHidden } from '@mui/utils';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import { FormControl } from '@mui/material';
import { useApiGet } from '../../utils/httpClient';
import { getRosterForms } from '../../endpoints/annualReports';
import FormStateSelector from '../../Components/FormStateSelector';
import StateProvinceSelector from '../../Components/StateProvinceSelector';
import { useCallback } from 'react';
import PageHeader from '../../Components/PageHeader';


const headCells = [
  {
    id: 'chapter_name',
    firstCell: true,
    label: 'Chapter',
    width: '10%'
  },
  {
    id: 'chapter_state',
    numeric: false,
    disablePadding: false,
    label: 'State / Province / District',
    width: '30%'
  },
  {
    id: 'status',
    numeric: false,
    disablePadding: false,
    label: 'Step',
    width: '30%'
  },
  {
    id: 'assigned_to',
    numeric: false,
    disablePadding: false,
    label: 'Assigned To',
    width: '30%'
  },
];

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.primary.veryLight,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));


function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } =
    props;
  const createSortHandler =
    (property) => (event) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <StyledTableCell
            color="white"
            key={headCell.id}
            align={headCell.firstCell ? 'left' : 'right'}
            variant='head'
            padding={'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{
              fontWeight: 'bold',
              fontSize: 16,
              "&hover": {
                cursor: 'pointer',
                color: 'primary'
              },
              width: headCell.width
            }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

function FakeLoadTable ({tableRowCount}) {
  return (
    <TableBody>
      {Array.from(new Array(tableRowCount)).map((_, index) => (
        <TableRow key={index}>
          <TableCell align="left" padding='normal'>
            <Skeleton variant="text" width="100%"/>
          </TableCell>
          <TableCell align="right" padding='normal'>
            <Skeleton variant="text" width="100%" />
          </TableCell>
          <TableCell align="right" padding='normal'>
            <Skeleton variant="text" width="100%" />
          </TableCell>
          <TableCell align="right" padding='normal'>
            <Skeleton variant="text" width="100%" />
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  )

}

export default function EnhancedTable() {
  let [searchParams, setSearchParams] = useSearchParams()

  const updateSearchParams = useCallback((key, value) => {
    if (!value || value === '') {
      searchParams.delete(key)
    } else {
      searchParams.set(key, value)
    }
    setSearchParams(searchParams)
  }, [searchParams, setSearchParams])

  const [order, setOrder] = useState('');
  const [orderBy, setOrderBy] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [formStatus, setFormStatus] = useState(searchParams.get('formStatus') ? searchParams.get('formStatus') : 'all')
  const [stateProvince, setStateProvince] = useState(searchParams.get('stateProvince', ''))
  const [disbanded, setDisbanded] = useState(searchParams.get('disbanded') ? searchParams.get('disbanded') : 'all')
  const [search, setSearch] = useState(searchParams.get('search', ''))
  const [delaySearch, setDelaySearch] = useState(searchParams.get('search', ''))
  
  // need up update search params when search changes
  useEffect(() => {
    updateSearchParams('search', search)
  }, [search, updateSearchParams])
  // need up update params when disbanded changes
  useEffect(() => {
    updateSearchParams('disbanded', disbanded)
  }, [disbanded, updateSearchParams])
  // need up update params when formStatus changes
  useEffect(() => {
    updateSearchParams('formStatus', formStatus)
  }, [formStatus, updateSearchParams])
  // need up update params when stateProvince changes
  useEffect(() => {
    updateSearchParams('stateProvince', stateProvince)
  }, [stateProvince, updateSearchParams])

  const { 
    data, isFetching
  } = useApiGet(
    ['roster_forms', rowsPerPage, page, stateProvince,
      formStatus, disbanded, orderBy, order, search],
    getRosterForms,
    {
      enabled: true,
      retry: 1,
    },
    {
      rowsPerPage, page, stateProvince,
      formStatus, disbanded, orderBy, order, search
    }
  )

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setSearch(delaySearch)
    }, 500)
    return () => clearTimeout(timeoutId)
  }, [delaySearch])

  const handleRequestSort = (
    event,
    property,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleClick = (event, id) => {
    window.location.href = `/roster/${id}`
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChange = (event) => {
    if (!event) {
      setStateProvince('')
    } else {
      setStateProvince(event.value)
    }
  }

  const handleStateChange = (stateId) => {
    if (stateId !== 0) {
      setFormStatus(stateId)
    }
  }

  return (
    <Grid container paddingLeft={2} paddingRight={2}>
      <Grid item xs={12}>
        <PageHeader title="Roster Dues Forms" />
      </Grid>
      <Grid container>
        <Grid item xs={4} paddingRight={2} paddingBottom={3}>
          <TextField
              label="Search"
              id="search-field"
              size='small'
              value={delaySearch}
              fullWidth={true}
              onChange={(event) => setDelaySearch(event.target.value)}
              InputProps={{
                startAdornment: <InputAdornment position="start"><SearchIcon/></InputAdornment>,
              }}
            />
        </Grid>
        <Grid item xs={3} paddingRight={2}>
          <StateProvinceSelector
            value={stateProvince}
            handleChange={handleChange}/>
        </Grid>
        <Grid item xs={3}  paddingRight={2}>
          <FormStateSelector
            formState={formStatus}
            handleStateChange={handleStateChange}
          />
        </Grid>
        <Grid item xs={2}>
        <FormControl fullWidth={true} variant="outlined" size='small'>
        <InputLabel id="disbanded">Disbanded</InputLabel>
        <Select
          labelId="select-label"
          id="select"
          fullWidth={true}
          value={disbanded}
          input={<OutlinedInput label="Disbanded" />}
          label="Disbanded"
          onChange={(event) => setDisbanded(event.target.value)}
        >
          <MenuItem key={'all'} value={'all'}>{'All'}</MenuItem>
          <MenuItem key={'true'} value={'true'}>{"True"}</MenuItem>
          <MenuItem key={'false'} value={'false'}>{"False"}</MenuItem>
        </Select>
          </FormControl>
        </Grid>
      </Grid>
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={'medium'}
          >
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            {isFetching && (<FakeLoadTable tableRowCount={rowsPerPage} />)}
            {!isFetching && <TableBody>
              {data?.map((row, index) => {
                return (
                  <StyledTableRow
                    hover
                    onClick={(event) => handleClick(event, row.id)}
                    role="checkbox"
                    tabIndex={-1}
                    key={row.id}
                    sx={{ cursor: 'pointer' }}
                  >
                    <TableCell align="left" padding='normal' sx={{ width: '10%' }}>
                      {row.chapter_name}
                    </TableCell>
                    <TableCell align="right" padding='normal' sx={{ width: '30%' }}>
                      {row.chapter_state}
                    </TableCell>
                    <TableCell align="right" padding='normal' sx={{ width: '30%' }}>
                      {row.state}
                    </TableCell>
                    <TableCell align="right" padding='normal'sx={{ width: '30%' }}>
                      {row.assigned_to}
                    </TableCell>
                  </StyledTableRow>
                );
              })}
            </TableBody>}
          </Table>
        </TableContainer>
          <TablePagination
            rowsPerPageOptions={[25, 50, 100]}
            component="div"
            count={data?.length < rowsPerPage ? data.length : -1}
            rowsPerPage={data?.length < rowsPerPage ? data.length : rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            slotProps={{
              'actions': {
                "nextButton": { 'disabled': data?.length < rowsPerPage },
              }
            }}
          />
      </Paper>
    </Box>
    </Grid>
  );
}