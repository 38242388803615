import React, {useState, useMemo} from "react"
import { Grid, Button, FormControl, Select, MenuItem, InputLabel, Tooltip } from '@mui/material'
import Modal from '@mui/material/Modal';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';
import dayjs from 'dayjs';
import MemberSearchModal from "../../Components/MemberSearchModal";
import { getMember } from "../../endpoints/annualReports";
import { useApiGet, useApiSend } from "../../utils/httpClient";
import { createMember, transferMember } from "../../endpoints/annualReports";
import DateTimePicker from "../../Components/DateTimePicker";
import ValidatedTextField from "../../Components/ValidatedTextField";
import StateProvinceSelector from "../../Components/StateProvinceSelector";
import { nameValidator, emailValidator, phoneNumberValidator } from "../../utils/validators";
import AlertItem from "../../Components/AlertItem";
import { LoadingButton } from "@mui/lab";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 750,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    paddingBottom: 3
};

function CreateTextField({label, value, readOnly, onChange, validator=null,
                          error, valueRequired=true}) {
    return (
        <Grid item xs={6} paddingLeft={2} paddingRight={2} paddingBottom={2}>
            <ValidatedTextField
                validator={validator}
                label={label}
                disabled={readOnly}
                startAdornment={null}
                valueRequired={valueRequired}
                errorOverride={error ? 'This field is required' : null}
                value={value}
                onChange={(isValid, value) => onChange(isValid, value)}/>
        </Grid>
    )
}

export default function CreateMemberModal({rosterId, parentRefetch, disabled=false}) {
    const [modalOpen, setModalOpen] = useState(false);
    const [userModalOpen, setUserModalOpen] = useState(false);
    const [userSelected, setUserSelected] = useState(null);
    const [date, setDate] = useState(null);
    const [error, setError] = useState(null);
    const [firstName, setFirstName] = useState('');
    const [firstNameError, setFirstNameError] = useState(false);
    const [lastName, setLastName] = useState('');
    const [lastNameError, setLastNameError] = useState(false);
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState(false);
    const [phone, setPhone] = useState('');
    const [address1, setAddress1] = useState('');
    const [address1Error, setAddress1Error] = useState(false);
    const [address2, setAddress2] = useState('');
    const [address3, setAddress3] = useState('');
    const [city, setCity] = useState('');
    const [cityError, setCityError] = useState(false);
    const [state, setState] = useState({});
    const [stateError, setStateError] = useState(false);
    const [zip, setZip] = useState('');
    const [zipError, setZipError] = useState(false);
    const [phoneError, setPhoneError] = useState(false);
    const [transferIn, setTransferIn] = useState(1);
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertSeverity, setAlertSeverity] = useState('success');
    const [isPending, setIsPending] = useState(false);

    const { mutate: createMemberCall } = useApiSend(
        createMember,
        (data) => {
            setAlertMessage('Member Initiated Successfully')
            setAlertSeverity('success')
            setShowAlert(true)
            setTimeout(() => {
                parentRefetch()
                handleModalClose()
                setIsPending(false);
            }, 1000)
        },
        (error) => {
            console.log(error)
            setAlertMessage('Error Initiating Member. Please try again or contact support.')
            setAlertSeverity('error')
            setShowAlert(true)
            setIsPending(false)
        }
    )

    const { mutate: transferMemberCall } = useApiSend(
        transferMember,
        (data) => {
            setAlertMessage('Member Transferred Successfully')
            setAlertSeverity('success')
            setShowAlert(true)
            setTimeout(() => {
                parentRefetch()
                handleModalClose()
                setIsPending(false);
            }, 1000)
        },
        (error) => {
            setAlertMessage('Error Transferring Member. Please try again or contact support.')
            setAlertSeverity('error')
            setShowAlert(true)
            setIsPending(false)
        }
    )

    const handleDateChange = (date) => {
        setDate(date)
        if (date) {
            setError(null)
        }
    }

    const handleSave = (e) => {
        e.preventDefault();
        setIsPending(true);
        let valid = true;
        if (transferIn === 1) {
            if (!date){
                setError('required');
                valid = false;
            }
            if (valid) {
                transferMemberCall({
                    memberId: userSelected,
                    roster_form_id: rosterId,
                    transfer_date: date.format('YYYY-MM-DD')
                })
            } else {
                setIsPending(false);
            }
        } else {
            // create
            if (firstName === '') {
                setFirstNameError(true);
                valid = false;
            }
            if (lastName === '') {
                setLastNameError(true);
                valid = false;
            }
            if (email === '') {
                setEmailError(true);
                valid = false;
            }
            if (address1 === '') {
                setAddress1Error(true);
                valid = false;
            }
            if (city === '') {
                setCityError(true);
                valid = false;
            }
            if (state === null) {
                setStateError(true);
                valid = false;
            }
            if (zip === '') {
                setZipError(true);
                valid = false;
            }

            if (!date) {
                setError('required');
                valid = false;
            }

            if (valid) {
                // need to modify phone if its not null and not empty string
                let tempPhone = phone;
                if (phone !== '' && phone !== null) {
                    tempPhone = tempPhone.replace(/\D/g, '');
                }
                createMemberCall({
                    first_name: firstName,
                    last_name: lastName,
                    email: email,
                    phone: tempPhone,
                    address1: address1,
                    address2: address2,
                    address3: address3,
                    city: city,
                    state: state,
                    zip_code: zip,
                    initiation_date: date.format('YYYY-MM-DD'),
                    roster_form_id: rosterId
                })
            } else {
                setIsPending(false);
            }
        }
    }

    const setValue = (field, isValid, value) => {
        switch (field) {
            case 'firstName':
                setFirstName(value);
                if (value !== '' || isValid === false) {
                    setFirstNameError(false);
                }
                break;  
            case 'lastName':
                setLastName(value);
                if (value !== '' || isValid === false) {
                    setLastNameError(false);
                }
                break;
            case 'email':
                setEmail(value);
                if (value !== '' || isValid === false) {
                    setEmailError(false);
                }
                break;
            case 'phone':
                setPhone(value);
                if (isValid === false && value !== '') {
                    setPhoneError(true);
                } else(
                    setPhoneError(false)
                )
                break;
            case 'address1':
                setAddress1(value);
                if (value !== '' || isValid === false) {
                    setAddress1Error(false);
                }
                break;
            case 'address2':
                setAddress2(value);
                break;
            case 'address3':
                setAddress3(value);
                break;
            case 'city':
                setCity(value);
                if (value !== '' || isValid === false) {
                    setCityError(false);
                }
                break;
            case 'state':
                setState(value ? value.value : null);
                if (value !== null || isValid === false) {
                    setStateError(false);
                }
                break;
            case 'zip':
                setZip(value);
                if (value !== '' || isValid === false) {
                    setZipError(false);
                }
                break;
            default:
                break;
        }
    }

    const errorMessage = useMemo(() => {
        switch (error) {
            case 'maxDate': {
                return 'Please select a date before March 1, 2025';
            }
            case 'minDate': {
                return 'Please select a date after March 1, 2024';
            }

            case 'invalidDate': {
                return 'Your date is not valid';
            }

            case 'required': {
                return 'This field is required';
            }

            default: {
                return '';
            }
        }
    }, [error]);

    const { data } = useApiGet(
        ['member-details', userSelected],
        getMember,
        {
          enabled: userSelected ? true : false,
          retry: 1,
        },
        {
            userId: userSelected
        }
    )

    const handleOpenUserModal = () => {
        setUserModalOpen(true);
    }

    const handleSetUser = (user) => {
        setUserSelected(user.id);
        setUserModalOpen(false);
    }

    const handleModalClose = () => {
        setModalOpen(false);
        setUserSelected(null);
        setDate(null);
        setError(null);
    }

  return (
    <Grid container>
        <Grid item xs={12}>
            <Tooltip title="Add a missing member to the roster via Transfer or New Member." placement="top-end">
                <Button
                    color='primary'
                    size='small'
                    variant='contained'
                    disabled={disabled}
                    onClick={() => setModalOpen(true)}
                    sx={{ fontWeight: 'bold' }}
                >
                    Add Missing Member
                </Button>
            </Tooltip>
        </Grid>
        <Modal
            open={modalOpen}
            onClose={handleModalClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            >
            <Box sx={style}>
                <Grid container>
                    
                    <Grid item xs={12} align={"right"}>
                        <IconButton onClick={handleModalClose}>
                            <CloseIcon fontSize="small"/>
                        </IconButton>
                    </Grid>
                    <Grid item xs={6} padding={2}>
                        <FormControl fullWidth size='small'>
                            <InputLabel id="transfer-label">Initiate Member or Transfer In</InputLabel>
                            <Select
                                id="transfer-select"
                                value={transferIn}
                                label="Initiate Member or Transfer In"
                                onChange={(e) => setTransferIn(e.target.value)}
                                >
                                <MenuItem value={1}>Transfer In</MenuItem>
                                <MenuItem value={2}>Initiate Member</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    {transferIn === 1 ? (
                        <Grid item xs={6} padding={2}>
                            <Button
                                fullWidth
                                size='medium'
                                color='primary'
                                variant='contained'
                                onClick={handleOpenUserModal}
                                sx={{ fontWeight: 'bold' }}
                            >
                                Search for Member
                            </Button>
                        </Grid>
                    ) : <Grid item xs={6} />}
                    {transferIn === 1 && userSelected && data !== null &&
                        <Grid container item>
                            <Grid container item>
                                <CreateTextField
                                    label="First Name"
                                    value={data?.first_name}
                                    readOnly={true}
                                />
                                <CreateTextField
                                    label="Last Name"
                                    value={data?.last_name}
                                    readOnly={true}
                                />
                                <CreateTextField
                                    label="Email"
                                    value={data?.email}
                                    readOnly={true}
                                />
                                <CreateTextField
                                    label="iMIS ID"
                                    value={data?.imis_id}
                                    readOnly={true}
                                />
                            </Grid>
                        </Grid>}
                    {transferIn === 2 &&
                        <Grid container item paddingTop={2}>
                            <CreateTextField
                                label="First Name"
                                value={firstName}
                                readOnly={false}
                                error={firstNameError}
                                validator={nameValidator}
                                onChange={(isValid, value) => setValue('firstName', isValid, value)}
                            />
                            <CreateTextField
                                label="Last Name"
                                value={lastName}
                                readOnly={false}
                                error={lastNameError}
                                validator={nameValidator}
                                onChange={(isValid, value) => setValue('lastName', isValid, value)}
                            />
                            <CreateTextField
                                label="Email"
                                value={email}
                                validator={emailValidator}
                                readOnly={false}
                                error={emailError}
                                onChange={(isValid, value) => setValue('email', isValid, value)}
                            />
                            <CreateTextField
                                label="Phone"
                                value={phone}
                                readOnly={false}
                                error={phoneError}
                                valueRequired={false}
                                validator={phoneNumberValidator}
                                onChange={(isValid, value) => setValue('phone', isValid, value)}
                            />
                            <CreateTextField
                                label="Address 1"
                                value={address1}
                                readOnly={false}
                                error={address1Error}
                                onChange={(isValid, value) => setValue('address1', isValid, value)}
                            />
                            
                            <CreateTextField
                                label="Address 2"
                                value={address2}
                                valueRequired={false}
                                readOnly={false}
                                onChange={(isValid, value) => setValue('address2', isValid, value)}
                            />
                            
                            <CreateTextField
                                label="Address 3"
                                value={address3}
                                valueRequired={false}
                                readOnly={false}
                                onChange={(isValid, value) => setValue('address3', isValid, value)}
                            />

                            <Grid item xs={6}/>
                            <CreateTextField
                                label="City"
                                value={city}
                                readOnly={false}
                                error={cityError}
                                onChange={(isValid, value) => setValue('city', isValid, value)}
                            />
                            <Grid item xs={6} paddingLeft={2} paddingRight={2} paddingBottom={2}>
                            <StateProvinceSelector
                                all={true}
                                error={stateError}
                                errorMessage={stateError ? 'This field is required' : null}
                                size={'small'}
                                required={true}
                                value={state}
                                handleChange={(value) => setValue('state', value === null, value)}/>
                            </Grid>
                            <CreateTextField
                                label="Postal Code"
                                value={zip}
                                readOnly={false}
                                error={zipError}
                                onChange={(isValid, value) => setValue('zip', isValid, value)}
                            />
                        </Grid>
                    }
                    {((transferIn === 1 && userSelected && data !== null) || transferIn === 2) &&
                    <Grid container item>
                        <Grid item xs={6} paddingLeft={2} paddingRight={2} paddingBottom={2}>
                            <DateTimePicker
                                size='small'
                                date={date}
                                label={transferIn === 1 ? "Transfer Date" : "Initiation Date"}
                                minDate={dayjs('2024-03-01')}
                                maxDate={dayjs('2025-02-28')}
                                handleDateChange={handleDateChange}
                                handleErrorUpdate={setError}
                                extraError={error}
                                extraErrorMessage={errorMessage} />
                        </Grid>
                        <Grid item xs={6} paddingLeft={2} paddingRight={2} paddingBottom={2}>
                            <LoadingButton
                                color='primary'
                                variant='contained'
                                size='medium'
                                fullWidth
                                loading={isPending}
                                disabled={isPending}
                                onClick={handleSave}
                                sx={{ fontWeight: 'bold' }}
                            >
                                {transferIn === 1 ? 'Transfer In' : 'Initiate Member'}
                            </LoadingButton>
                        </Grid>
                    </Grid>}
                    <Grid item xs={12}>
                        {showAlert && (
                            <AlertItem
                                severity={alertSeverity}
                                description={alertMessage}
                                onClose={() => setShowAlert(false)} />
                            )}
                    </Grid>
                </Grid>
            </Box>
        </Modal>
        <MemberSearchModal
            modalOpen={userModalOpen}
            setModalOpen={setUserModalOpen}
            setUser={handleSetUser}/>
    </Grid>
  );
}