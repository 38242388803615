import React from 'react'
import { Select, MenuItem, OutlinedInput, FormControl, InputLabel, Skeleton } from '@mui/material'
import { useApiGet } from '../utils/httpClient'
import { getRegions } from '../endpoints/annualReports'


export default function RegionSelector ({formRegion, handleRegionChange, label="EB Liaison"}) {
    const { data } = useApiGet(
      ['spd-regions'],
      getRegions,
      {
        enabled: true,
        retry: 1,
      }
    )
  
    return (
        data ? (
        <FormControl fullWidth={true} variant="outlined" size='small'>
            <InputLabel id="formState">{label}</InputLabel>
            <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                fullWidth={true}
                variant='standard'
                size='small'
                value={formRegion}
                input={<OutlinedInput label={label} />}
                label={label}
                onChange={(event) => handleRegionChange(event.target.value)}
            >
                <MenuItem value={'all'}>All</MenuItem>
                {data?.sort((a, b) => a.order - b.order).map((status) => (
                <MenuItem key={status.id} value={status.id}>{status.name}</MenuItem>))}
            </Select>
        </FormControl>
        ) : (
            <Skeleton>
                <Select label="Regions" />
            </Skeleton>
        )
    )
}