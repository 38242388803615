import React, {useState, useEffect, useCallback} from 'react';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';

export default function DateTimePicker({ date, label, minDate, maxDate, handleDateChange, extraError,
                                         extraErrorMessage, handleErrorUpdate, autoFocus=false, shown=true,
                                         required=true
}) {
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [otherErrorMessage, setOtherErrorMessage] = useState('');
    const [autoOpen, setAutoOpen] = useState(false);

    const setDate = (newValue) => {
        // validate date is between min and max then handle date change
        // otherwise set error
        if (minDate && maxDate && newValue.isAfter(minDate.subtract(1, 'day')) && newValue.isBefore(maxDate.add(1, 'day'))) {
            setError(false);
            setErrorMessage('');
            handleErrorUpdate(false);
            handleDateChange(newValue);
        } else if (!minDate && !maxDate && newValue.isBefore(dayjs(), 'day')) {
            setError(false);
            setErrorMessage('');
            handleErrorUpdate(false);
            handleDateChange(newValue);
        } else {
            handleErrorUpdate(true);
            setError(true);
            if (minDate && maxDate) {
                setErrorMessage(`Date must be between ${minDate.format('MM-DD-YYYY')} and ${maxDate.format('MM-DD-YYYY')}`)
            } else {
                setErrorMessage('Date must be in the past')
            }
        }
    }

    useEffect(() => {
        if (extraError) {
            setError(true);
            setOtherErrorMessage(extraErrorMessage);
        }
    }, [extraError, extraErrorMessage])

    const checkOpen = useCallback(() => {
        if (autoFocus && shown) {
            setAutoOpen(true);
        }
    }, [autoFocus, shown])

    useEffect(() => {
        checkOpen()
    }, [checkOpen])

    return (
        <DatePicker
            value={date}
            label={label}
            open={autoOpen}
            disableFuture={true}
            onOpen={() => setAutoOpen(true)}
            onClose={() => setAutoOpen(false)}
            openTo="year"
            views={['year', 'month', 'day']}
            onError={(newError) => setError(newError)}
            slotProps={{
                textField: {
                    helperText: errorMessage || otherErrorMessage,
                    error: !!error,
                    fullWidth: true,
                    size: 'small',
                    required: required,
                }
            }}
            sx={{
                '& .MuiInputBase-root': {
                  height: 40 // Adjust the height as needed
                },
                '& .MuiOutlinedInput-input': {
                  fontSize: 16 // Adjust the font size as needed
                },

              }}
            minDate={minDate ? minDate : dayjs().subtract(15, 'year')}
            maxDate={maxDate ? maxDate : dayjs()}
            onChange={(newValue) => setDate(newValue)}/>
    )
}