import React, {useEffect, useState} from "react"
import { Grid, TextField, Button} from '@mui/material'
import Modal from '@mui/material/Modal';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';
import { getMember } from "../../endpoints/annualReports";
import { useApiGet } from "../../utils/httpClient";


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 750,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
};


export default function RosterMemberModal({modalOpen, setModalOpen, userId}) {
    const [addressShownn, setAddressShown] = useState(false);
    const [datesShown, setDatesShown] = useState(false);

    const {data, refetch } = useApiGet(
        ['member-details', userId],
        getMember,
        {
          enabled: (userId && modalOpen) ? true : false,
          retry: 1,
          cacheTime: 0
        },
        {
            userId
        }
    )

    const handleClose = () => {
        setAddressShown(false);
        setDatesShown(false);
        setModalOpen(false);
    }

    useEffect(() => {
        if (modalOpen) {
            refetch()
        }
    }, [modalOpen, refetch])

  return (
    <Modal
        open={modalOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        >
        <Box sx={style}>
        {data &&
            <Grid container padding>
                <Grid item xs={12} align={"right"}>
                    <IconButton onClick={handleClose}>
                        <CloseIcon fontSize="small"/>
                    </IconButton>
                </Grid>
                <Grid item xs={6} paddingLeft={2} paddingRight={2} paddingBottom={1}>
                    <TextField
                        id="first-name"
                        label="First Name"
                        InputProps={{
                            readOnly: true,
                        }}
                        fullWidth
                        value={data?.first_name}
                        variant="standard"/>
                </Grid>
                <Grid item xs={6} paddingLeft={2} paddingRight={2} paddingBottom={1}>
                    <TextField
                        id="last-name"
                        label="Last Name"
                        InputProps={{
                            readOnly: true,
                        }}
                        fullWidth
                        value={data?.last_name}
                        variant="standard"/>
                </Grid>
                <Grid item xs={6} paddingLeft={2} paddingRight={2} paddingBottom={1}>
                    <TextField
                        id="email"
                        label="Email"
                        InputProps={{
                            readOnly: true,
                        }}
                        fullWidth
                        value={data?.email}
                        variant="standard"/>
                </Grid>
                <Grid item xs={6} paddingLeft={2} paddingRight={2} paddingBottom={1}>
                    <TextField
                        id="phone"
                        label="Phone"
                        InputProps={{
                            readOnly: true,
                        }}
                        fullWidth
                        value={data?.phone}
                        variant="standard"/>
                </Grid>
                <Grid item xs={6} paddingLeft={2} paddingRight={2} paddingBottom={1}>
                    <TextField
                        id="status"
                        label="Status"
                        InputProps={{
                            readOnly: true,
                        }}
                        fullWidth
                        value={data?.status}
                        variant="standard"/>
                </Grid>
                <Grid item xs={6} paddingLeft={2} paddingRight={2} paddingBottom={1}>
                    <TextField
                        id="chapter-name-7"
                        label="Chapter"
                        InputProps={{
                            readOnly: true,
                        }}
                        fullWidth
                        value={data?.chapter_name}
                        variant="standard"/>
                </Grid>
                <Grid item xs={6} paddingLeft={2} paddingRight={2} paddingBottom={1}>
                    <TextField
                        id="imis"
                        label="Member ID"
                        InputProps={{
                            readOnly: true,
                        }}
                        fullWidth
                        value={data?.imis_id}
                        variant="standard"/>
                </Grid>
                <Grid item xs={6} paddingLeft={2} paddingRight={2} paddingBottom={1}>
                    <TextField
                        id="imis-status"
                        label="Original Status"
                        InputProps={{
                            readOnly: true,
                        }}
                        fullWidth
                        value={data?.imis_status}
                        variant="standard"/>
                </Grid>
                <Grid item xs={12} paddingLeft={1}>
                    <Button onClick={() => setDatesShown(!datesShown)}>{datesShown ? "Hide" : "Show"} Dates</Button>
                </Grid>
                {datesShown && 
                <Grid container item>
                    <Grid item xs={6} paddingLeft={2} paddingRight={2} paddingBottom={1}>
                        <TextField
                            id="reinstatement-date"
                            label="Reinstatement Date"
                            InputProps={{
                                readOnly: true,
                            }}
                            fullWidth
                            value={data?.reinstated_at}
                            variant="standard"/>
                    </Grid>
                    <Grid item xs={6} paddingLeft={2} paddingRight={2} paddingBottom={1}>
                        <TextField
                            id="transfer_in_date"
                            label="Transfer In Date"
                            InputProps={{
                                readOnly: true,
                            }}
                            fullWidth
                            value={data?.transfer_in_date}
                            variant="standard"/>
                    </Grid>
                    <Grid item xs={6} paddingLeft={2} paddingRight={2} paddingBottom={1}>
                        <TextField
                            id="transfer_out_date"
                            label="Transfer Out Date"
                            InputProps={{
                                readOnly: true,
                            }}
                            fullWidth
                            value={data?.transfer_out_date}
                            variant="standard"/>
                    </Grid>
                    <Grid item xs={6} paddingLeft={2} paddingRight={2} paddingBottom={1}>
                        <TextField
                            id="initiation_date"
                            label="Initiation Date"
                            InputProps={{
                                readOnly: true,
                            }}
                            fullWidth
                            value={data?.initiation_date}
                            variant="standard"/>
                    </Grid>
                    <Grid item xs={6} paddingLeft={2} paddingRight={2} paddingBottom={3}>
                        <TextField
                            id="death_date"
                            label="Passing Date"
                            InputProps={{
                                readOnly: true,
                            }}
                            fullWidth
                            value={data?.death_date}
                            variant="standard"/>
                    </Grid>
                </Grid>}
                <Grid item xs={12} paddingLeft={1}>
                    <Button onClick={() => setAddressShown(!addressShownn)}>{addressShownn ? "Hide" : "Show"} Address</Button>
                </Grid>
                {addressShownn &&
                    <Grid container item>
                    <Grid item xs={6} paddingLeft={2} paddingRight={2} paddingBottom={1}>
                        <TextField
                            id="address-1"
                            label="Address 1"
                            InputProps={{
                                readOnly: true,
                            }}
                            fullWidth
                            value={data.address?.address1}
                            variant="standard"/>
                    </Grid>
                    {data.address?.address2 && (
                    <Grid item xs={6} paddingLeft={2} paddingRight={2} paddingBottom={1}>
                        <TextField
                            id="address-2"
                            label="Address 2"
                            InputProps={{
                                readOnly: true,
                            }}
                            fullWidth
                            value={data.address?.address2}
                            variant="standard"/>
                    </Grid>)}
                    {data.address?.address3 && (
                    <Grid item xs={6} paddingLeft={2} paddingRight={2} paddingBottom={1}>
                        <TextField
                            id="address-3"
                            label="Address 3"
                            InputProps={{
                                readOnly: true,
                            }}
                            fullWidth
                            value={data.address?.address3}
                            variant="standard"/>
                    </Grid>)}
                    {(!data.address?.address2 || data.address?.address3) && (
                    <Grid item xs={6} paddingLeft={2} paddingRight={2} paddingBottom={1}/>)}
                    <Grid item xs={6} paddingLeft={2} paddingRight={2} paddingBottom={1}>
                        <TextField
                            id="city"
                            label="City"
                            InputProps={{
                                readOnly: true,
                            }}
                            fullWidth
                            value={data.address?.city}
                            variant="standard"/>
                    </Grid>
                    <Grid item xs={3} paddingLeft={2} paddingRight={2} paddingBottom={3}>
                        <TextField
                            id="state-province"
                            label="State / Province"
                            InputProps={{
                                readOnly: true,
                            }}
                            fullWidth
                            value={data.address?.state}
                            variant="standard"/>
                    </Grid>
                    <Grid item xs={3} paddingLeft={2} paddingRight={2} paddingBottom={3}>
                        <TextField
                            id="postal-code"
                            label="Postal Code"
                            InputProps={{
                                readOnly: true,
                            }}
                            fullWidth
                            value={data.address?.zip_code}
                            variant="standard"/>
                    </Grid>
                    <Grid item xs={6} paddingLeft={2} paddingRight={2} paddingBottom={3}>
                        <TextField
                            id="Country"
                            label="Country"
                            InputProps={{
                                readOnly: true,
                            }}
                            fullWidth
                            value={data.address?.country}
                            variant="standard"/>
                    </Grid>
                </Grid>}
            </Grid>}
        </Box>
    </Modal>
  );
}