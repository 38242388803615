import React, {useState} from 'react';
import { useParams } from 'react-router-dom';
import { Grid, Card, CardContent, Typography, Divider, CardHeader, FormControlLabel, Switch,
         ButtonGroup, IconButton, TextField, Select, MenuItem, 
         Autocomplete} from '@mui/material';
import { useApiGet, useApiSend } from '../../utils/httpClient';
import { getUser, updateUser, getRoles, getApplications, getReports } from '../../endpoints/users';
import Edit from '@mui/icons-material/Edit';
import SaveAsIcon from '@mui/icons-material/SaveAs';
import ClearIcon from '@mui/icons-material/Clear';
import dayjs from 'dayjs';
import { applications}  from '../../utils/variables';
import AlertItem from '../../Components/AlertItem';


const BooleanDisplay = ({value, label, handleUpdate}) => {
    return (
        <FormControlLabel
            control={
                <Switch
                    checked={value}
                    onChange={handleUpdate}
                />
            }
            label={label}
        />
    )
}


const RoleSelector = ({value, handleUpdate, shown}) => {
    const { data } = useApiGet(
        ['roles'],
        getRoles,
        {enabled: shown}
    )

    if (data) {
        return (
            <Select
                value={value}
                onChange={handleUpdate}
                size={'small'}
                fullWidth
            >
                {data.map((role) => (
                    <MenuItem key={role.id} value={role.id}>{role.name}</MenuItem>
                ))}
            </Select>
        )
    } else {
        return null
    }
}


const MultiSelect = ({value, handleUpdate, shown, type}) => {

    const { data: applications } = useApiGet(
        ['applications'],
        getApplications,
        {enabled: shown && type === 'applications'}
    )

    const { data: reports } = useApiGet(
        ['reports'],
        getReports,
        {enabled: shown && type === 'reports'}
    )

    const data = type === 'applications' ? applications : reports

    if (data) {
        return (
            <Autocomplete
                value={value}
                options={data}
                getOptionLabel={(option) => option.name}
                onChange={(event, newValue) => handleUpdate(newValue)}
                fullWidth
                size='small'
                multiple
                renderInput={(params) => (
                    <TextField
                        {...params}
                        variant="standard"
                        label={type === 'applications' ? 'Applications' : 'Reports'}
                    />
                )}
            />
        )
    } else {
        return null
    }
}

const CustomCardHeader = ({title, action, editable, handleClear, handleUpdate, editing, handleEdit}) => {
    return (
        <CardHeader
            title={title}
            sx={{
                '& .MuiCardHeader-title': {
                    fontWeight: "bold",
                    fontSize: "1.2rem"
                },
                pb: 0,
                }}
                action={editable
                ? editing === action ? <ButtonGroup>
                                            <IconButton onClick={handleClear}>
                                                <ClearIcon color={"error"}/>
                                            </IconButton>
                                            <IconButton onClick={handleUpdate}>
                                                <SaveAsIcon color={'success'}/>
                                            </IconButton>
                                            </ButtonGroup>
                                    :   <IconButton onClick={() => handleEdit(action)}>
                                            <Edit color={'blue'}/>
                                        </IconButton>
                : null}
            />
    )
}


export default function UserDetail () {
    let { id } = useParams()
    const [editing, setEditing] = useState(null)
    const [editingValue1, setEditingValue1] = useState(null)
    const [editingValue2, setEditingValue2] = useState(null)
    const [alertOpen, setAlertOpen] = useState(false)
    const [alertMessage, setAlertMessage] = useState('')
    const [alertSeverity, setAlertSeverity] = useState('success')


    const { data, refetch } = useApiGet(
        ['user-detail', id],
        getUser,
        {enabled: id ? true : false},
        {id}
    )

    const { mutate } = useApiSend(
        updateUser,
        (data) => {
            handleClear()
            setAlertMessage("User successfully updated.")
            setAlertSeverity("success")
            setAlertOpen(true)
            refetch()
        },
        (error) => {
            console.log(error)
            handleClear()
            setAlertMessage("Error updating User. If the problem persists, please contact support.")
            setAlertSeverity("error")
            setAlertOpen(true)
        }
    )

    const editable = () => {
        if (id === localStorage.getItem('user_id')) {
            return true
        } else if (allEditable()) {
            return true
        }
        return false
    }

    const allEditable = () => {
        if (localStorage.getItem('apps').includes(applications.UserManagement)) {
            return true
        }
        return false
    }

    const handleEdit = (field) => {
        setEditing(field)
        switch (field) {
            case 'name':
                setEditingValue1(data.first_name)
                setEditingValue2(data.last_name)
                break
            case 'imis':
                setEditingValue1(data.imis_user)
                break
            case 'active':
                setEditingValue1(data.is_active)
                break
            case 'role':
                setEditingValue1(data.role_id)
                break
            case 'applications':
                setEditingValue1(data.applications)
                break
            case 'reports':
                setEditingValue1(data.reports)
                break
            case 'email':
                setEditingValue1(data.email)
                break
            default:
                break
        }
    }

    const handleClear = () => {
        setEditing(null)
        setEditingValue1(null)
        setEditingValue2(null)
    }

    const handleUpdate = (e) => {
        e.preventDefault()
        switch (editing) {
            case 'name':
                mutate({
                    id,
                    first_name: editingValue1,
                    last_name: editingValue2
                })
                break
            case 'imis':
                mutate({
                    id,
                    imis_user: editingValue1
                })
                break
            case 'active':
                mutate({
                    id,
                    is_active: editingValue1
                })
                break
            case 'role':
                mutate({
                    id,
                    role_id: editingValue1
                })
                break
            case 'applications':
                let tempApps = editingValue1.map((app) => app.id)
                mutate({
                    id,
                    applications: tempApps
                })
                break
            case 'reports':
                let tempReports = editingValue1.map((report) => report.id)
                mutate({
                    id,
                    reports: tempReports
                })
                break
            case 'email':
                mutate({
                    id,
                    email: editingValue1
                })
                break
            default:
                break
        }   
    }

    return (
        data ? (
            <Grid container>
                <Grid item xs={12} textAlign={'center'} padding paddingBottom={3}>
                    <Typography variant="h4" component="div" color="primary">
                        User Profile
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    {alertOpen && (
                    <AlertItem
                        severity={alertSeverity}
                        description={alertMessage}
                        onClose={() => setAlertOpen(false)}
                    />)}
                </Grid>
                <Grid container item xs={12} padding>
                    <Grid item xs={12} sm={6} padding>
                        <Card>
                            <CustomCardHeader
                                title="Name"
                                action="name"
                                editing={editing}
                                handleEdit={handleEdit}
                                editable={editable()}
                                handleClear={handleClear}
                                handleUpdate={handleUpdate}/>
                            <CardContent sx={{ pb: 2, pt: 0}}>
                                {editing === 'name' ? (
                                    <Grid container>
                                        <Grid item xs={12} sm={6} padding>
                                            <TextField
                                                label="First Name"
                                                value={editingValue1}
                                                onChange={(e) => setEditingValue1(e.target.value)}
                                                size={'small'}
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6} padding>
                                            <TextField
                                                label="Last Name"
                                                value={editingValue2}
                                                onChange={(e) => setEditingValue2(e.target.value)}
                                                size={'small'}
                                                fullWidth
                                            />
                                        </Grid>
                                    </Grid>
                                ) : (
                                    <Typography variant="body1" component="div" sx={{ whiteSpace: 'pre-line'}}>
                                        {data.first_name} {data.last_name}
                                    </Typography>
                                )}
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} padding>
                        <Card>
                            <CustomCardHeader
                                title="Imis User?"
                                action="imis"
                                editing={editing}
                                handleEdit={handleEdit}
                                editable={allEditable()}
                                handleClear={handleClear}
                                handleUpdate={handleUpdate}/>
                            <CardContent sx={{ pb: 2, pt: 0}}>
                                {editing === 'imis' ? (
                                    <BooleanDisplay
                                        value={editingValue1}
                                        label={editingValue1 ? 'Yes' : 'No'}
                                        handleUpdate={() => setEditingValue1(!editingValue1)}
                                    />
                                ) : (
                                    <Typography variant="body1" component="div" sx={{ whiteSpace: 'pre-line'}}>
                                        {data.imis_user ? 'Yes' : 'No'}
                                    </Typography>
                                )}
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
                <Grid container item xs={12} padding>
                    <Grid item xs={12} sm={6} padding>
                        <Card>
                            <CustomCardHeader
                                title="Email"
                                action="email"
                                editing={editing}
                                handleEdit={handleEdit}
                                editable={allEditable()}
                                handleClear={handleClear}
                                handleUpdate={handleUpdate}/>
                            <CardContent sx={{ pb: 2, pt: 0}}>
                                <Typography variant="body1" component="div" sx={{ whiteSpace: 'pre-line'}}>
                                    {data.email}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} padding>
                        <Card>
                            <CustomCardHeader
                                title="Is Active?"
                                action="active"
                                editing={editing}
                                handleEdit={handleEdit}
                                editable={allEditable()}
                                handleClear={handleClear}
                                handleUpdate={handleUpdate}/>
                            <CardContent sx={{ pb: 2, pt: 0}}>
                                {editing === 'active' ? (
                                    <BooleanDisplay
                                        value={editingValue1}
                                        label={editingValue1 ? 'Yes' : 'No'}
                                        handleUpdate={() => setEditingValue1(!editingValue1)}
                                    />
                                ) : (
                                    <Typography variant="body1" component="div" sx={{ whiteSpace: 'pre-line'}}>
                                        {data.is_active ? 'Yes' : 'No'}
                                    </Typography>
                                )}
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
                <Grid container item xs={12} padding>
                    <Grid item xs={12} sm={6} padding>
                        <Card>
                            <CustomCardHeader
                                title="Role"
                                action="role"
                                editing={editing}
                                handleEdit={handleEdit}
                                editable={allEditable()}
                                handleClear={handleClear}
                                handleUpdate={handleUpdate}/>
                            <CardContent sx={{ pb: 2, pt: 0}}>
                                {editing === 'role' ? (
                                    <RoleSelector
                                        value={editingValue1}
                                        handleUpdate={(e) => setEditingValue1(e.target.value)}
                                        shown={editing === 'role'}
                                    />
                                ) : (
                                    <Typography variant="body1" component="div" sx={{ whiteSpace: 'pre-line'}}>
                                        {data.role}
                                    </Typography>
                                )}
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} padding>
                        <Card>
                            <CardHeader
                                title="Last Login"
                                sx={{
                                    '& .MuiCardHeader-title': {
                                      fontWeight: "bold",
                                      fontSize: "1.2rem"
                                    },
                                    pb: 0,
                                  }}/> 
                            <CardContent sx={{ pb: 2, pt: 0}}>
                                <Typography variant="body1" component="div" sx={{ whiteSpace: 'pre-line'}}>
                                    {data.last_login ? dayjs(data.last_login).format('MMM DD, YYYY h:mm A') : 'Never'}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
                <Grid item xs={12} padding={3}>
                    <Divider>
                        <Typography variant="overline" component="div" fontWeight={'bold'}>
                            Permissions
                        </Typography>
                    </Divider>
                </Grid>
                <Grid container item xs={12} padding>
                    <Grid item xs={12} sm={6} padding>
                        <Card>
                            <CustomCardHeader
                                title="Applications"
                                action="applications"
                                editing={editing}
                                handleEdit={handleEdit}
                                editable={allEditable()}
                                handleClear={handleClear}
                                handleUpdate={handleUpdate}/>
                            <CardContent sx={{ pb: 2, pt: 0}}>
                                {editing === 'applications' ? (
                                    <MultiSelect
                                        value={editingValue1}
                                        handleUpdate={(e) => setEditingValue1(e)}
                                        shown={editing === 'applications'}
                                        type={'applications'}
                                    />
                                ) : (
                                    <Typography variant="body1" component="div" sx={{ whiteSpace: 'pre-line'}}>
                                        {data.applications.map((app) => (
                                            <Typography variant="body1" component="div" sx={{ whiteSpace: 'pre-line'}}>
                                                {app.name}
                                            </Typography>))}
                                        {data.applications.length === 0 && (
                                            <Typography variant="body1" component="div" sx={{ whiteSpace: 'pre-line'}}>
                                                None
                                            </Typography>)}
                                    </Typography>
                                )}
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} padding>
                        <Card>
                            <CustomCardHeader
                                title="Reports"
                                action="reports"
                                editing={editing}
                                handleEdit={handleEdit}
                                editable={allEditable()}
                                handleClear={handleClear}
                                handleUpdate={handleUpdate}/>
                            
                            {editing === 'reports' ? (
                                <CardContent sx={{ pb: 2, pt: 0}}>
                                    <MultiSelect
                                        value={editingValue1}
                                        handleUpdate={(e) => setEditingValue1(e)}
                                        shown={editing === 'reports'}
                                        type={'reports'}
                                    />
                                </CardContent>
                            ) : (
                                <CardContent sx={{ pb: 2, pt: 0}}>
                                    {data.reports.map((report) => (
                                        <Typography variant="body1" component="div" sx={{ whiteSpace: 'pre-line'}}>
                                            {report.name}
                                        </Typography>))}
                                    {data.reports.length === 0 && (
                                        <Typography variant="body1" component="div" sx={{ whiteSpace: 'pre-line'}}>
                                            None
                                        </Typography>)}
                                </CardContent>
                            )}
                        </Card>
                    </Grid>
                </Grid>
            </Grid>
        ) : null
    )
}