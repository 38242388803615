import React from 'react'
import { Select, Autocomplete, TextField, Skeleton } from '@mui/material'
import { useApiGet } from '../utils/httpClient'
import { getTrainingRoles } from '../endpoints/training'


export default function TrainingRolesSelector ({value, handleValueChange, label="Roles"}) {
    const { 
        data
      } = useApiGet(
        ['training-roles'],
        getTrainingRoles,
        {
          enabled: true,
          retry: 1,
        },
    )

    const onValueChange = (newValue) => {
        handleValueChange(newValue)
    }

    return (
        data ? (
            <Autocomplete
                multiple
                variant='standard'
                size='small'
                id="tags-standard"
                options={data}
                getOptionLabel={(option) => option.name}
                value={value}
                onChange={(event, newValue) => onValueChange(newValue)}
                renderInput={(params) => (
                <TextField
                    {...params}
                    variant="standard"
                    label={label}
                />
                )}
            />

        ) : (
            <Skeleton>
                <Select label="Step" />
            </Skeleton>
        )
    )
}