import React, { useEffect, useState } from "react";
import {
  useStripe,
} from "@stripe/react-stripe-js";
import { Alert, Grid, Typography, Link } from "@mui/material";

const STATUS_CONTENT_MAP = {
  succeeded: {
    text: "Payment succeeded",
    severity: "success",
  },
  processing: {
    text: "Your payment is processing.",
    severity: "info",
  },
  requires_payment_method: {
    text: "Your payment was not successful, please try again.",
    severity: "error",
  },
  default: {
    text: "Your payment is processing.",
    severity: "info",
  }
};

export default function CompletePage({handleBack}) {
  const stripe = useStripe();

  const [status, setStatus] = useState("default");

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({paymentIntent}) => {
      if (!paymentIntent) {
        return;
      }

      setStatus(paymentIntent.status);
    });
  }, [stripe]);

  const retryPayment = () => {
    handleBack();
  }

  const descriptionDetails = (severity) => {
    switch (severity) {
      case "success":
        return (
          <div>
            <Typography variant='body1'>
              Thank you for your donation! Notification email has been sent to you for your records.
            </Typography>
            <Link href="https://www.peointernational.org">
              Return to browsing P.E.O. International
            </Link>
          </div>
        );
      case "info":
        return (
          <div>
            <Typography variant='body1'>
              Thank you for your donation! Your payment is processing. Nofication will be sent to you once the payment is completed.
            </Typography>
            <Link href="https://www.peointernational.org">
              Return to browsing P.E.O. International
            </Link>
          </div>
        );
      case "error":
        return (
          <div>
            <Typography variant='body1'>
              Your payment was not successful, please try again.
            </Typography>
            <Link onClick={retryPayment}>
              Retry Payment
            </Link>
          </div>
        );
      default:
        return (
          <div>
            <Typography variant='body1'>
              Something went wrong, please try again.
            </Typography>
            <Link onClick={retryPayment}>
              Retry Payment
            </Link>
          </div>
        );
      }
  }

  return (
    <Grid container>
      <Grid item xs={1} padding/>
      <Grid item xs={10} padding>
        <Alert severity={STATUS_CONTENT_MAP[status].severity} variant="filled">
          {STATUS_CONTENT_MAP[status].text}
        </Alert>
      </Grid>
      <Grid item xs={1} padding/>
      <Grid item xs={1} padding/>
      <Grid item xs={10} padding textAlign={'center'}>
        {descriptionDetails(STATUS_CONTENT_MAP[status].severity)}
      </Grid>
      <Grid item xs={1} padding/>
    </Grid>
  );
}