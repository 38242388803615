export const formStatuses = {
    Unassigned: "b28842d9ad2641338faeead81752dd27",
    InactiveMembers: "70ab0a4e56f14072893c608b830aa6dc",
    TransferOut: "f05b1160c6e245cd9c5ff2542fe2ccf7",
    ActiveMembers: "f4f4ee067e3d4ab0b8ede6627e4ab0ac",
    DuesForm: "622be9722edf4de4b2b66e0e40bbeeef",
    NotStarted: "83434fd0ce264fe09a424596823e80f2"
}

export const formStates = {
    LCTreas: 'c15526c2aebb4713865745ad0ae959c7',
    LCPres: '3e0315958276403bbe2697f83e49e434',
    SpdTreas: 'd7da036b82af4f27b221e1754601dd20',
    SpdSec: '188da6218c6e4e329ce2e6b11b1c7a57',
    SpdExec: '718f31ce9ebb45319b5d9862bd56d96a',
    SpdPres: 'fdd32de02b1c45dbacacae67bd439275',
    Completed: 'a035d8b411774ebfadfc8d3ee1d703f2',
}

export const irsStates = {
    LCTreas: 'd8a0127a9fed426492205fabddc6ef7d',
    LCPres: '9b5971f8fd7047958ffc156e4a2efb55',
    SpdTreas: '9fb1ea53c7b44c33acef63a86484f82d',
}

export const memberStatuses = {
    Unassigned: "0fac81ac43094fccae6264c6a76f51e7",
    Inactive: "bbcd1dfce5a449fba82f7d3e131c4a08",
    Active: "2efc683e8d4a4b7bbdb700f27c18ee08",
    Other: "2ae99ae943064a638aa8fb1806e16ce7",
    NoProcessing: "a8a813a5c5b04470a102791fc6107b15"
}

export const receiptQuestions = {
    question1: 'c2d10e6c39bf4b84b728aa0e6324746e',
    question2: '77bdefb130884ca2af352ca3e80f8ee3',
    subQuestion3: 'eafd00006a064224966088c3b59384f0',
    subQuestion4: '0b530ad12c604169a7b2e36218bf3677',
    question3: '4f19da96a310482ab27cc4c85aa919de',
    question7: '4bab454f0fc04da9baf0592c10016618',
    question8: 'b4b75e42cc724f39a3adf85c6b4d3bcd',
    question9: '27b665b2aead4f2f915339640601d38c',
    question10: '2aa92f4b9d1140e7b4160c0ab4220f08'
}

export const disbursementQuestions = {
    question0: '14f5fd664fb64f80ab2d76c3dd92b9ec',
    question1: '04eacf562cb24a2bad38630a58fe1a2d',
    question2: 'ef17ef2ed43145618c34fcd2f25926d3',
    question3: '58d97ad6560245bdb4eb7d3a64c7a87f',
    question4: 'd64c8ca603e146899f5394bd25f819fa',
    question5: '9b851331d4e54e6485e4036581a80c19',
    subQuestion3: '9b8835fdfbd44ce792332be2bcf446cc',
    subQuestion4: '9716d7bdd8514fb49e94f082afef6c95',
    question6: '7ffbeb17f21140a4b0140787e4ac3821',
    question9: 'c07551a1eccc4168b74f43ee275ac5e0',
    question10: 'b21d13cf442743128759438d57d33e05',
}

export const assetQuestions = {
    question1: 'bb0c5672578c479c854c8e17b00714cf',
    question3: '468289cd36904f109f773fa1327bd153',
    question4: 'a970c5d2f2264c4a88cca4545b7a138d',
    question5: '46987ef3b44f48359032a2f15d3a2d1d',
    question6: '15e5e73ebb9a47398a73ffb3c5f4e47c',
    question7: '67ff33369da145adb77ec5f48fd18120',
}

export const applications = {
    DocumentAdmin: '1318b71740ac4264823f5e1f9d4bb189',
    TrainingAdmin: '511207ced22e452cb9148c26bcbcae61',
    UserManagement: '5632faa767544d41b49307a34a953459',
    AnnualReportAdmin: '6485c3cb78f946b391ab2fd6f894522b',
    ExecAppBar: 'a6bed255bd9e46228b257235b4250aeb',
}

export const projects = {
    Foundations: 'd715cd09d85844039ba4807c991232c2',
    ELF: '134fcb458f1a447fb6854393e4d4ffd3',
    IPS: '2385c3c477c54329b2afbe3a6c7f432b',
    PCE: 'a3329a35021f4db1b9608945f5b501df',
    PSA: 'aa9e8eb796734e2887f3d92fa211f77c',
    STAR: 'f7369bfc19a24558ada0a77f3f740dd2'
}
