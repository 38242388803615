import React, {useEffect, useState, useCallback} from 'react';
import { Grid, Stepper, Step, StepLabel  } from '@mui/material';
import DonationPI from './DonationPI';
import Billing from './Billing';
import DonationInfo from './DonationInfo';
import { useSearchParams } from "react-router-dom";
import { getInProgressDonation } from '../../endpoints/donations';
import { useApiGet } from '../../utils/httpClient';


const Spacer = () => {
    return (
        <Grid item xs={1} sm={2} md={3}/>
    )
}



export default function Donations() {
    let [searchParams, setSearchParams] = useSearchParams()

    const id = searchParams.get('id')

    const updateSearchParams = useCallback((key, value) => {
        if (value || value === 0) {
            searchParams.set(key, value)
        } else {
            searchParams.delete(key)
        }
        setSearchParams(searchParams)
    }, [searchParams, setSearchParams])

    const [activeStep, setActiveStep] = useState(searchParams.get('step') ? parseInt(searchParams.get('step')) : 0)
    const [step1Complete, setStep1Complete] = useState(false)
    const [step2Complete, setStep2Complete] = useState(false)
    const [step3Complete, setStep3Complete] = useState(false)
    const [step4Complete, setStep4Complete] = useState(false)
    // Donation Information
    const [donationId, setDonationId] = useState(null)
    const [donationObject, setDonationObject] = useState(null)

    const { data: donationData, refetch } = useApiGet(
        ['donation', donationId],
        getInProgressDonation,
        {enabled: donationId ? true : false},
        {id: donationId}
    )

    useEffect(() => {
        if (searchParams.has('step')) {
            setActiveStep(parseInt(searchParams.get('step')))
        } else {
            setActiveStep(0)
        }
    }, [searchParams])

    useEffect(() => {
        updateSearchParams('step', activeStep)
    }, [activeStep, updateSearchParams])

    useEffect(() => {
        updateSearchParams('id', donationId)
    }, [donationId, updateSearchParams])

    useEffect(() => {
        if (id) {
            setDonationId(id)
        }
    }, [id])

    useEffect(() => {
        if (donationData) {
            setDonationObject(donationData)
        }
    }, [donationData])

    let steps = [
        {
            name: "Donor Information",
            state: step1Complete,
            step: 1
        },
        {
            name: "Donation Details",
            state: step2Complete,
            step: 2
        },
        {
            name: "Payment",
            state: step3Complete,
            step: 3
        },
        {
            name: "Finish",
            state: step4Complete,
            step: 4
        }
    ]

    const handleNext = () => {
        switch (activeStep) {
            case 0:
                setStep1Complete(true)
                break;
            case 1:
                setStep2Complete(true)
                break;
            case 2:
                setStep3Complete(true)
                break;
            case 3:
                setStep4Complete(true)
                break;
            default:
                break;
        }
        if (activeStep >= steps.length - 1) {
            return
        }
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }

    const handleBack = () => {
        switch (activeStep) {
            case 1:
                setStep1Complete(false)
                break;
            case 2:
                setStep2Complete(false)
                break;
            case 3:
                setStep3Complete(false)
                break;
            default:
                break;
        }
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }

    const activeScreen = () => {
        switch (activeStep) {
            case 0:
                return <DonationPI 
                            handleNext={handleNext}
                            refetch={refetch}
                            donationId={donationId}
                            donationObject={donationObject}
                            setDonationId={setDonationId}/>
            case 1:
                return <DonationInfo
                            donationId={donationId}
                            refetch={refetch}
                            handleBack={handleBack}
                            donationObject={donationObject}
                            handleNext={handleNext}/>
            case 2:
                return <Billing
                            donationId={donationId}
                            step={activeStep}
                            refetch={refetch}
                            donationObject={donationObject}
                            handleBack={handleBack}/>
            case 3:
                return <Billing
                            donationId={donationId}
                            step={activeStep}
                            refetch={refetch}
                            donationObject={donationObject}
                            handleBack={handleBack}/>
            default:
                return <DonationPI />
        }
    }

    return (
        <Grid container sx={{ minHeight: '85vh'}}>
            <Grid item container className="backgroundImage">
                <Grid item xs={12} paddingTop={5}/>
                <Grid item container xs={12}>
                    <Spacer/>
                    <Grid item xs={10} sm={8} md={6} sx={{ backgroundColor: '#ffffff'}}>
                        <Stepper activeStep={activeStep} sx={{ p: 2}}>
                            {steps.map((step, index) => {
                                return (
                                    <Step
                                        key={step.name}
                                        completed={step.state}>
                                        <StepLabel>
                                            {step.name}
                                        </StepLabel>
                                    </Step>
                                )
                            })}
                        </Stepper>
                        <Grid item paddingRight paddingLeft>
                            {activeScreen()}
                        </Grid>
                    </Grid>
                    <Spacer/>
                </Grid>
                <Grid item xs={12} paddingBottom={7}/>
            </Grid>
        </Grid>
    )
}