import React, { useCallback, useRef } from 'react'
import update from 'immutability-helper'
import { useDrag, useDrop } from "react-dnd";

import { Card, CardContent, Grid, IconButton, Typography } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';


const style1 = {
  border: "1px dashed gray",
  padding: "0.5rem 1rem",
  marginBottom: ".5rem",
  backgroundColor: "white",
  cursor: "move"
};

const MyCard = ({ text, index, moveCard, answerText, onRemoveQuestion }) => {
  const ref = useRef(null);
  const [, drop] = useDrop({
    accept: 'card',
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    }
  });
  const [{ isDragging }, drag] = useDrag({
    type: 'card',
    item: { index },
    collect: monitor => ({
      isDragging: monitor.isDragging()
    })
  });
  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));
  return (
    <CustomCard
        ref={ref}
        style={{ ...style1, opacity }}
        text={text}
        answerText={answerText}
        onRemoveQuestion={onRemoveQuestion}
        index={index}/>
  );
};

const CustomCard = React.forwardRef((props, ref) => {
    return (
  <Card ref={ref} sx={{ borderRadius: '16px'}}>
    <CardContent sx={{ pl: 2 }}>
        <Grid container>
            <Grid item xs={11}>
                <Typography variant="h6" component="div">
                    {props.text}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                    {props.answerText}
                </Typography>
            </Grid>
            <Grid item xs={1} textAlign={'right'}>
                <IconButton color='primary' onClick={() => props.onRemoveQuestion(props.index)}>
                    <DeleteIcon/>
                </IconButton>
            </Grid>
        </Grid>
      </CardContent>
  </Card>
)});



const Questions = ({questions, onQuestionOrderUpdate, onRemoveQuestion}) => {
    const moveCard = useCallback(
      (dragIndex, hoverIndex) => {
        const dragCard = questions[dragIndex]
        onQuestionOrderUpdate(
          update(questions, {
            $splice: [
              [dragIndex, 1],
              [hoverIndex, 0, dragCard],
            ],
          }),
        )
      },
      [questions, onQuestionOrderUpdate],
    )

    const getAnswerText = (question) => {
        switch(question.answer) {
            case 'a':
                return question.selection_a
            case 'b':
                return question.selection_b
            case 'c':
                return question.selection_c
            case 'd':
                return question.selection_d
            case 'e':
                return question.selection_e
            default:
                return question.answer
        }
    }
    const renderCard = (card, index) => {
      return (
        <Grid item xs={12} padding>
            <MyCard
                key={card.order}
                index={index}
                id={card.order}
                text={card.question}
                answerText={getAnswerText(card)}
                onRemoveQuestion={() => onRemoveQuestion(index)}
                moveCard={moveCard}
            />
        </Grid>
      )
    }
    return (
        <Grid item container>
            {questions.map((card, i) => renderCard(card, i))}
        </Grid>

    )
}
export default Questions
