import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Grid, Box, Tab, Skeleton } from '@mui/material';
import { useApiGet } from '../../utils/httpClient';
import { getIRSQuestions } from '../../endpoints/annualReports';
import GoodsSold from './GoodsSold';
import CheckIcon from '@mui/icons-material/Check';
import DangerousIcon from '@mui/icons-material/Dangerous';
import ErrorIcon from '@mui/icons-material/Error';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import Receipts from './Receipts';
import BedNBreakfast from './BedNBreakfast';
import Assets from './Assets';
import Disbursements from './Disbursements';
import Totals from './Totals';
import AlertItem from '../../Components/AlertItem';
import ConfirmationDialog from '../../Components/ConfirmationDialog';


export default function TabInfo ({assigned, refetchParent, previousTotal, notes, fedIdNumber,
                                  assignedTo, currentState, currentStatus, parentAlertMessage,
                                  parentAlertSeverity, parentShowAlert, setParentKeepOpen,
                                  setFormSubmitOpen, setFormSubmitMessage}) {
  let { id } = useParams();

  const handleChange = (event, newValue) => {
    if (editingTab) {
      setNextTab(newValue);
      setWarningOpen(true);
      return;
    }
    setValue(newValue);
  };

  const { data, isFetching, refetch } = useApiGet(
    ['irs-questions', id, assignedTo, currentState, currentStatus],
    getIRSQuestions,
    {
      enabled: true,
      retry: 1,
    },
    { id }
  )

  const calculateInitialTab = useCallback(() => {
    if (data?.next_section) {
      return data.next_section
    } else {
      return "total"
    }
  }, [data])
  const [value, setValue] = useState(calculateInitialTab);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertSeverity, setAlertSeverity] = useState('success');
  const [editingTab, setEditingTab] = useState(false);
  const [nextTab, setNextTab] = useState('');
  const [warningOpen, setWarningOpen] = useState(false);

  useEffect(() => {
    setValue(calculateInitialTab)
  }, [data, calculateInitialTab])


  const tabIcon = (section, completedSections, nextSection) => {
 

    let icon = <DangerousIcon color={"blue"}/>
    if (nextSection === section && assigned === true) {
        icon = <ErrorIcon sx={{ color: "#CC7722" }}/>
    } else if (completedSections.includes(section) && assigned === true) {
        icon = <CheckIcon color={"green"}/>
    }
    return icon
  }

  const handleConfirm = () => {
    setWarningOpen(false);
    setEditingTab(false);
    setValue(nextTab);
    setNextTab('');
  }

  const handleCancel = () => {
    setWarningOpen(false);
    setNextTab('');
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        {showAlert && (
          <AlertItem
            severity={alertSeverity}
            description={alertMessage}
            onClose={() => setShowAlert(false)} />
        )}
      </Grid>
      {isFetching ? <Skeleton variant="rectangular" height={500} /> : (
      <Grid item xs={12}>
        <Box
        sx={{ display: '100%' }}
      >
        <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList
            onChange={handleChange}
            aria-label="neat"
            TabIndicatorProps={{
              sx: { height: '6px' },
            }}
          >
            <Tab
              label="Goods Sold"
              value="goods_sold"
              icon={tabIcon('goods_sold', data.completed_sections, data.next_section)}
              iconPosition="start"/>
            <Tab
              label="Bed and Breakfast"
              value="bed_and_breakfast"
              icon={tabIcon('bed_and_breakfast', data.completed_sections, data.next_section)}
              iconPosition="start"/>
            <Tab
              label="Receipts"
              value="receipts"
              icon={tabIcon('receipts', data.completed_sections, data.next_section)}
              iconPosition="start"/>
            <Tab
              label="Disbursements"
              value="disbursements"
              icon={tabIcon('disbursements', data.completed_sections, data.next_section)}
              iconPosition="start"/>
            <Tab
              label="Assets"
              value="assets"
              icon={tabIcon('assets', data.completed_sections, data.next_section)}
              iconPosition="start"/>
            <Tab
                label="Totals"
                value="total"
                icon={tabIcon('total', data.completed_sections, data.next_section)}
                iconPosition="start"/>
          </TabList>
        </Box>
        <TabPanel value={"receipts"}>
          <Receipts
            updateUserEditing={setEditingTab}
            userEditing={editingTab}
            questions={data['receipts']}
            refetch={refetch}
            otherQuestions={[
              data['goods_sold'].filter(q => q.question_id === '4c50bf2c5451427e8bc487dafaa70060')[0],
              data['bed_and_breakfast'].filter(q => q.question_id === '1885ea03cb094fe8847ba269a8b07c61')[0]
            ]}
            alertOpen={() => setShowAlert(true)}
            alertMessage={(message) => setAlertMessage(message)}
            alertSeverity={(severity) => setAlertSeverity(severity)}
            editable={
              assigned && (data.next_section === 'receipts' || data.completed_sections.includes('receipts'))}/>
        </TabPanel>
        <TabPanel value={"goods_sold"}>
          <GoodsSold
            updateUserEditing={setEditingTab}
            userEditing={editingTab}
            questions={data['goods_sold']}
            refetch={refetch}
            refetchParent={refetchParent}
            alertOpen={() => setShowAlert(true)}
            alertMessage={(message) => setAlertMessage(message)}
            alertSeverity={(severity) => setAlertSeverity(severity)}
            editable={
              assigned && (data.next_section === 'goods_sold' || data.completed_sections.includes('goods_sold'))}/>
        </TabPanel>
        <TabPanel value={"bed_and_breakfast"}>
          <BedNBreakfast
            updateUserEditing={setEditingTab}
            userEditing={editingTab}
            questions={data['bed_and_breakfast']}
            refetch={refetch}
            alertOpen={() => setShowAlert(true)}
            alertMessage={(message) => setAlertMessage(message)}
            alertSeverity={(severity) => setAlertSeverity(severity)}
            editable={
              assigned && (data.next_section === 'bed_and_breakfast' || data.completed_sections.includes('bed_and_breakfast'))}/>
        </TabPanel>
        <TabPanel value={"disbursements"}>
          <Disbursements
            updateUserEditing={setEditingTab}
            userEditing={editingTab}
            questions={data['disbursements']}
            otherQuestions={[
              data['goods_sold'].filter(q => q.question_id === 'ecea95f923634856af8800ac1c026bca')[0],
              data['bed_and_breakfast'].filter(q => q.question_id === 'd94ef3e0e30a45c6a15134b5521a6387')[0]
            ]}
            currentDuesTotal={data.dues_total}
            refetch={refetch}
            alertOpen={() => setShowAlert(true)}
            alertMessage={(message) => setAlertMessage(message)}
            alertSeverity={(severity) => setAlertSeverity(severity)}
            editable={
              assigned && (data.next_section === 'disbursements' || data.completed_sections.includes('disbursements'))}/>
        </TabPanel>
        <TabPanel value={"assets"}>
          <Assets
            updateUserEditing={setEditingTab}
            userEditing={editingTab}
            questions={data['assets']}
            refetch={refetch}
            alertOpen={() => setShowAlert(true)}
            alertMessage={(message) => setAlertMessage(message)}
            alertSeverity={(severity) => setAlertSeverity(severity)}
            editable={
              assigned && (data.next_section === 'assets' || data.completed_sections.includes('assets'))}
            refetchParent={refetchParent}/>
        </TabPanel>
        <TabPanel value={"total"}>
          <Totals
            updateUserEditing={setEditingTab}
            questions={data}
            formNotes={notes}
            previousTotal={previousTotal}
            refetch={refetch}
            fedIdNumber={fedIdNumber}
            editable={
              assigned && (data.next_section === 'total' || data.completed_sections.includes('total'))
            }
            parentAlertOpen={() => parentShowAlert(true)}
            parentAlertMessage={(message) => parentAlertMessage(message)}
            parentAlertSeverity={(severity) => parentAlertSeverity(severity)}
            setFormSubmitOpen={(value) => setFormSubmitOpen(value)}
            setParentKeepOpen={(value) => setParentKeepOpen(value)}
            setFormSubmitMessage={(message) => setFormSubmitMessage(message)}
            currentState={currentState}
            refetchParent={refetchParent}
            handleRedirectTab={(tab) => setValue(tab)}/>
        </TabPanel>
        </TabContext>
        </Box>
      </Grid>)}
      <ConfirmationDialog
        open={warningOpen}
        handleOk={handleConfirm}
        handleCancel={handleCancel}
        title={'Changes Detected'}
        message={'You have unsaved changes on this form. Are you sure you want to continue?'}
        confirmText={'Change Tab'}
        cancelText={'Cancel'}/>
    </Grid>
  );
}