import React, {useState} from 'react';
import { useParams } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import Paper from '@mui/material/Paper';
import { TableVirtuoso } from 'react-virtuoso';
import { Grid, Box, ButtonGroup, IconButton, Typography, Button, TextField } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import DateDialog from './DateDialog';
import { updateRosterMember, resetRosterMember, rejectDuesForm } from '../../endpoints/annualReports';
import { useApiSend } from '../../utils/httpClient';
import ApprovalButton from './ApprovalButton';
import { formStatuses, formStates } from '../../utils/variables';
import LoadingButton from '@mui/lab/LoadingButton';
import RosterMemberModal from './RosterMemberDetail';
import CreateMemberModal from './CreateMember';
import HistoryIcon from '@mui/icons-material/History';
import HistoryModal from '../../Components/HistoryModal';
import ConfirmationDialog from '../../Components/ConfirmationDialog';
import { FormControl, FormControlLabel, Checkbox } from '@mui/material';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import UpdateIcon from '@mui/icons-material/Update';


const columns = [
  {
    width: 315,
    label: 'Action',
    dataKey: 'action',
    skip: true,
  },
  {
    width: 120,
    label: 'Name',
    dataKey: 'full_name',
  },
  {
    width: 120,
    label: 'Member ID',
    dataKey: 'imis_id',
  },
  {
    width: 50,
    label: null,
    dataKey: 'info',
    skip: true
  },
];

const VirtuosoTableComponents = {
  Scroller: React.forwardRef((props, ref) => (
    <TableContainer component={Paper} {...props} ref={ref} />
  )),
  Table: (props) => (
    <Table {...props} sx={{ borderCollapse: 'separate', tableLayout: 'fixed' }} />
  ),
  TableHead: React.forwardRef((props, ref) => <TableHead {...props} ref={ref} />),
  TableRow,
  TableBody: React.forwardRef((props, ref) => <TableBody {...props} ref={ref} />),
};

export default function ActiveMembers({rowData, refetch, editable, refetchParent, currentStatusId,
                                       assigned, formStateId, alertOpen, alertMessage, alertSeverity,
                                       setFormSubmitOpen, setFormSubmitMessage, parentAlertOpen,
                                       parentAlertMessage, parentAlertSeverity, parentSetFormSubmitOpen,
                                       parentSetParentKeepOpen, parentSetFormSubmitMessage}) {
    const [date, setDate] = useState(null);
    const [dateRequested, setDateRequested] = useState(false);
    const [action, setAction] = useState(null);
    const [memberId, setMemberId] = useState(null);
    const [inProgress, setInProgress] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [dateRestricted, setDateRestricted] = useState(true);
    const [showHistoryModal, setShowHistoryModal] = useState(false);
    const [openConfirm, setOpenConfirm] = useState(false);
    const [verified, setVerified] = useState(false);
    const [showButtons, setShowButtons] = useState(false);
    const [notes, setNotes] = useState('');
    const [notesError, setNotesError] = useState(false);
    const [notesErrorDescrption, setNotesErrorDescrption] = useState('');

    let { id } = useParams();

    const { mutate: rejectForm } = useApiSend(
      rejectDuesForm,
      (data) => {
          setInProgress(false)
          parentSetFormSubmitMessage("The form has been rejected.")
          parentSetParentKeepOpen(false)
          parentSetFormSubmitOpen(true)
          parentAlertMessage("The form has been rejected.")
          parentAlertSeverity("success")
          parentAlertOpen(true)
          refetchParent()
          refetch()
      },
      (error) => {
        setInProgress(false)
        console.log(error)
      },
    )

    const { mutate } = useApiSend(
      updateRosterMember,
      (data) => {
          setAction(null);
          setMemberId(null);
          setDate(null);
          refetch();
          setInProgress(false)
      },
      (error) => {
        setInProgress(false)
      },
    )

    const { mutate: resetMember } = useApiSend(
      resetRosterMember,
      (data) => {
          refetch();
          refetchParent();
          setInProgress(false)
      },
      (error) => {
        setInProgress(false)
      },
    )

    const handleConfirm = () => {
      setOpenConfirm(false)
      setInProgress(true)
      resetMember({
        rosterId: id,
        memberId: memberId,
      })
    }
  
    const handleCancel = () => {
      setOpenConfirm(false)
    }

    const onNotesChange = (event) => {
      setNotes(event.target.value)
      if (event.target.value.length > 4000) {
        setNotesError(true)
        setNotesErrorDescrption('Notes must be less than 4000 characters')
      } else {
        setNotesError(false)
        setNotesErrorDescrption('')
      }
    }

    const handleReject = () => {
      if (notes.length === 0) {
        setNotesError(true)
        setNotesErrorDescrption('Notes are required')
      } else {
        if (!notesError) {
          setInProgress(true)
          rejectForm({
            id,
            notes,
          })
        }
      }
    }

    const buildRequest = (dateValue=null) => {
      let request = {
        rosterId: id,
        memberId: memberId,
      }
      switch (action) {
        case 'transfer':
          request['transfer_out'] = true
          request['transfer_date'] = dateValue.format('YYYY-MM-DD')
          break;
        case 'passed':
          request['passed'] = true
          request['passed_date'] = dateValue.format('YYYY-MM-DD')
          break;
        default:
          break;
      }
      return request
    }

    const handleClose = (dateValue) => {
        setDateRequested(false);
        if (dateValue) {
            // perform action
            mutate(buildRequest(dateValue))
        } else {
          setInProgress(false)
        }
    }

    const handleButtonClick = (action, member) => {
      setMemberId(member.id)
      setInProgress(true)
        let actionsRequiringDate = [
          'transfer', 'passed'
        ]
        if (actionsRequiringDate.includes(action)) {
          if (action === 'passed' && !['AA', 'AR'].includes(member.imis_status)) {
            setAction(action)
            setDateRestricted(false)
            setDateRequested(true)
          } else {
            setAction(action)
            setDateRestricted(true)
            setDateRequested(true)
          }

        } else {
            mutate({
                rosterId: id,
                memberId: member.id,
                is_inactive: true
            })
        }        
    }

    const handleOpenModal = (id) => {
      setMemberId(id)
      setShowModal(true)
    }

    const handleCloseModal = () => {
      setMemberId(null)
      setShowModal(false)
    }

    const handleHistoryOpen = (id) => {
      setMemberId(id)
      setShowHistoryModal(true)
    }

    const handleCloseHistoryModal = () => {
      setMemberId(null)
      setShowHistoryModal(false)
    }

    const handleResetMember = (member) => {
      setMemberId(member.id)
      setOpenConfirm(true)
    }

    const fixedHeaderContent = () =>  {
      return (
        <TableRow>
          {columns.map((column) => (
            <TableCell
              key={column.dataKey}
              variant="head"
              align={column.dataKey === 'action' ? 'left' : 'right'}
              style={{ width: column.width }}
              sx={{
                backgroundColor: 'background.paper',
              }}
            >
              {column.label === 'Action' ? (
                <Grid container direction={'row'} sx={{ justifyContent: 'flex-start'}}>
                  <Grid item>
                    <Typography variant='body2'>
                      Action
                    </Typography>
                  </Grid>
                  <Grid item paddingLeft={2}>
                    <Tooltip title="Click here to change membership status for those listed below." placement='top'>
                    <Button onClick={() => setShowButtons(!showButtons)} disabled={!editable} variant='contained' size={'small'}>
                      <UpdateIcon color={editable ? 'white' : 'grey'} fontSize='small' sx={{ pr: 1}}/>
                      {showButtons ? 'Cancel' : 'Update Active Members'}
                    </Button>
                    </Tooltip>
                  </Grid>
                </Grid>
              ) : (
                <Typography variant='body2'>
                  {column.label}
                </Typography>
              )}
            </TableCell>
          ))}
        </TableRow>
      );
    }


    const rowContent = (_index, row) => {
        return (
            <React.Fragment>
                <TableCell
                  key={'action'}
                  align={'left'}
                >
                  {showButtons ? (
                    <ButtonGroup size="small" variant='outlined'>
                      <Tooltip title="Places member in Inactive status." placement='top-end'>
                        <LoadingButton
                            key='inactive'
                            loading={row.id === memberId ? inProgress: false}
                            sx={{ fontWeight: 'bold' }}
                            disabled={!editable}
                            onClick={() => handleButtonClick('inactive', row)}
                        >
                            InActive
                        </LoadingButton>
                      </Tooltip>
                      <Tooltip title="Member transferred to another chapter." placement='top-end'>
                        <LoadingButton
                          key='transfer'
                          color="blue"
                          loading={row.id === memberId ? inProgress: false}
                          sx={{ fontWeight: 'bold' }}
                          disabled={!editable}
                          onClick={() => handleButtonClick('transfer', row)}
                        >
                          Transferred
                        </LoadingButton>
                      </Tooltip>
                      <Tooltip title="Member passed away." placement='top-end'>
                        <LoadingButton
                          key='passed'
                          color="black"
                          loading={row.id === memberId ? inProgress: false}
                          sx={{ fontWeight: 'bold' }}
                          disabled={!editable}
                          onClick={() => handleButtonClick('passed', row)}
                        >
                          Passed
                        </LoadingButton>
                      </Tooltip>
                      <Tooltip title="Resets member to original status (before any updates)." placement='top-end'>
                        <LoadingButton
                          key='reset'
                          color="warning"
                          loading={row.id === memberId ? inProgress: false}
                          sx={{ fontWeight: 'bold' }}
                          disabled={!editable && ![formStates.LCTreas, formStates.SpdTreas].includes(formStateId)}
                          onClick={() => handleResetMember(row)}
                        >
                          Reset
                        </LoadingButton>
                      </Tooltip>
                    </ButtonGroup>
                  ) : (
                    <React.Fragment>
                      <Grid container>
                          <Button>
                            <TaskAltIcon color='green' fontSize='small'/>
                            <Typography variant='body2' sx={{ fontWeight: 'bold', color: (theme) => theme.palette.success.main }}>
                              Active
                            </Typography>
                          </Button>
                      </Grid>
                    </React.Fragment>
                  )}
                  <DateDialog
                    open={dateRequested}
                    dateRestricted={dateRestricted}
                    onClose={handleClose}
                    dateValue={date}
                    action={action}/>
                </TableCell>
              {columns.filter((item) => !item.skip).map((column) => (
                <TableCell
                  key={column.dataKey}
                  align={'right'}
                >
                  {row[column.dataKey]}
                </TableCell>
              ))}
              <TableCell
                  key={'info'}
                  align={'right'}
                  display={'flex'}
                >
                  <Tooltip title="View Member Details" placement='top'>
                    <IconButton onClick={() => handleOpenModal(row.id)} size='small'>
                      <InfoOutlinedIcon color="primary"/>
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="View Member History" placement='top'>
                    <IconButton onClick={() => handleHistoryOpen(row.id)} size='small'>
                      <HistoryIcon color="primary"/>
                    </IconButton>
                  </Tooltip>
                </TableCell>
            </React.Fragment>
          );
    }
    return (
        <Grid container>
          {currentStatusId !== formStatuses.ActiveMembers && (
            <Grid item xs={12} padding textAlign={'center'}>
              <Typography variant='body2' sx={{ pb: 1}}>
                This tab currently cannot be edited. This is due to: Required work needing to be completed on a tab to the left or current assignee / step does not allow.
              </Typography>
              <CreateMemberModal
                disabled={!editable}
                rosterId={id}
                parentRefetch={refetch}/>
            </Grid>)}
          {editable && (
            <Grid container item xs={12} justifyItems={'stretch'}>
              <Grid container item xs={12} md={5} padding>
                <Grid item xs={12}>
                  <FormControl>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={verified}
                          onChange={(e) => setVerified(e.target.checked)}
                          size={'medium'}
                        />
                      }
                      label="All members below have been verified."
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} padding>
                  <ApprovalButton
                    formStateId={formStateId}
                    setFormSubmitMessage={(value) => setFormSubmitMessage(value)}
                    setFormSubmitOpen={(value) => setFormSubmitOpen(value)}
                    refetchParent={refetchParent}
                    alertMessage={(message) => alertMessage(message)}
                    alertOpen={alertOpen}
                    alertSeverity={(severity) => alertSeverity(severity)}
                    id={id}
                    disabled={!verified}/>
                  </Grid>
              </Grid>
            </Grid>
            )}
          {!editable && assigned && formStateId === formStates.SpdSec && (
            <Grid container item xs={12} padding>
              <Grid item xs={12} paddingLeft>
                <FormControl>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={verified}
                        onChange={(e) => setVerified(e.target.checked)}
                        size={'medium'}
                      />
                    }
                    label="All members below have been verified."
                  />
                </FormControl>
              </Grid>
              {!verified &&
              <Grid item xs={12}>
                <Typography variant="body2" paddingLeft>
                  If the form is being rejected, please provide an explanation in the box below.
                </Typography>
              </Grid>}
              {!verified &&
              <Grid item xs={12} padding>
                <TextField
                  id="detailed-notes"
                  label="Explanation / Notes"
                  multiline
                  error={notesError}
                  helperText={notesErrorDescrption}
                  value={notes}
                  onChange={onNotesChange}
                  rows={2}
                  fullWidth/>
              </Grid>}
              <Grid item xs={12} padding>
                <ButtonGroup>
                  <ApprovalButton
                    formStateId={formStateId}
                    setFormSubmitMessage={(value) => setFormSubmitMessage(value)}
                    setFormSubmitOpen={(value) => setFormSubmitOpen(value)}
                    refetchParent={refetchParent}
                    alertMessage={(message) => alertMessage(message)}
                    alertOpen={alertOpen}
                    alertSeverity={(severity) => alertSeverity(severity)}
                    id={id}
                    disabled={!verified}/>
                  <LoadingButton
                    key='reject'
                    color="error"
                    variant='contained'
                    loading={inProgress}
                    sx={{ fontWeight: 'bold' }}
                    disabled={verified}
                    onClick={handleReject}>
                    Reject
                    </LoadingButton>
                </ButtonGroup>
              </Grid>
            </Grid>
          )}
          <Grid item xs={12} padding>
            <Box sx={{ height: 500 }}>
                <TableVirtuoso
                    data={rowData}
                    components={VirtuosoTableComponents}
                    fixedHeaderContent={fixedHeaderContent}
                    itemContent={rowContent}
                />
            </Box>
          </Grid>
          <RosterMemberModal
            modalOpen={showModal}
            setModalOpen={handleCloseModal}
            userId={memberId} />
          <HistoryModal
            modalOpen={showHistoryModal}
            setModalOpen={handleCloseHistoryModal}
            memberId={memberId} />
          <ConfirmationDialog
            open={openConfirm}
            handleOk={handleConfirm}
            handleCancel={handleCancel}
            title={'Reset Member'}
            message={'This will send the member back to their original state.  If the member is new or transferred in, they will be removed from the list. This update could require other tabs to be reverified. Are you sure you want to reset this member? This action cannot be undone.'}
            confirmText={'Reset Member'}
            cancelText={'Cancel'}/>
          
        </Grid>
  );
}