import React from 'react';
import { TableVirtuoso } from 'react-virtuoso';
import { Card, CardContent, CardHeader, Grid, Paper, Table, TableContainer, Button,
         TableHead, TableRow, TableBody, TableCell, Box } from '@mui/material';
import DangerousIcon from '@mui/icons-material/Dangerous';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';


function DashboardCardTable ({ data, title, irs=false }) {
  const columns = [
    {
      width: 50,
      label: 'Completed',
      dataKey: 'completed',
      skip: true,
    },
    {
      width: 100,
      label: 'Chapter',
      dataKey: 'name',
    },
    {
      width: 125,
      label: irs ? 'State / District' : 'State / Province / District',
      dataKey: 'state',
    },
    {
      width: 200,
      label: 'Postion Assigned',
      dataKey: 'formState',
    },
    {
        width: 200,
        label: 'Assignee',
        dataKey: 'assignee',
    },
  ];
  
  const VirtuosoTableComponents = {
    Scroller: React.forwardRef((props, ref) => (
      <TableContainer component={Paper} {...props} ref={ref} />
    )),
    Table: (props) => (
      <Table size="small" {...props} sx={{ borderCollapse: 'separate', tableLayout: 'fixed' }} />
    ),
    TableHead: React.forwardRef((props, ref) => <TableHead {...props} ref={ref} />),
    TableRow,
    TableBody: React.forwardRef((props, ref) => <TableBody {...props} ref={ref} />),
  };
  
  function fixedHeaderContent() {
    return (
      <TableRow>
        <TableCell
          key={'action'}
          variant="head"
          align={'center'}
          style={{ width: 50 }}
          sx={{
            backgroundColor: 'background.paper',
          }}
        >
        </TableCell>
        {columns.map((column) => (
          <TableCell
            key={column.dataKey}
            variant="head"
            align={column.dataKey === 'action' ? 'center' : 'center'}
            style={{ width: column.width }}
            sx={{
              backgroundColor: 'background.paper',
            }}
          >
            {column.label}
          </TableCell>
        ))}
      </TableRow>
    );
  }
  const rowContent = (_index, row) => {
    return (
        <React.Fragment>
          <TableCell
            key={'action'}
            align={'center'}
            onClick={() => window.location.href = irs ? `/irs/${row.id}` : `/roster/${row.id}`}>
              <Button variant="contained" color="primary" size='small' fullWidth sx={{ fontWeight: 'bold'}}>
                Access
              </Button>
          </TableCell>
          <TableCell
            key={'completed'}
            align={'center'}
            onClick={() => window.location.href = irs ? `/irs/${row.id}` : `/roster/${row.id}`}>
            {row.completed ? <TaskAltIcon color="success"/>
                           : row.assignee_id === localStorage.getItem('user_id') ? <PriorityHighIcon color="#CC7722" />
                                                                                 : <DangerousIcon color="blue"/>}
          </TableCell>
          {columns.filter((col) => col.skip !== true).map((column) => (
            <TableCell
              key={column.dataKey}
              align={'center'}
              onClick={() => window.location.href = irs ? `/irs/${row.id}` : `/roster/${row.id}`}>
              {row[column.dataKey]}
            </TableCell>))}
        </React.Fragment>
      );
  }
  return (
    <Card>
      <CardHeader title={title} sx={{ color: (theme) => theme.palette.primary.main}}/>
      <CardContent>
        <Grid container>
          <Grid item xs={12} textAlign={'center'}>
            <Box sx={{ height: 90, width: 'auto' }}>
                <TableVirtuoso
                    data={data}
                    components={VirtuosoTableComponents}
                    fixedHeaderContent={fixedHeaderContent}
                    itemContent={rowContent}
                />
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
);
}

export default function SPDDashboard ({ data }) {

  return (
    <Grid container>
        <Grid item xs={12} padding={2}>
          <DashboardCardTable data={data.assignedForms} title="Roster and Dues Forms"/>
        </Grid>
        <Grid item xs={12} padding={2}>
          <DashboardCardTable data={data.assignedIrs} title="IRS-LC Form" irs={true}/>
        </Grid>
    </Grid>
  );
}