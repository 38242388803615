import React, {useState} from "react"
import {Grid, TextField, Button, FormControl, InputLabel, Select,
    MenuItem, OutlinedInput, FormHelperText,
    ButtonGroup
} from "@mui/material"


export default function Question({onAddQuestion, onCancel}) {
    const [question, setQuestion] = useState('')
    const [answer, setAnswer] = useState('')
    const [choiceA, setChoiceA] = useState('')
    const [choiceB, setChoiceB] = useState('')
    const [choiceC, setChoiceC] = useState('')
    const [choiceD, setChoiceD] = useState('')
    const [choiceE, setChoiceE] = useState('')
    const [questionError, setQuestionError] = useState(null)
    const [answerError, setAnswerError] = useState(null)

    const handleQuestionChange = (e) => {
        let value = e.target.value
        setQuestion(value)
        if (value.length > 255) {
            setQuestionError("Question must be less than 255 characters")
        } else if (value.length === 0) {
            setQuestionError("Question is required")
        } else {
            setQuestionError(null)
        }
    }

    const handleAnswerChange = (e) => {
        let value = e.target.value
        setAnswer(value)
        if (value === 'a' && choiceA.length === 0) {
            setAnswerError("Answer is not valid")
        } else if (value === 'b' && choiceB.length === 0) {
            setAnswerError("Answer is not valid")
        } else if (value === 'c' && choiceC.length === 0) {
            setAnswerError("Answer is not valid")
        } else if (value === 'd' && choiceD.length === 0) {
            setAnswerError("Answer is not valid")
        } else if (value === 'e' && choiceE.length === 0) {
            setAnswerError("Answer is not valid")
        } else {
            setAnswerError(null)
        }
    }

    const handleChoiceUpdate = (choice, value) => {
        if (answer === choice && value.length === 0) {
            setAnswer('')
        }
        switch (choice) {
            case 'a':
                setChoiceA(value)
                break
            case 'b':
                setChoiceB(value)
                break
            case 'c':
                setChoiceC(value)
                break
            case 'd':
                setChoiceD(value)
                break
            case 'e':
                setChoiceE(value)
                break
            default:
                break
        }
        
    }

    const handleSave = () => {
        if (questionError || answerError) {
            return
        }

        // Save the question
        let questionData = {
            question: question,
            answer: answer,
            order: 0,
            selection_a: choiceA,
            selection_b: choiceB,
            selection_c: choiceC,
            selection_d: choiceD,
            selection_e: choiceE
        }
        onAddQuestion(questionData)
    }

    return (
        <Grid container>
            <Grid item xs={12} md={6} padding>
                <TextField
                    label={'Question'}
                    size={'small'}
                    fullWidth
                    multiline
                    maxRows={4}
                    error={!!questionError}
                    helperText={questionError}
                    onChange={handleQuestionChange}
                    value={question}
                />
            </Grid>
            <Grid item xs={12} md={6} padding>
                {choiceA ? (
                    <FormControl fullWidth={true} variant="outlined" error={answerError} size={'small'}>
                        <InputLabel id="answer">Answer</InputLabel>
                        <Select
                            labelId="answer-label"
                            id="answer-select"
                            size={'small'}
                            fullWidth={true}
                            value={answer}
                            input={<OutlinedInput label='Answer' />}
                            label='Answer'
                            onChange={handleAnswerChange}
                        >
                            {choiceA && <MenuItem value={'a'}>{choiceA}</MenuItem>}
                            {choiceB && <MenuItem value={'b'}>{choiceB}</MenuItem>}
                            {choiceC && <MenuItem value={'c'}>{choiceC}</MenuItem>}
                            {choiceD && <MenuItem value={'d'}>{choiceD}</MenuItem>}
                            {choiceE && <MenuItem value={'e'}>{choiceE}</MenuItem>}
                        </Select>
                        {answerError && <FormHelperText>{"Selected Answer not in options."}</FormHelperText>}
                    </FormControl>
                ) : (
                    null
                )}
            </Grid>
            <Grid item container xs={12}>
                <Grid item xs={12} padding>
                    <TextField
                        label={'Choice A'}
                        size={'small'}
                        fullWidth
                        onChange={(e) => handleChoiceUpdate('a', e.target.value)}
                        value={choiceA}
                    />
                </Grid>
                <Grid item xs={12} padding>
                    <TextField
                        label={'Choice B'}
                        size={'small'}
                        fullWidth
                        onChange={(e) => handleChoiceUpdate('b', e.target.value)}
                        value={choiceB}
                    />
                </Grid>
                <Grid item xs={12} padding>
                    <TextField
                        label={'Choice C'}
                        size={'small'}
                        fullWidth
                        onChange={(e) => handleChoiceUpdate('c', e.target.value)}
                        value={choiceC}
                    />
                </Grid>
                <Grid item xs={12} padding>
                    <TextField
                        label={'Choice D'}
                        size={'small'}
                        fullWidth
                        onChange={(e) => handleChoiceUpdate('d', e.target.value)}
                        value={choiceD}
                    />
                </Grid>
                <Grid item xs={12} padding>
                    <TextField
                        label={'Choice E'}
                        size={'small'}
                        fullWidth
                        onChange={(e) => handleChoiceUpdate('e', e.target.value)}
                        value={choiceE}
                    />
                </Grid>
            </Grid>
            <Grid item xs={12} padding textAlign={'right'}>
                <ButtonGroup>
                    <Button variant="contained" color="warning" onClick={onCancel}>
                        Cancel
                    </Button>
                    <Button variant="contained" color="primary" onClick={handleSave}>
                        Save
                    </Button>
                </ButtonGroup>
            </Grid>
        </Grid>
    )
}