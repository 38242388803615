import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Grid, ButtonGroup, Divider } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import QuestionLine from '../../Components/QuestionLine';
import { irsSubmitAnswers } from '../../endpoints/annualReports';
import { useApiSend } from '../../utils/httpClient';


const sectionQuestions = {
    question1: '580b273874c94b1cadede7996dd142f3',
    question2: '96a8ba2d26cf45599fdf3085917e9112',
    question3: '4c50bf2c5451427e8bc487dafaa70060', // 4c50bf2c5451427e8bc487dafaa70060
    question4: 'ecea95f923634856af8800ac1c026bca', // ecea95f923634856af8800ac1c026bca
    question5: '15440a4fe32b436d8ca8e72a740236e5',
    question6: '40d043d0ea7e4520a745fdbd155ebc5f',
}

export default function Receipts ({questions, refetch, editable, refetchParent, alertOpen, alertMessage,
                                   alertSeverity, userEditing, updateUserEditing}) {
    let { id } = useParams();
    const formValid = useRef({
        question1: true, question2: true, question3: true, question4: true,
        question5: true, question6: true,
    });
    const [question1, setQuestion1] = useState(null);
    const [question1Error, setQuestion1Error] = useState(null);
    const [question2, setQuestion2] = useState(null);
    const [question2Error, setQuestion2Error] = useState(null);
    const [question3, setQuestion3] = useState(null);
    const [question4, setQuestion4] = useState(null);
    const [question5, setQuestion5] = useState(null);
    const [question5Error, setQuestion5Error] = useState(null);
    const [question6, setQuestion6] = useState(null);
    const [question6Error, setQuestion6Error] = useState(null);
    const [answersSet, setAnswersSet] = useState(false);

    const handleScrollToTop = () => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
      };

    const { mutate } = useApiSend(
        irsSubmitAnswers,
        (data) => {
            handleScrollToTop()
            alertMessage("Receipts successfully updated.")
            alertSeverity("success")
            alertOpen()
            setTimeout(() => {
                refetch()
                refetchParent()
            }, 1000)
        },
        (error) => {
            console.log(error)
            alertMessage("Error updating Receipts. If the problem persists, please contact support.")
            alertSeverity("error")
            alertOpen()
        }
    )

    const setAnswers = useCallback(() => {
        questions.forEach((answers) => {
            if (answers.question_id === sectionQuestions.question1) {
                setQuestion1(answers.answer)
            } else if (answers.question_id === sectionQuestions.question2) {
                setQuestion2(answers.answer)
            } else if (answers.question_id === sectionQuestions.question3) {
                setQuestion3(answers.answer)
            } else if (answers.question_id === sectionQuestions.question4) {
                setQuestion4(answers.answer)
            } else if (answers.question_id === sectionQuestions.question5) {
                setQuestion5(answers.answer)
            } else if (answers.question_id === sectionQuestions.question6) {
                setQuestion6(answers.answer)
            }
        })
    }, [questions])

    useEffect(() => {
        setAnswers()
        setAnswersSet(true)
    }, [questions, setAnswers])

    const answersUpdated = useCallback(() => {
        let answersUpdate = false
        if (question1 !== null && question1 !== questions.filter(q => q.question_id === sectionQuestions.question1)[0]?.answer) {
            answersUpdate = true
        } else if (question2 !== null && question2 !== questions.filter(q => q.question_id === sectionQuestions.question2)[0]?.answer) {
            answersUpdate = true
        } else if (question3 !== null && question3 !== questions.filter(q => q.question_id === sectionQuestions.question3)[0]?.answer) {
            answersUpdate = true
        } else if (question4 !== null && question4 !== questions.filter(q => q.question_id === sectionQuestions.question4)[0]?.answer) {
            answersUpdate = true
        } else if (question5 !== null && question5 !== questions.filter(q => q.question_id === sectionQuestions.question5)[0]?.answer) {
            answersUpdate = true
        } else if (question6 !== null && question6 !== questions.filter(q => q.question_id === sectionQuestions.question6)[0]?.answer) {
            answersUpdate = true
        }
        return answersUpdate
    }, [question1, question2, question3, question4, question5, question6, questions])

    useEffect(() => {
        if (answersUpdated() && answersSet && !userEditing) {
            updateUserEditing(true)
        }
    }, [answersUpdated, updateUserEditing, answersSet, userEditing])

    const handleQuestion2Change = (value) => {
        if (!value) {
            setQuestion2(null)
            setQuestion2Error('Please enter a description')
            return
        }
        setQuestion2(value)
        setQuestion2Error(null)
    }

    const handleQuestion1Change = (value) => {
        setQuestion1(value)
        if (value !== null && value !== '1') {
            setQuestion1Error(null)
        } else {
            setQuestion1Error('Please select an answer')
        }
    }

    const handleQuestion5Change = (value) => {
        setQuestion5(value)
        if (value !== null && value !== '1') {
            setQuestion5Error(null)
        } else {
            setQuestion5Error('Please select an answer')
        }
    }

    const handleQuestion6Change = (value) => {
        setQuestion6(value)
        if (value !== null && value !== '1') {
            setQuestion6Error(null)
        } else {
            setQuestion6Error('Please select an answer')
        }
    }

    const onSubmitForm = (completed) => {
        let formValidCopy = { ...formValid.current }
        let validForm = true
        if (!formValidCopy.question1 || question1 === null || question1 === '1') {
            validForm = false
            setQuestion1Error('Please select an answer')
        }
        if (question1) {
            if (!question2 || !formValidCopy.question2) {
                validForm = false
                setQuestion2Error('Please enter a description')
            }
            if (!formValidCopy.question3) {
                validForm = false
            }
            if (!formValidCopy.question4) {
                validForm = false
            }
            if (!formValidCopy.question5 || question5 === null || question5 === '1') {
                validForm = false
                setQuestion5Error('Please select an answer')
            }
            if (!formValidCopy.question6 || question6 === null || question6 === '1') {
                validForm = false
                setQuestion6Error('Please select an answer')
            }
        }

        if (!validForm) {
            console.log('Form is not valid')
            return
        }
        let answers = [
            {
                question_id: questions.filter(q => q.question_id === sectionQuestions.question1)[0]?.id,
                answer: question1 !== null ? question1.toString() : question1,
            },
            {
                question_id: questions.filter(q => q.question_id === sectionQuestions.question2)[0]?.id,
                answer: question2 !== null ? question2.toString() : question2,
            },
            {
                question_id: questions.filter(q => q.question_id === sectionQuestions.question3)[0]?.id,
                answer: question3 !== null ? question3.toString() : question3,
            },
            {
                question_id: questions.filter(q => q.question_id === sectionQuestions.question4)[0]?.id,
                answer: question4 !== null ? question4.toString() : question4,
            },
            {
                question_id: questions.filter(q => q.question_id === sectionQuestions.question5)[0]?.id,
                answer: question5 !== null ? question5.toString() : question5,
            },
            {
                question_id: questions.filter(q => q.question_id === sectionQuestions.question6)[0]?.id,
                answer: question6 !== null ? question6.toString() : question6,
            },
        ]
        mutate({
            id: id,
            answers: answers,
            section_completed: completed,
            section: 'goods_sold',
        })
    }

    return (
        <Grid container>
            {questions.filter(q => q.question_id === sectionQuestions.question1).map((question) => (
                <QuestionLine
                    key={1}
                    questionNumber={1}
                    question={question.question}
                    questionId={question.question_id}
                    answerType={question.answer_type}
                    disabled={!editable}
                    answer={question1}
                    onChange={(value) => handleQuestion1Change(value)}
                    errorOverride={question1Error}
                    onFormChange={(isValid) => formValid.current.question1 = isValid}
                />))}
            {question1 === true && 
                <Grid container item>
                    {questions.filter(q => q.question_id === sectionQuestions.question2).map((question) => (
                        <QuestionLine
                            key={2}
                            questionNumber={2}
                            question={question.question}
                            questionId={question.question_id}
                            answerType={question.answer_type}
                            errorOverride={question2Error}
                            disabled={!editable}
                            answer={question2}
                            onChange={(value) => handleQuestion2Change(value)}
                            onFormChange={(isValid) => formValid.current.question2 = isValid}
                        />))}
                    {questions.filter(q => q.question_id === sectionQuestions.question3).map((question) => (
                        <QuestionLine
                            key={5}
                            questionNumber={3}
                            question={question.question}
                            questionId={question.question_id}
                            answerType={question.answer_type}
                            disabled={!editable}
                            answer={question3}
                            onChange={(value) => setQuestion3(value)}
                            onFormChange={(isValid) => formValid.current.question3 = isValid}
                        />))}
                    {questions.filter(q => q.question_id === sectionQuestions.question4).map((question) => (
                        <QuestionLine
                            key={6}
                            questionNumber={4}
                            question={question.question}
                            questionId={question.question_id}
                            answerType={question.answer_type}
                            disabled={!editable}
                            answer={question4}
                            onChange={(value) => setQuestion4(value)}
                            onFormChange={(isValid) => formValid.current.question4 = isValid}
                        />))}
                    {questions.filter(q => q.question_id === sectionQuestions.question5).map((question) => (
                        <QuestionLine
                            key={7}
                            questionNumber={5}
                            question={question.question}
                            questionId={question.question_id}
                            answerType={question.answer_type}
                            disabled={!editable}
                            answer={question5}
                            errorOverride={question5Error}
                            onChange={(value) => handleQuestion5Change(value)}
                            onFormChange={(isValid) => formValid.current.question5 = isValid}
                        />))}
                    {questions.filter(q => q.question_id === sectionQuestions.question6).map((question) => (
                        <QuestionLine
                            key={8}
                            questionNumber={6}
                            question={question.question}
                            questionId={question.question_id}
                            answerType={question.answer_type}
                            disabled={!editable}
                            answer={question6}
                            errorOverride={question6Error}
                            onChange={(value) => handleQuestion6Change(value)}
                            onFormChange={(isValid) => formValid.current.question6 = isValid}
                        />))}
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>
                </Grid>}
            <Grid item xs={12} padding={2}>
                <Divider />
            </Grid>
            <Grid item xs={12} textAlign={'right'} paddingBottom={2}>
                <ButtonGroup>
                    <LoadingButton
                        color="green"
                        size='small'
                        variant='contained'
                        loading={false}
                        sx={{ fontWeight: 'bold' }}
                        disabled={!editable}
                        onClick={() => onSubmitForm(true)}
                    >
                        Save and Complete Goods Sold
                    </LoadingButton>
                </ButtonGroup>
            </Grid>
        </Grid>
    );
}