import React from 'react'
import { Select, Autocomplete, TextField, Skeleton } from '@mui/material'
import { useApiGet } from '../utils/httpClient'
import { getTrainingTopics } from '../endpoints/training'


export default function TrainingTopicSelector ({value, handleValueChange, label="Topic"}) {
    const { 
        data
      } = useApiGet(
        ['training-topics'],
        getTrainingTopics,
        {
          enabled: true,
          retry: 1,
        },
    )

    const onValueChange = (newValue) => {
        handleValueChange(newValue)
    }
  
    return (
        data ? (
            <Autocomplete
                multiple
                id="tags-standard"
                options={data}
                variant='standard'
                size='small'
                getOptionLabel={(option) => option.name}
                value={value}
                onChange={(event, newValue) => onValueChange(newValue)}
                renderInput={(params) => (
                <TextField
                    {...params}
                    variant="standard"
                    label={label}
                />
                )}
            />

        ) : (
            <Skeleton>
                <Select label="Step" />
            </Skeleton>
        )
    )
}