import React, {useState} from 'react';

import {useStripe, useElements, PaymentElement} from '@stripe/react-stripe-js';
import { Button, Typography, Grid } from '@mui/material';

const CheckoutForm = ({donationId, goBack}) => {
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);

  
  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    const currentOrigin = window.location.origin;

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setLoading(true);

    const { error } = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: currentOrigin + `/donations?step=3&donationId=${donationId}`,
      },
    });

    // Should only reach here if there is an error

    if (error.type === 'card_error' || error.type === 'validation_error') {
      // Show error to your customer (for example, payment details incomplete)
      setMessage(error.message);
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
      setMessage("An unexpected error occurred. Please try again.");
    }
    setLoading(false);
  };

  return (
    <Grid container padding={5}>
      <Grid item xs={12}>
        <Typography variant='h4' textAlign={'center'}>
          Credit Card Information
        </Typography>
      </Grid>
      <Grid item xs={12}>
      <form onSubmit={handleSubmit}>
        <Grid container item xs={12} padding={3}>
        <Grid item xs={12} textAlign={'center'}>
          <PaymentElement />
        </Grid>
        <Grid item xs={12} textAlign={'center'} padding paddingTop={5}>
          <Button onClick={goBack} varient='text'>
            Back
          </Button>
          <Button type="submit" disabled={!stripe || loading || !elements} variant='contained'>
            Donate Now
          </Button>
          {message && (<Typography variant='body1' color='red'>
            {message}
          </Typography>)}
          </Grid>
          </Grid>
        </form>
      </Grid>
    </Grid>
  )
};

export default CheckoutForm;