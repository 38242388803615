import React, {useEffect} from 'react';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Stack from '@mui/material/Stack';


function ErrorAlert({description, onClose}) {
    return (
        <Alert severity="error" onClose={onClose} variant='filled'>
            <AlertTitle>Error</AlertTitle>
            {description}
        </Alert>
    );
}

function WarningAlert({description, onClose}) {
    return (
        <Alert severity="warning" onClose={onClose} variant='filled'>
            <AlertTitle>Warning</AlertTitle>
            {description}
        </Alert>
    );
}

function SuccessAlert({description, onClose}) {
    return (
        <Alert severity="success" onClose={onClose} variant='filled'>
            <AlertTitle>Success</AlertTitle>
            {description}
        </Alert>
    );
}

export default function AlertItem({severity, description, onClose, keepOpen=false}) {

    useEffect(() => {
        if (severity === 'error') return

        if (keepOpen) return

        const timer = setTimeout(() => {
            onClose();
        }, 2000);
        return () => clearTimeout(timer);
    }, [onClose, severity, keepOpen]);

    const isError = severity === 'error';
    const isWarning = severity === 'warning';
    const isSuccess = severity === 'success';
    return (
        <Stack spacing={2} sx={{ width: '100%' }}>
            {isError && <ErrorAlert description={description} onClose={onClose} />}
            {isWarning && <WarningAlert description={description} onClose={onClose} />}
            {isSuccess && <SuccessAlert description={description} onClose={onClose} />}
        </Stack>
    );
}