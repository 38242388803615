import React from 'react'
import { styled } from '@mui/material/styles';
import { Button } from '@mui/material'
import CloudUploadIcon from '@mui/icons-material/CloudUpload';


const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });


export default function ImageSelectorButton({ label, handleFileUpload, editable=true, fullWidth=false }) {

    return (
        <Button
            size='small'
            disabled={!editable}
            component="label"
            role={undefined}
            variant="contained"
            tabIndex={-1}
            fullWidth={fullWidth}
            onChange={handleFileUpload}
            startIcon={<CloudUploadIcon />}
        >
            {label}
            <VisuallyHiddenInput type="file" />
        </Button>
    )
}