import React, {useState, useEffect} from 'react';
import { Grid, TextField, Divider, Button } from '@mui/material';
import StateSelector from '../../Components/StateSelector';
import CountrySelector from '../../Components/CountrySelector';
import ValidatedTextField from '../../Components/ValidatedTextField';
import { emailValidator } from '../../utils/validators';
import { createDonation } from '../../endpoints/donations';
import { getPostalInfo } from '../../endpoints/lookups';
import { useApiSend, useApiGet } from '../../utils/httpClient';


export default function DonationPI({handleNext, setDonationId, refetch, donationObject, donationId}) {

    const [firstName, setFirstName] = useState('')
    const [firstNameError, setFirstNameError] = useState(false)
    const [lastName, setLastName] = useState('')
    const [lastNameError, setLastNameError] = useState(false)
    const [email, setEmail] = useState('')
    const [emailError, setEmailError] = useState(false)
    const [chapter, setChapter] = useState('')
    const [countryId, setCountryId] = useState('')
    const [countryIdError, setCountryIdError] = useState(false)
    const [stateRequired, setStateRequired] = useState(false)
    const [stateId, setStateId] = useState('')
    const [stateIdError, setStateIdError] = useState(false)
    const [city, setCity] = useState('')
    const [cityError, setCityError] = useState(false)
    const [address, setAddress] = useState('')
    const [addressError, setAddressError] = useState(false)
    const [postalCode, setPostalCode] = useState('')
    const [postalCodeError, setPostalCodeError] = useState(false)
    const [address2, setAddress2] = useState('')
    const [delayPostalCode, setDelayPostalCode] = useState('')
    const [getPostalInfoActive, setGetPostalInfoActive] = useState(false)
    const [loadedFromDonation, setLoadedFromDonation] = useState(false)

    const { mutate } = useApiSend(
        createDonation,
        (data) => {
            setDonationId(data.id)
            refetch()
            handleNext()
        },
        (error) => {
            console.error(error)
        },
    )

    const { data: postalInfo } = useApiGet(
        ['postal-info', postalCode, countryId],
        getPostalInfo,
        {
            enabled: getPostalInfoActive,
        },
        { postalCode, countryId }
    )

    useEffect(() => {
        if (donationObject) {
            setLoadedFromDonation(true)
            setFirstName(donationObject.first_name)
            setLastName(donationObject.last_name)
            setEmail(donationObject.email)
            setChapter(donationObject.chapter)
            setCountryId(donationObject.country_id)
            setStateId(donationObject.state_id)
            setCity(donationObject.city)
            setAddress(donationObject.address1)
            setAddress2(donationObject.address2)
            setPostalCode(donationObject.postal_code)
            setDelayPostalCode(donationObject.postal_code)
        }
    }, [donationObject])

    useEffect(() => {
        const timeoutId = setTimeout(() => {
          setPostalCode(delayPostalCode)
        }, 500)
        return () => clearTimeout(timeoutId)
      }, [delayPostalCode])

    useEffect(() => {
        if (postalCode.length > 4 && countryId.length > 0  && !loadedFromDonation) {
            setGetPostalInfoActive(true)
        } else {
            setGetPostalInfoActive(false)
        }
    }, [postalCode, countryId, loadedFromDonation])

    useEffect(() => {
        if (postalInfo) {
            if (postalInfo.city) {
                setCity(postalInfo.city)
            }
            if (postalInfo.state_id) {
                setStateId(postalInfo.state_id)
            }
        }
    }, [postalInfo])
    
    const handleEmailChange = (valid, value) => {
        if (valid) {
            setEmailError(false)
        } else {
            setEmailError(true)
        }
        setEmail(value)
    }

    const handleFirstNameChange = (event) => {
        let value = event.target.value
        setFirstName(value)
        if (value.length > 0) {
            setFirstNameError(false)
        }
    }

    const handleLastNameChange = (event) => {
        let value = event.target.value
        setLastName(value)
        if (value.length > 0) {
            setLastNameError(false)
        }
    }

    const handleCountryChange = (value) => {
        setLoadedFromDonation(false)
        if (value) {
            if (value.id !== countryId) {
                setStateId('')
            }
            setCountryId(value.id)
            setStateRequired(value.has_states)
            setCountryIdError(false)
        } else {
            setCountryId('')
        }
    }

    const handleStateChange = (value) => {
        if (value) {
            setStateId(value.id)
            setStateIdError(false)
        } else {
            setStateId('')
        }
    }

    const handlePostalCodeChange = (event) => {
        setLoadedFromDonation(false)
        let value = event.target.value
        setDelayPostalCode(value)
        if (value.length > 0) {
            setPostalCodeError(false)
        }
    }

    const handleAddressChange = (event) => {
        let value = event.target.value
        setAddress(value)
        if (value.length > 0) {
            setAddressError(false)
        }
    }
    
    const handleCityChange = (event) => {
        let value = event.target.value
        setCity(value)
        if (value.length > 0) {
            setCityError(false)
        }
    }

    const handleMovingNext = () => {
        let valid = true
        if (firstName.length === 0) {
            setFirstNameError(true)
            valid = false
        }
        if (lastName.length === 0) {
            setLastNameError(true)
            valid = false
        }
        if (email.length === 0 || emailError) {
            setEmailError(true)
            valid = false
        }
        if (countryId.length === 0) {
            setCountryIdError(true)
            valid = false
        }
        if (stateRequired && stateId.length === 0) {
            setStateIdError(true)
            valid = false
        }
        if (city.length === 0) {
            setCityError(true)
            valid = false
        }
        if (address.length === 0) {
            setAddressError(true)
            valid = false
        }
        if (postalCode.length === 0) {
            setPostalCodeError(true)
            valid = false
        }
        if (!valid) {
            console.log('Not valid')
            return
        }
        let data = {
            first_name: firstName,
            last_name: lastName,
            email: email,
            chapter: chapter,
            country_id: countryId,
            state_id: stateId,
            city: city,
            address1: address,
            address2: address2,
            postal_code: postalCode,
            donation_id: donationId,
        }
        mutate(data)
    }
    
    return (
        <Grid container>
            <Grid item xs={12} paddingTop={5}/>
            <Grid item xs={6} paddingRight={1} paddingBottom={2}>
                <TextField
                    variant="outlined"
                    required
                    fullWidth
                    id="first-name"
                    label="First Name"
                    error={firstNameError}
                    helperText={firstNameError ? 'First Name is required' : ''}
                    autoFocus
                    value={firstName}
                    onChange={handleFirstNameChange}
                />
            </Grid>
            <Grid item xs={6} paddingLeft={1} paddingBottom={2}>
                <TextField
                    variant="outlined"
                    required
                    fullWidth
                    id="last-name"
                    label="Last Name"
                    value={lastName}
                    error={lastNameError}
                    helperText={lastNameError ? 'Last Name is required' : ''}
                    onChange={handleLastNameChange}
                />
            </Grid>
            <Grid item xs={6} paddingRight={1} paddingBottom={2}>
                <ValidatedTextField
                    label={'Email'}
                    size='medium'
                    validator={emailValidator}
                    onChange={(valid, value) => handleEmailChange(valid, value)}
                    errorOverride={emailError ? 'Email is required' : ''}
                    valueRequired={true}
                    value={email}/>
            </Grid>
            <Grid item xs={6} paddingLeft={1} paddingBottom={2}>
                <TextField
                    variant="outlined"
                    fullWidth
                    id="chapter"
                    label="P.E.O. Chapter (Chapter\SPD)"
                    value={chapter}
                    onChange={(e) => setChapter(e.target.value)}
                />
            </Grid>
            <Grid item xs={12} padding>
                <Divider />
            </Grid>
            <Grid item xs={12} paddingTop={2}/>
            <Grid item xs={6} paddingRight={1} paddingBottom={2}>
                <CountrySelector
                    value={countryId}
                    handleChange={handleCountryChange}
                    error={countryIdError}
                    errorMessage={'Country is required'}
                    size='medium'/>
            </Grid>
            <Grid item xs={6} paddingLeft={1} paddingBottom={2}>
                <TextField
                    variant="outlined"
                    required
                    fullWidth
                    id="past-code"
                    label="Postal Code"
                    value={delayPostalCode}
                    error={postalCodeError}
                    helperText={postalCodeError ? 'Postal Code is required' : ''}
                    onChange={handlePostalCodeChange}
                />
            </Grid>
            <Grid item xs={6} paddingRight={1} paddingBottom={2}>
                <TextField
                    variant="outlined"
                    required
                    fullWidth
                    id="address-1"
                    label="Address 1"
                    value={address}
                    error={addressError}
                    helperText={addressError ? 'Address is required' : ''}
                    onChange={handleAddressChange}
                />
            </Grid>
            <Grid item xs={6} paddingLeft={1} paddingBottom={2}>
                <TextField
                    variant="outlined"
                    fullWidth
                    id="address-2"
                    label="Address 2"
                    value={address2}
                    onChange={(e) => setAddress2(e.target.value)}
                />
            </Grid>
            <Grid item xs={6} paddingRight={1} paddingBottom={2}>
                <TextField
                    variant="outlined"
                    required
                    fullWidth
                    id="city"
                    label="City"
                    value={city}
                    error={cityError}
                    helperText={cityError ? 'City is required' : ''}
                    onChange={handleCityChange}
                />
            </Grid>
            <Grid item xs={6} paddingLeft={1} paddingBottom={2}>
                <StateSelector
                    value={stateId}
                    countryId={countryId}
                    handleChange={handleStateChange}
                    error={stateIdError}
                    errorMessage={'State is required'}
                    size='medium'/>
            </Grid>
            <Grid item xs={12} padding textAlign={'center'}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleMovingNext()}
                    sx={{ fontWeight: 'bold' }}
                >
                    {'Next'}
                </Button>
            </Grid>
        </Grid>
    )
}