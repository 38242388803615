import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Grid, ButtonGroup, Divider, Typography } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import QuestionLine from '../../Components/QuestionLine';
import { irsSubmitAnswers } from '../../endpoints/annualReports';
import { useApiSend } from '../../utils/httpClient';
import { formatCurrency } from '../../utils/formatters';
import { assetQuestions } from '../../utils/variables';

export default function Assets ({questions, refetch, editable, alertOpen, alertMessage,
                                 alertSeverity, userEditing, updateUserEditing}) {
    const { id } = useParams();
    const formValid = useRef({
        question1: true, question3: true, question4: true,
        question5: true, question6: true, question7: true,
    });
    const [question1, setQuestion1] = useState(null);
    const [question3, setQuestion3] = useState(null);
    const [question4, setQuestion4] = useState(null);
    const [question5, setQuestion5] = useState(null);
    const [question6, setQuestion6] = useState(null);
    const [question7, setQuestion7] = useState(null);
    const [answersSet, setAnswersSet] = useState(false);


    const setAnswers = useCallback(() => {
        questions.forEach((answers) => {
            if (answers.question_id === assetQuestions.question1) {
                setQuestion1(answers.answer)
            } else if (answers.question_id === assetQuestions.question3) {
                setQuestion3(answers.answer)
            } else if (answers.question_id === assetQuestions.question4) {
                setQuestion4(answers.answer)
            } else if (answers.question_id === assetQuestions.question5) {
                setQuestion5(answers.answer)
            } else if (answers.question_id === assetQuestions.question6) {
                setQuestion6(answers.answer)
            } else if (answers.question_id === assetQuestions.question7) {
                setQuestion7(answers.answer)
            }
        })
    }, [questions])

    useEffect(() => {
        setAnswers()
        setAnswersSet(true)
    }, [questions, setAnswers])

    const answersUpdated = useCallback(() => {
        let answersUpdate = false
        questions.forEach((answers) => {
            if (answers.question_id === assetQuestions.question1) {
                if (question1 !== answers.answer) {
                    answersUpdate = true
                }
            }
            if (answers.question_id === assetQuestions.question3) {
                if (question3 !== answers.answer) {
                    answersUpdate = true
                }
            }
            if (answers.question_id === assetQuestions.question4) {
                if (question4 !== answers.answer) {
                    answersUpdate = true
                }
            }
            if (answers.question_id === assetQuestions.question5) {
                if (question5 !== answers.answer) {
                    answersUpdate = true
                }
            }
            if (answers.question_id === assetQuestions.question6) {
                if (question6 !== answers.answer) {
                    answersUpdate = true
                }
            }
            if (answers.question_id === assetQuestions.question7) {
                if (question7 !== answers.answer) {
                    answersUpdate = true
                }
            }
        })
        return answersUpdate
    }, [question1, question3, question4, question5, question6, question7, questions])

    useEffect(() => {
        if (answersUpdated() && answersSet && !userEditing) {
            updateUserEditing(true)
        }
    }, [answersUpdated, updateUserEditing, answersSet, userEditing])

    const handleScrollToTop = () => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
      };

    const { mutate } = useApiSend(
        irsSubmitAnswers,
        (data) => {
          handleScrollToTop()
          alertMessage("Assets successfully updated.")
          alertSeverity("success")
          alertOpen()
          refetch()
        },
        (error) => {
          console.log(error)
            alertMessage("Error updating Assets. If the problem persists, please contact support.")
            alertSeverity("error")
            alertOpen()
        }
    )

    const onSubmitForm = (completed) => {
        let formValidCopy = { ...formValid.current }
        let validForm = true
        if (!formValidCopy.question1) {
            validForm = false
        }
        if (!formValidCopy.question3) {
            validForm = false
        }
        if (!formValidCopy.question4) {
            validForm = false
        }
        if (!formValidCopy.question5) {
            validForm = false
        }
        if (!formValidCopy.question6) {
            validForm = false
        }
        if (!formValidCopy.question7) {
            validForm = false
        }

        if (!validForm) {
            console.log("Form is not valid")
            return
        }
        let answers = [
            {
                question_id: questions.filter(q => q.question_id === assetQuestions.question1)[0]?.id,
                answer: question1 ? question1.toString() : null,
            },
            {
                question_id: questions.filter(q => q.question_id === assetQuestions.question3)[0]?.id,
                answer: question3 ? question3.toString() : null,
            },
            {
                question_id: questions.filter(q => q.question_id === assetQuestions.question4)[0]?.id,
                answer: question4 ? question4.toString() : null,
            },
            {
                question_id: questions.filter(q => q.question_id === assetQuestions.question5)[0]?.id,
                answer: question5 ? question5.toString() : null,
            },
            {
                question_id: questions.filter(q => q.question_id === assetQuestions.question6)[0]?.id,
                answer: question6 ? question6.toString() : null,
            },
            {
                question_id: questions.filter(q => q.question_id === assetQuestions.question7)[0]?.id,
                answer: question7 ? question7.toString() : null,
            },
        ]
        mutate({
            id: id,
            answers: answers,
            section_completed: completed,
            section: 'assets',
        })
    }

    return (
        <Grid container>
            <Grid item xs={12} paddingBottom={2} display={'flex'} justifyContent={'space-between'}>
                <Typography variant="h6" fontStyle={'italic'}>
                    Chapter Assets, end of fiscal year
                </Typography>
            </Grid>
            {questions.filter(q => q.question_id === assetQuestions.question1).map((question) => (
                <QuestionLine
                    key={1}
                    questionNumber={1}
                    questionId={question.question_id}
                    question={question.question}
                    answerType={question.answer_type}
                    disabled={!editable}
                    answer={question1}
                    onChange={(value) => setQuestion1(value)}
                    onFormChange={(isValid) => formValid.current.question1 = isValid}
                />))}
            {questions.filter(q => q.question_id === assetQuestions.question3).map((question) => (
                <QuestionLine
                    key={5}
                    questionNumber={2}
                    questionId={question.question_id}
                    question={question.question}
                    answerType={question.answer_type}
                    disabled={!editable}
                    answer={question3}
                    onChange={(value) => setQuestion3(value)}
                    onFormChange={(isValid) => formValid.current.question3 = isValid}
                />))}
            {questions.filter(q => q.question_id === assetQuestions.question4).map((question) => (
                <QuestionLine
                    key={6}
                    questionNumber={3}
                    question={question.question}
                    questionId={question.question_id}
                    answerType={question.answer_type}
                    disabled={!editable}
                    answer={question4}
                    onChange={(value) => setQuestion4(value)}
                    onFormChange={(isValid) => formValid.current.question4 = isValid}
                />))}
            {questions.filter(q => q.question_id === assetQuestions.question5).map((question) => (
                <QuestionLine
                    key={7}
                    questionNumber={4}
                    question={question.question}
                    questionId={question.question_id}
                    answerType={question.answer_type}
                    disabled={!editable}
                    answer={question5}
                    onChange={(value) => setQuestion5(value)}
                    onFormChange={(isValid) => formValid.current.question5 = isValid}
                />))}
            {questions.filter(q => q.question_id === assetQuestions.question6).map((question) => (
                <QuestionLine
                    key={8}
                    questionNumber={5}
                    question={question.question}
                    questionId={question.question_id}
                    answerType={question.answer_type}
                    disabled={!editable}
                    answer={question6}
                    onChange={(value) => setQuestion6(value)}
                    onFormChange={(isValid) => formValid.current.question6 = isValid}
                />))}
            {questions.filter(q => q.question_id === assetQuestions.question7).map((question) => (
                <QuestionLine
                    key={9}
                    questionNumber={6}
                    question={question.question}
                    questionId={question.question_id}
                    answerType={question.answer_type}
                    disabled={!editable}
                    answer={question7}
                    onChange={(value) => setQuestion7(value)}
                    onFormChange={(isValid) => formValid.current.question7 = isValid}
                />))}
            <Grid item xs={12} padding={2}>
                <Divider />
            </Grid>
            <Grid item xs={12}>
                <Typography variant="body1" fontWeight={'bold'} textAlign={'right'}>
                    Total Assets: {
                      formatCurrency(
                        parseFloat(question1 || 0) +
                        parseFloat(question3 || 0) +
                        parseFloat(question4 || 0) +
                        parseFloat(question5 || 0) +
                        parseFloat(question6 || 0) +
                        parseFloat(question7 || 0)
                    )}
                </Typography>
            </Grid>
            <Grid item xs={12} padding={2}>
                <Divider />
            </Grid>
            <Grid item xs={12} padding={2} textAlign={"right"}>
                <ButtonGroup>
                    <LoadingButton
                        color="green"
                        size='small'
                        variant='contained'
                        loading={false}
                        sx={{ fontWeight: 'bold' }}
                        disabled={!editable}
                        onClick={() => onSubmitForm(true)}
                    >
                        Save and Complete Assets
                    </LoadingButton>
                </ButtonGroup>
            </Grid>


        </Grid>
    );
}