import React, {useCallback, useEffect, useState} from 'react';
import { useParams } from 'react-router-dom';

import { Button, Grid, Typography, Checkbox, TextField, ButtonGroup, Tooltip,
  FormControl, FormHelperText,
  FormControlLabel
 } from '@mui/material';

import { useApiGet, useApiSend, documentClient } from '../../utils/httpClient';
import { getRosterDues } from '../../endpoints/annualReports';
import { approveDuesForm, rejectDuesForm } from '../../endpoints/annualReports';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import dayjs from 'dayjs';
import AlertItem from '../../Components/AlertItem';
import { formStates } from '../../utils/variables';
import { formatCurrency } from '../../utils/formatters';
import TooltipWrapper from '../../Components/TooltipWrapper';
import { LoadingButton } from '@mui/lab';


function PersonsTooltop ({ persons }) {
  const tab = (status) => {
    switch (status) {
      case 'Active':
        return 'Active Members';
      case 'No Processing':
        return 'No Longer In Chapter';
      case 'Inactive':
        return 'Inactive Members';
      case 'Other':
        return 'No Longer In Chapter';
      default:
        return 'Unknown';
    }
  }
  return (
    <React.Fragment>
      {persons.map((person, index) => (
        <Typography variant="body2" key={index}>
          {person.first_name} {person.last_name} - {tab(person.status)}
        </Typography>
      ))}
    </React.Fragment>
  );
}


function DuesRow ({ id, description, descriptionWeight="normal", amount='',
                    amountPrefix="", paddingBottom=false, descriptionPrefix="",
                    removeDescriptionPrefix=false, error=false, tooltipData=null,
                    toolTipButton=null }) {
  return (
    <Grid container paddingBottom={paddingBottom ? 2 : 0} id={id}>
      {removeDescriptionPrefix ? null : (
      <Grid item xs={1}>
        <Typography variant="body1" fontWeight={descriptionWeight}>
          {descriptionPrefix}
        </Typography>
      </Grid>)}
      <Grid item xs={removeDescriptionPrefix ? 9 : 8} paddingTop={removeDescriptionPrefix ? 1: 0}>
        <Typography variant="body1" fontWeight={descriptionWeight} color={error ? 'primary' : 'default'}>
          {description}
          {tooltipData && tooltipData.length > 0 && (
            <Tooltip title={<PersonsTooltop persons={tooltipData}/>}>
              <InfoOutlinedIcon color="primary" fontSize='small' sx={{ paddingLeft: 2 }}/>
            </Tooltip>)}
          {toolTipButton}
        </Typography>
        
      </Grid>
      {amount != null &&
      <Grid item xs={3} textAlign={'right'}>
        {removeDescriptionPrefix ? amount : (
        <Typography variant="body1">
          {amountPrefix === '$' ? formatCurrency(amount || 0) : `${amountPrefix}${amount}`}
        </Typography>)}
        {error && (
          <Typography variant="caption" sx={{ color: (theme) => theme.palette.error.main}}>
            Checking is required.
          </Typography>)}
      </Grid>}
    </Grid>
  );
}

export default function Dues ({ activeMembers, editable, refetchParent, totalInitiated, totalReinstated,
                                spdApprovable, formStateId, alertOpen, alertMessage: parentMessage,
                                alertSeverity: parentSeverity, setFormSubmitOpen, setParentKeepOpen,
                                setFormSubmitMessage, irsForm }) {
  const [total, setTotal] = useState(0);
  const [checked, setChecked] = useState(false);
  const [notes, setNotes] = useState('');
  const [notesError, setNotesError] = useState(false);
  const [notesErrorDescrption, setNotesErrorDescrption] = useState('');
  const [name, setName] = useState('');
  const [nameError, setNameError] = useState(false);
  const [checkboxError, setCheckboxError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertSeverity, setAlertSeverity] = useState('');
  const [documentLoading, setDocumentLoading] = useState(false);
  let { id } = useParams();

  const { data: duesFees, isFetching, refetch } = useApiGet(
    ['roster-dues', id],
    getRosterDues,
    {
      enabled: true,
      retry: 1,
    },
    { id }
  )

  const getSuccessMessage = () => {
    if (formStateId === formStates.LCTreas) {
      return "Thank you! Submitting your Roster and Dues Form was successful. You may return to the dashboard to start on your IRS-LC Form."
    } else {
      return "Thank you! Submitting your Roster and Dues Form was successful. You may return to the dashboard to check if your IRS-LC Form is ready for your review."
    }
  }

  const { mutate: approveForm } = useApiSend(
    approveDuesForm,
    (data) => {
        handleScrollToTop()
        setFormSubmitMessage("Thank you! Submitting your Roster and Dues Form was successful.")
        setParentKeepOpen(true)
        setFormSubmitOpen(true)
        parentMessage(getSuccessMessage())
        parentSeverity("success")
        alertOpen(true)
        resetState()
        refetchParent()
        refetch()
    },
    (error) => {
      setIsLoading(false)
      console.log(error)
      setAlertMessage("Error approving roster. If the problem persists, please contact support.")
      setAlertSeverity("error")
      setShowAlert(true)
    },
  )

  const { mutate: rejectForm } = useApiSend(
    rejectDuesForm,
    (data) => {
        handleScrollToTop()
        setFormSubmitMessage("The form has been rejected.")
        setParentKeepOpen(false)
        setFormSubmitOpen(true)
        parentMessage("The form has been rejected.")
        parentSeverity("success")
        alertOpen(true)
        resetState()
        refetchParent()
        refetch()
    },
    (error) => {
      setIsLoading(false)
      console.log(error)
    },
  )   

  const handleApprove = () => {
    setIsLoading(true)
    approveForm({
      id,
      notes,
      signature_name: name,
      understanding_checked: checked,
      total_amount: total
    })
  }

  const handleReject = () => {
    setIsLoading(true)
    rejectForm({
      id,
      notes,
    })
  }

  const resetState = () => {
    setChecked(false)
    setNotes('')
    setName('')
    setNotesError(false)
    setNotesErrorDescrption('')
    setNameError(false)
    setCheckboxError(false)
    setIsLoading(false)
  }

  const onNotesChange = (event) => {
    setNotes(event.target.value)
    if (event.target.value.length > 4000) {
      setNotesError(true)
      setNotesErrorDescrption('Notes must be less than 4000 characters')
    } else {
      setNotesError(false)
      setNotesErrorDescrption('')
    }
  }

  const handleChecked = () => {
    if (!checked) {
      setCheckboxError(false)
    }
    setChecked(!checked)
  }

  const handleGetDocument = async() => {
    setDocumentLoading(true)
    try {
      const response = await documentClient.get(`/roster/${id}/document`);
      const blob = new Blob([response.data], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);
      window.open(url);
      window.focus();
    } catch (error) {
      console.log(error)
      setAlertMessage("Error retrieving document. If the problem persists, please contact support.")
      setAlertSeverity("error")
      setShowAlert(true)
    }
    setDocumentLoading(false)
  }

  const calculateTotal = useCallback(() => {
    let total = 0;
    if (!duesFees) {
      return total
    }
    if (duesFees.spd_id) {
      total += duesFees.spd_active_members * duesFees.international_dues
      total += duesFees.spd_liablitity * (duesFees.active_chapters + 1)
      total += duesFees.initiation_fee * duesFees.spd_initiated
      total += duesFees.reinstatement_fee * duesFees.spd_reinstated
      total += duesFees.tech_fee * duesFees.active_chapters
      total += duesFees.spd_insurance
      total += duesFees.spd_bond
      total += duesFees.spd_qb
      return total
    } else {
      total += duesFees.state_dues * activeMembers.length
      total += duesFees.international_dues * activeMembers.length
      total += duesFees.initiation_fee * totalInitiated.length
      total += duesFees.reinstatement_fee * totalReinstated.length
      if (duesFees.disbanded === false) {
        total += duesFees.tech_fee
        duesFees.others.forEach(item => {
          total += item.per_member_fee > 0 ? item.per_member_fee * activeMembers.length : item.per_chapter_fee;
        })
      }
      return total
    }
  }, [duesFees, activeMembers.length, totalInitiated.length, totalReinstated.length])

  useEffect(() => {
    setTotal(calculateTotal)
  }, [activeMembers.length, duesFees, calculateTotal])

  const handleSubmit = (e) => {
    e.preventDefault()
    let valid = true
    if (notes.length > 4000) {
      setNotesError(true)
      setNotesErrorDescrption('Notes must be less than 4000 characters')
      valid = false
    }
    if (!checked) {
      setCheckboxError(true)
      valid = false
    }
    if (name !== `${localStorage.getItem('first_name')} ${localStorage.getItem('last_name')}`) {
      setNameError(true)
      valid = false
    } else {
      setNameError(false)
    }
    if (valid) {
      handleApprove()
    }
  }

  const onReject = (e) => {
    e.preventDefault()
    let valid = true
    if (notes.length === 0) {
      setNotesError(true)
      setNotesErrorDescrption('Notes are Required')
      valid = false
    } else if (notes.length > 4000) {
      setNotesError(true)
      setNotesErrorDescrption('Notes must be less than 4000 characters')
      valid = false
    }
    if (valid) {
      handleReject()
    }
  }

  const handleNameChange = (event) => {
    setName(event.target.value)
    if (event.target.value === `${localStorage.getItem('first_name')} ${localStorage.getItem('last_name')}`) {
      setNameError(false)
    }
  }

  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth' // Optional: Adds smooth scrolling animation
    });
  };

  if (isFetching) {
    return (
      <Grid container>
        <Typography variant="h5">Loading...</Typography>
    </Grid>)
  } else {
  return (
    <Grid container>
      <Grid item xs={12} sm={10} paddingBottom>
        <Typography variant="h5" color="primary">
            {duesFees?.spd_id ? (
              `${duesFees?.dues_name}`
            ) : (
              `${duesFees?.chapter_name}\\${duesFees?.chapter_state} ${duesFees?.dues_name} Chapter`
            )}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={2} paddingBottom textAlign={'right'}>
        <LoadingButton
          variant="contained"
          color="primary"
          loading={documentLoading}
          disabled={documentLoading}
          size='small'
          onClick={handleGetDocument}>
          View Document
        </LoadingButton>
      </Grid>
      {irsForm && (
      <Grid item xs={12} textAlign={'right'} paddingBottom={2}>
        <Button
          variant="contained"
          color='success'
          onClick={() => window.location.href = `/irs/${irsForm.id}`}
          size='small'>
            {irsForm.assignee_id === localStorage.getItem('user_id') ? 'Complete IRS-LC' : 'View IRS-LC'}
        </Button>
      </Grid>)}
      {duesFees?.spd_id && (
        <DuesRow
          id="state-dues-description"
          description={`Number of Active Chapters Reporting March 1, 2025`}
          amount={duesFees?.active_chapters}
        />
      )}
      <DuesRow
        id="state-dues"
        description="Number of Active Members March 1, 2025"
        amount={duesFees?.spd_id ? duesFees?.spd_active_members : activeMembers.length}
        paddingBottom={true}
      />
      {duesFees?.spd_id ? (
        <DuesRow
        id="state-dues-header"
        description={`Amount of dues payable to International Chapter per active member:`}
        descriptionWeight='bold'
        descriptionPrefix='I.'
      />
      ) : (
        <DuesRow
          id="state-dues-header"
          description={`Amount of dues payable to ${duesFees?.dues_name} Chapter per active member:`}
          descriptionWeight='bold'
          descriptionPrefix='I.'
        />
      )}
      {!duesFees?.spd_id && (
        <DuesRow
          id="state-dues-description"
          description={`${duesFees?.dues_name} Chapter dues of $${duesFees?.state_dues.toFixed(2)}`}
          amount={(duesFees?.state_dues * activeMembers.length)}
          amountPrefix='$'
        />
      )}
      <DuesRow
        id="state-dues-description"
        description={`International Chapter dues of $${duesFees.international_dues.toFixed(2)} (includes $${duesFees.cottey_college.toFixed(2)} for Cottey College)`}
        amount={duesFees?.spd_id ? (duesFees?.international_dues * duesFees.spd_active_members) : (duesFees?.international_dues * activeMembers.length)}
        amountPrefix='$'
        paddingBottom={true}
      />
      <DuesRow
        id="state-dues-header"
        description={`Initiation and Reinstatement Fees:`}
        descriptionWeight='bold'
        descriptionPrefix='II.'
      />
      <DuesRow
        id="state-dues-description"
        description={`$${duesFees.initiation_fee.toFixed(2)} for each ${duesFees.spd_id ? duesFees.spd_initiated : totalInitiated.length} members initiated 03/01/2024 to 02/28/2025`}
        amount={duesFees?.spd_id ? (duesFees?.initiation_fee * duesFees.spd_initiated) : (duesFees?.initiation_fee * totalInitiated.length)}
        amountPrefix='$'
        tooltipData={duesFees.spd_id ? null : totalInitiated}
      />
      <DuesRow
        id="state-dues-description"
        description={`$${duesFees.reinstatement_fee.toFixed(2)} for each ${duesFees.spd_id ? duesFees.spd_reinstated : totalReinstated.length} members reinstated 03/01/2024 to 02/28/2025`}
        amount={duesFees?.spd_id ? (duesFees?.initiation_fee * duesFees.spd_reinstated) : (duesFees?.reinstatement_fee * totalReinstated.length)}
        amountPrefix='$'
        paddingBottom={true}
        tooltipData={duesFees.spd_id ? null : totalReinstated}
      />
      <DuesRow
        id="state-dues-header"
        description={`Supply/Resource/Technology Payment`}
        descriptionWeight='bold'
        amount={duesFees?.spd_id ? (duesFees?.tech_fee * duesFees?.active_chapters) : duesFees?.disbanded ? 0 :  duesFees?.tech_fee}
        amountPrefix='$'
        paddingBottom={true}
        descriptionPrefix='III.'
        toolTipButton={<TooltipWrapper message={"Covers costs for International Chapter to provide technology, materials and supplies to each local chapter."} id='tech-fee-tooltip'/>}
      />
      {duesFees?.spd_id ? (
        <Grid item container>
          <DuesRow
            id="state-dues-header"
            description={`Liability Insurance, $${duesFees?.spd_liablitity} per active chapter as of 3/1/2025:`}
            descriptionWeight='bold'
            descriptionPrefix='IV.'
          />
          <DuesRow
            id="state-dues-description"
            description={`${duesFees?.active_chapters} active local chapters + 1 ${duesFees?.remittance_to} chapter`}
            amount={(duesFees?.spd_liablitity * (duesFees?.active_chapters + 1))}
            amountPrefix='$'
          />
        </Grid>
      ) : (
        <Grid item container>
          <DuesRow
              id="state-dues-header"
              description={`Other items due (refer to ${duesFees?.dues_name} Chapter bylaws):`}
              descriptionWeight='bold'
              descriptionPrefix='IV.'
              amount={duesFees?.disbanded || duesFees?.others.length === 0 ? 0 : null}
              amountPrefix={duesFees?.disbanded || duesFees?.others.length === 0 ? '$' : null}
            />
            {!duesFees?.disbanded && duesFees?.others.map((item, index) => (
              <DuesRow
                key={index}
                id={`state-dues-description-${index}`}
                description={item.per_member_fee > 0 ? `${item.description} ($${item.per_member_fee.toFixed(2)} per member)` : `${item.description} ($${item.per_chapter_fee.toFixed(2)} per chapter)`}
                amount={item.per_member_fee > 0 ? (item.per_member_fee * activeMembers.length) : item.per_chapter_fee}
                amountPrefix='$'
              />
            ))}
        </Grid>)}
      <Grid item xs={12} paddingBottom={2}/>
      {duesFees?.spd_id ? (
        <DuesRow
          id="state-dues-header"
          description={`Directors & Officers Insurance Premium:`}
          descriptionWeight='bold'
          descriptionPrefix='V.'
          paddingBottom={true}
          amount={duesFees?.spd_insurance}
          amountPrefix='$'/>
      ) : (
        <DuesRow
          id="state-dues-header"
          description={`Total Remittance`}
          descriptionWeight='bold'
          descriptionPrefix='V.'
          paddingBottom={true}
          amount={total}
          amountPrefix='$'/>
      )}

      {duesFees?.spd_id && (
        <Grid item container>
          <DuesRow
            id="state-dues-header"
            description={`Fidelity Bond Insurance:`}
            descriptionWeight='bold'
            descriptionPrefix='VI.'/>
          <DuesRow
            id="state-dues-header"
            description={duesFees?.officers_covered ? duesFees?.officers_covered : 'N/A'}
            paddingBottom={true}
            amount={duesFees?.spd_bond}
            amountPrefix='$'/>
          <DuesRow
            id="state-dues-header"
            description={`QuickBooks Usage Fee:`}
            descriptionWeight='bold'
            descriptionPrefix='VII.'
            paddingBottom={true}
            amount={duesFees?.spd_qb}
            amountPrefix='$'/>
          <DuesRow
            id="state-dues-header"
            description={`Total Remmittance`}
            descriptionWeight='bold'
            descriptionPrefix='VIII.'
            paddingBottom={true}
            amount={total}
            amountPrefix='$'/>
        </Grid>
      )}
      
      {!duesFees?.spd_id && (
        <Grid item container>
          <Grid item xs={12}>
            <Typography variant="body2" paddingBottom={2}>
              Total remittance payable to {duesFees?.dues_name} Chapter.
              Send check for total dues and fees along with a copy of this form to the address
              shown below. Use a separate check and project donations form if forwarding project
              gifts at this time.
            </Typography>
          </Grid>
          <Grid item xs={12} textAlign={'center'} paddingBottom={3}>
            <Typography variant="body2" fontWeight='bold'>
              Remittance Address:
            </Typography>
            <Typography variant="body2">
              {duesFees?.remittance_addressee}
            </Typography>
            <Typography variant="body2">
              {duesFees?.remittance_address1}
            </Typography>
            {duesFees?.remittance_address2 && (
              <Typography variant="body2">
                {duesFees?.remittance_address2}
              </Typography>)}
            <Typography variant="body2">
              {duesFees?.remittance_city}, {duesFees?.remittance_state} {duesFees?.remittance_zip}
            </Typography>
            <Typography variant="body2">
              {duesFees?.remittance_country}
            </Typography>
          </Grid>
        </Grid>
      )}
      <Grid item xs={12}>
        <Typography variant="body2" paddingBottom={2}>
          Add any additional notes.  {![formStates.LCTreas, formStates.SpdTreas].includes(formStateId) && ' If the form is being rejected, please provide an explanation in the box below.'}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <TextField
          id="detailed-notes"
          label="Explanation / Notes"
          multiline
          disabled={!editable}
          error={notesError}
          helperText={notesErrorDescrption}
          value={notes}
          onChange={onNotesChange}
          rows={3}
          fullWidth/>
      </Grid>
      {duesFees?.notes.length > 0 && (
        <Grid item xs={12} paddingTop={2}>
          <Typography variant="body1" fontWeight={'bold'}>
            Previous Notes:
          </Typography>
        </Grid>
      )}
      {duesFees?.notes.map((note, index) => (
        <Grid item container key={index} xs={12}>
          <Grid item xs={12} sm={12} md={5} lg={4}>
            <Typography variant="body1">
              {note.created_by} on {dayjs(note.created_at).format('MM/DD/YYYY hh:mm A')}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={7} lg={8}>
            <Typography variant="body1">
              {note.note}
            </Typography>
          </Grid>
        </Grid>
      ))}
      <Grid item xs={12} padding>
        <FormControl error={!!checkboxError}>
          <FormControlLabel
            required
            disabled={!editable}
            control={
              <Checkbox
                required
                disabled={!editable}
                checked={checked}
                onChange={handleChecked}
                size={'large'}
              />
            }
            label="I hereby attest that the information above and the Roster and Dues are accurate and balanced."
          />
        {checkboxError && (
          <FormHelperText error>
            You must check the box to submit the form.
          </FormHelperText>)}
        </FormControl>
      </Grid>
      <Grid item xs={12} paddingTop={2}>
        <Typography variant="body1" fontWeight={'bold'}>
          Electronic Signature:
        </Typography>
      </Grid>
      <Grid item xs={12} paddingBottom={2}>
        <Typography variant="body2">
          Please type your name in the box. The name you enter must match the name shown below the box, including punctuation and spacing.
        </Typography>
      </Grid>
      
      <Grid item xs={12} sm={8} md={5} lg={4}>
        <TextField
          id="name"
          label="Name"
          required
          fullWidth
          disabled={!editable}
          value={name}
          error={nameError}
          helperText={nameError ? 'Name must match your name below' : ''}
          onChange={handleNameChange}
          />
      </Grid>
      <Grid item lg={8} md={7} sm={4} xs={12}/>
      <Grid item xs={12} paddingTop={1}>
        <Typography variant="body2">
          {localStorage.getItem('first_name')} {localStorage.getItem('last_name')}
        </Typography>
      </Grid>
      {spdApprovable ? (
        <Grid item xs={12} textAlign={'right'}>
          <ButtonGroup>
            <Tooltip title="Approves the form and sends it to the next step." placement='top'>
              <Button
                variant="contained"
                color="primary"
                disabled={!editable || isLoading}
                sx={{fontWeight: 'bold'}}
                onClick={handleSubmit}>
                Submit as Approved
              </Button>
            </Tooltip>
            {![formStates.LCTreas, formStates.SpdTreas].includes(formStateId) && (
              <Tooltip title="Rejects the form sending it to a member to fix." placement='top'>
                <Button
                  variant="contained"
                  color="warning"
                  disabled={!editable || isLoading}
                  sx={{fontWeight: 'bold'}}
                  onClick={onReject}>
                  Submit as Rejection
                </Button>
              </Tooltip>)}
          </ButtonGroup>
        </Grid>
      ) : (
        <Grid item xs={12} textAlign={'right'}>
          <Typography color='primary' variant='h6' fontWeight={'bold'} textAlign={'center'}>
            Local Chapter Forms are not completed yet. Please complete the Local Chapter Forms first.
          </Typography>
        </Grid>
      )}
      <Grid item xs={12}>
        {showAlert && alertSeverity === 'error' && (
          <AlertItem
            severity={alertSeverity}
            description={alertMessage}
            onClose={() => setShowAlert(false)} />
        )}
      </Grid>
    </Grid>
  )}
}