import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Grid, ButtonGroup, Divider, Typography } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import QuestionLine from '../../Components/QuestionLine';
import { irsSubmitAnswers } from '../../endpoints/annualReports';
import { useApiSend } from '../../utils/httpClient';
import { formatCurrency } from '../../utils/formatters';


const sectionQuestions = {
    question1: '8f6f9bb7dbf944f58295ca6599d8419c',
    question2: '1885ea03cb094fe8847ba269a8b07c61',
    question3: 'd94ef3e0e30a45c6a15134b5521a6387',
    question4: 'fd625af812764daa954dd2b8af089aa0',
}

export default function BedNBreakfast ({questions, refetch, editable, alertOpen, alertMessage,
                                        alertSeverity, userEditing, updateUserEditing}) {
    const formValid = useRef({
        question1: true, question2: true, question3: true, question4: true,
        question5: true, question6: true, question7: true,
    });
    const [question1, setQuestion1] = useState(null);
    const [question1Error, setQuestion1Error] = useState(null);
    const [question2, setQuestion2] = useState(null);
    const [question3, setQuestion3] = useState(null);
    const [question4, setQuestion4] = useState(null);
    const [question4Error, setQuestion4Error] = useState(null);
    const [answersSet, setAnswersSet] = useState(false);

    const { id } = useParams();

    const handleScrollToTop = () => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
      };

    const { mutate } = useApiSend(
        irsSubmitAnswers,
        (data) => {
            handleScrollToTop()
            alertMessage("Bed & Breakfast successfully updated.")
            alertSeverity("success")
            alertOpen()
            refetch()
        },
        (error) => {
            console.log(error)
            alertMessage("Error updating Bed & Breakfast. If the problem persists, please contact support.")
            alertSeverity("error")
            alertOpen()
        }
    )

    const setAnswers = useCallback(() => {
        questions.forEach((answers) => {
            if (answers.question_id === sectionQuestions.question1) {
                setQuestion1(answers.answer)
            } else if (answers.question_id === sectionQuestions.question2) {
                setQuestion2(answers.answer)
            } else if (answers.question_id === sectionQuestions.question3) {
                setQuestion3(answers.answer)
            } else if (answers.question_id === sectionQuestions.question4) {
                setQuestion4(answers.answer)
            }
        })
    }, [questions])

    useEffect(() => {
        setAnswers()
        setAnswersSet(true)
    }, [questions, setAnswers])

    const answersUpdated = useCallback(() => {
        let answersUpdated = false
        questions.forEach((answers) => {
            if (answers.question_id === sectionQuestions.question1 && answers.answer !== question1) {
                answersUpdated = true
            } else if (answers.question_id === sectionQuestions.question2 && answers.answer !== question2) {
                answersUpdated = true
            } else if (answers.question_id === sectionQuestions.question3 && answers.answer !== question3) {
                answersUpdated = true
            } else if (answers.question_id === sectionQuestions.question4 && answers.answer !== question4) {
                answersUpdated = true
            }
        })
        return answersUpdated
    }, [questions, question1, question2, question3, question4])

    useEffect(() => {
        if (answersUpdated() && answersSet && !userEditing) {
            updateUserEditing(true)
        }
    }, [answersUpdated, updateUserEditing, answersSet, userEditing])

    const handleQuestion1Change = (value) => {
        if (value === true && question1 !== value) {
            setQuestion2(null)
            setQuestion3(null)
            setQuestion4(null)
        }
        setQuestion1(value)
        if (value !== null || value !== '1') {
            setQuestion1Error(null)
        } else {
            setQuestion1Error('Please select an answer')
        }
    }

    const handleQuestion4Change = (value) => {
        setQuestion4(value)
        if (value !== null && value !== '1') {
            setQuestion4Error(null)
        } else {
            setQuestion4Error('Please select an answer')
        }
    }

    const onSubmitForm = (completed) => {
        let formValidCopy = { ...formValid.current }
        let validForm = true
        if (!formValidCopy.question1 || question1 === null || question1 === '1') {
            validForm = false
            setQuestion1Error('Please select an answer')
        }
        if (question1 === true) {
            if (!formValidCopy.question2) {
                validForm = false
            }
            if (!formValidCopy.question3) {
                validForm = false
            }
            if (parseFloat(question2 || 0) - parseFloat(question3 || 0) > 0) {
                if (!formValidCopy.question4 || question4 === null || question4 === '1') {
                    validForm = false
                    setQuestion4Error('Please select an answer')
                }
            }
        }

        if (!validForm) {
            console.log('invalid form')
            return
        }
        let answers = [
            {
                question_id: questions.filter(q => q.question_id === sectionQuestions.question1)[0]?.id,
                answer: question1 === null ? null : question1.toString(),
            },
            {
                question_id: questions.filter(q => q.question_id === sectionQuestions.question2)[0]?.id,
                answer: question2 === null ? null : question2.toString(),
            },
            {
                question_id: questions.filter(q => q.question_id === sectionQuestions.question3)[0]?.id,
                answer: question3 === null ? null : question3.toString(),
            },
            {
                question_id: questions.filter(q => q.question_id === sectionQuestions.question4)[0]?.id,
                answer: question4 === null ? null : question4.toString(),
            },
        ]
        mutate({
            id: id,
            answers: answers,
            section_completed: completed,
            section: 'bed_and_breakfast',
        })
    }

    return (
        <Grid container>
            
            {questions.filter(q => q.question_id === sectionQuestions.question1).map((question) => (
                <QuestionLine
                    key={1}
                    questionNumber={1}
                    question={question.question}
                    questionId={question.question_id}
                    answerType={question.answer_type}
                    disabled={!editable}
                    answer={question1}
                    onChange={(value) => handleQuestion1Change(value)}
                    errorOverride={question1Error}
                    onFormChange={(isValid) => formValid.current.question1 = isValid}
                />))}
            {question1 === true && 
                <Grid item container>
                    {questions.filter(q => q.question_id === sectionQuestions.question2).map((question) => (
                        <QuestionLine
                            key={2}
                            questionNumber={2}
                            question={question.question}
                            questionId={question.question_id}
                            answerType={question.answer_type}
                            disabled={!editable}
                            answer={question2}
                            onChange={(value) => setQuestion2(value)}
                            onFormChange={(isValid) => formValid.current.question2 = isValid}
                        />))}
                    {questions.filter(q => q.question_id === sectionQuestions.question3).map((question) => (
                        <QuestionLine
                            key={5}
                            questionNumber={3}
                            question={question.question}
                            questionId={question.question_id}
                            answerType={question.answer_type}
                            disabled={!editable}
                            answer={question3}
                            onChange={(value) => setQuestion3(value)}
                            onFormChange={(isValid) => formValid.current.question3 = isValid}
                        />))}
                <Grid item xs={12} padding={2}>
                    <Divider />
                </Grid>
                <Grid item xs={12} textAlign={'right'}>
                    <Typography variant="body1" fontWeight={'bold'} >
                        Net Income: {formatCurrency(parseFloat(question2 || 0) - parseFloat(question3 || 0))}
                    </Typography>
                </Grid>
                {parseFloat(question2 || 0) - parseFloat(question3 || 0) > 0 &&
                <Grid item container>
                    <Grid item xs={12} padding={2}>
                        <Divider />
                    </Grid>
                    <Grid item xs={12} padding={2}>
                        <Typography variant="body1" fontWeight={'bold'} >
                            The net income from a local chapter's B&B service must be used exclusively for P.E.O. charities qualified under U.S.
                            Internal Revenue code section 501(c)(3), including International Chapter P.E.O. projects, funds held in the P.E.O.
                            Foundation, and/or other charitable state or district chapter projects.
                        </Typography>
                    </Grid>
                        {questions.filter(q => q.question_id === sectionQuestions.question4).map((question) => (
                            <QuestionLine
                                key={6}
                                questionNumber={4}
                                question={question.question}
                                questionId={question.question_id}
                                answerType={question.answer_type}
                                disabled={!editable}
                                answer={question4}
                                onChange={(value) => handleQuestion4Change(value)}
                                errorOverride={question4Error}
                                onFormChange={(isValid) => formValid.current.question4 = isValid}
                            />))}
                </Grid>}
            </Grid>}
            <Grid item xs={12} padding={2}>
                <Divider />
            </Grid>
            <Grid item xs={12} textAlign={'right'} padding>
                <ButtonGroup>
                    <LoadingButton
                        color="green"
                        size='small'
                        variant='contained'
                        loading={false}
                        sx={{ fontWeight: 'bold' }}
                        disabled={!editable}
                        onClick={() => onSubmitForm(true)}
                    >
                        Save and Complete B & B
                    </LoadingButton>
                </ButtonGroup>
            </Grid>
        </Grid>
    );
}