import React, {useState} from 'react';
import { Grid, Typography, Link, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';
import UndoIcon from '@mui/icons-material/Undo';
import ArticleIcon from '@mui/icons-material/Article';


export default function PageHeader({title, rightDom, leftDom, subtitle, showDashboardLink=true, showTraining=true, traningLink='training'}) {
    const [showInstructionsDialog, setShowInstructionsDialog] = useState(false);

    const handleRouteToInstructions = () => {
        let baseUrl = window.location.origin;
        let newUrl = `${baseUrl}${baseUrl.endsWith('/') ? '' : '/'}${traningLink}`;

        const newWindow = window.open(newUrl, '_blank', 'nopener,noreferrer');
        if (newWindow) newWindow.opener = null;
    }

    const handleClose = () => {
        setShowInstructionsDialog(false);
    }

    return (
        <Grid container>
            <Grid item container justifyContent={'space-between'} alignContent={'center'} alignItems={'center'}>
                {showDashboardLink && (
                    <Link href='/' fontSize={"medium"} paddingLeft={1}>
                        <UndoIcon
                            fontSize="small"
                            sx={{ pt: 1}}/>  Back to Dashboard
                    </Link>
                )}
                {showTraining && showDashboardLink && (
                    <Link fontSize={"medium"} paddingRight={1} onClick={() => setShowInstructionsDialog(true)}> 
                        Show Instructions
                        <ArticleIcon
                            fontSize="small"
                            sx={{ pt: 1, pl: 1}}/>
                    </Link>
                )}

            </Grid>
            <Grid item container paddingTop={2} paddingBottom={3}>
                <Grid item xs={12} sm={3} md={2}>
                    {leftDom}
                </Grid>
                <Grid item xs={12} sm={6} md={8} textAlign={'center'}>
                    <Typography variant='h4' color="primary">
                        {title}
                    </Typography>
                    {subtitle && (
                        <Typography variant='h5' color="primary">
                            {subtitle}
                        </Typography>
                    )}
                </Grid>
                <Grid item xs={12} sm={3} md={2} textAlign={'right'}>
                    {rightDom}
                </Grid>
            </Grid>
            <Dialog
                open={showInstructionsDialog}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                {"How would you like to view the instructions?"}
                </DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Instructions can be viewed via video or document.
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={handleRouteToInstructions}>
                    Page Video
                </Button>
                <Button onClick={handleClose}>
                    Full Document
                </Button>
                </DialogActions>
            </Dialog>
        </Grid>
    )
}