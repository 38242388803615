import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Grid, TextField, Skeleton, Switch,
         InputAdornment, FormControlLabel, IconButton } from '@mui/material';
import { useApiGet, useApiSend } from '../../utils/httpClient';
import { getIRSDetail, updateIrsForm } from '../../endpoints/annualReports';
import TabInfo from './TabInfo';
import EditIcon from '@mui/icons-material/Edit';
import HistoryModal from '../../Components/HistoryModal';
import LoadingButton from '@mui/lab/LoadingButton';
import IRSStateSelector from '../../Components/IRSStateSelector';
import UserSelectModal from '../../Components/UserSearchModal';
import { applications } from '../../utils/variables';
import AlertItem from '../../Components/AlertItem';
import PageHeader from '../../Components/PageHeader';
import ConfirmationDialog from '../../Components/ConfirmationDialog';


export default function IRSDetail () {
  let { id } = useParams();
  const [hideContent, setHideContent] = useState(true);
  const [stateEdit, setStateEdit] = useState(false);
  const [assignedEdit, setAssignedEdit] = useState(false);
  const [showHistory, setShowHistory] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertSeverity, setAlertSeverity] = useState('success');
  const [formSubmitOpen, setFormSubmitOpen] = useState(false);
  const [keepOpen, setKeepOpen] = useState(false);
  const [formSubmitMessage, setFormSubmitMessage] = useState('');

  const { data, isFetching, isSuccess, refetch } = useApiGet(
    ['roster', id],
    getIRSDetail,
    {
      enabled: true,
      retry: 1,
      refetchInterval: 15 * 60 * 1000,  // The data will be refetched every 15 minutes
    },
    { id }
  )

  const { mutate } = useApiSend(
    updateIrsForm,
    (data) => {
      setAlertMessage("IRS Form successfully updated.")
      setAlertSeverity("success")
      setShowAlert(true)
      refetch()
    },
    (error) => {
      console.log(error)
      setAlertMessage("Error updating IRS Form. If the problem persists, please contact support.")
      setAlertSeverity("error")
      setShowAlert(true)
    }
  )

  const onStateUpdate = (state) => {
    setStateEdit(false)
    if (state === data.state_id || state === 0) {
      return
    }
    mutate({
      id,
      status_id: data.status_id,
      state_id: state,
      assigned_to_id: data.assigned_to_id
    })
  }

  const onAssignedUpdate = (assigned) => {
    setAssignedEdit(false)
    if (assigned === data.assigned_to_id || assigned === 0) {
      return
    }
    mutate({
      id,
      status_id: data.status_id,
      state_id: data.state_id,
      assigned_to_id: assigned
    })
  }

  const allowEdit = useCallback(() => {
    return data?.assigned_to_id === localStorage.getItem('user_id')
  }, [data])

  useEffect(() => {
    if (isSuccess) {
      allowEdit()
    }
  }, [data, allowEdit, isSuccess])

  const isAdmin = () => {
    return localStorage.getItem('apps').includes(applications.AnnualReportAdmin)
  }

  return (
    isFetching ? <Skeleton variant="text" /> : (
    <Grid container>
      <Grid item xs={12}>
        {showAlert && (
          <AlertItem
            severity={alertSeverity}
            description={alertMessage}
            keepOpen={keepOpen}
            onClose={() => setShowAlert(false)} />
        )}
      </Grid>
      <Grid item xs={12}>
        <PageHeader
          title={`${data?.chapter_name}/${data?.chapter_state} IRS-LC Form`}
          rightDom={isAdmin() ? <FormControlLabel
                                  control={<Switch checked={hideContent} onChange={() => setHideContent(!hideContent)} />}
                                  label={"Hide Info"}/>
                              : null}/>
      </Grid>
      {isAdmin() && !hideContent && (
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={4} md={3} padding={2}>
            {isFetching ? <Skeleton variant="text" /> : (
            <TextField
              id="chapter-name"
              label="Chapter"
              InputProps={{
                readOnly: true,
              }}
              fullWidth
              value={data.chapter_name}
              variant="standard"/>)}
          </Grid>
          <Grid item xs={4} md={3} padding={2}>
            {isFetching ? <Skeleton variant="text" /> : (
            <TextField
              id="chapter-state"
              label="Chapter State / District"
              fullWidth
              InputProps={{
                readOnly: true,
              }}
              value={data.chapter_state}
              variant="standard"/>)}
          </Grid>
          <Grid item xs={4} md={3} padding={2}>
            <TextField
              id="current-status"
              label="Current Status"
              fullWidth
              value={data?.status}
              InputProps={{readOnly: true}}
              variant="standard"/>
          </Grid>
          <Grid item xs={4} md={3} padding={2}>
            {stateEdit ? (
              <IRSStateSelector
                formState={data?.state_id}
                handleStateChange={onStateUpdate}
                onClose={() => setStateEdit(false)}
              />
            ) : (
            <TextField
              id="current-state"
              label="Current Step"
              fullWidth
              value={data?.state}
              InputProps={{
                endAdornment: <InputAdornment position="end">
                  <IconButton color={'blue'} disabled={true} onClick={() => setStateEdit(true)}>
                    <EditIcon/>
                  </IconButton>
                </InputAdornment>,
              }}
              variant="standard"/>)}
          </Grid>
          <Grid item xs={4} md={3} padding={2}>
            <TextField
              id="assigned-to"
              label="Assigned To"
              fullWidth
              value={data?.assignee}
              InputProps={{
                endAdornment: <InputAdornment position="end">
                  <IconButton color={'blue'} onClick={() => setAssignedEdit(true)}>
                    <EditIcon/>
                  </IconButton>
                </InputAdornment>,
              }}
              variant="standard"/>
            <UserSelectModal
              modalOpen={assignedEdit}
              setModalOpen={setAssignedEdit}
              setUser={onAssignedUpdate}/>
          </Grid>
          <Grid item xs={4} md={9} padding={2} textAlign={'right'}>
            <LoadingButton
                variant="contained"
                color="primary"
                size='small'
                sx={{ fontWeight: 'bold' }}
                onClick={() => setShowHistory(true)}>
                Form History
              </LoadingButton>
            </Grid>
        </Grid>
      </Grid>)}
      {data &&
      <TabInfo
        setFormSubmitMessage={setFormSubmitMessage}
        setFormSubmitOpen={setFormSubmitOpen}
        setParentKeepOpen={setKeepOpen}
        parentAlertMessage={setAlertMessage}
        parentAlertSeverity={setAlertSeverity}
        parentShowAlert={setShowAlert}
        fedIdNumber={data.fed_id_number}
        previousTotal={data.previous_ending_balance}
        notes={data.notes}
        assigned={allowEdit()}
        assignedTo={data.assigned_to_id}
        currentState={data.state_id}
        currentStatus={data.status_id}
        refetchParent={refetch}/>}
      <HistoryModal
        modalOpen={showHistory}
        setModalOpen={() => setShowHistory(false)}
        irsId={id}/>
      <ConfirmationDialog
        open={formSubmitOpen}
        handleOk={() => setFormSubmitOpen(false)}
        title={formSubmitMessage.includes('rejected') ? 'Form Rejected' : 'Form Submitted'}
        message={formSubmitMessage}
        confirmText={'Close'}/>
    </Grid>)
  );
}