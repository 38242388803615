import React, {useState} from 'react';
import { approveRosterForm } from '../../endpoints/annualReports';
import { useApiSend } from '../../utils/httpClient';
import { LoadingButton } from '@mui/lab';
import { Tooltip } from '@mui/material';
import { formStates } from '../../utils/variables';


export default function ApprovalButton({refetchParent, id, alertMessage,
                                        alertOpen, alertSeverity, disabled=false,
                                        formStateId=null, setFormSubmitOpen=null,
                                        setFormSubmitMessage=null, text='Save and Continue'}) {
    const [loading, setLoading] = useState(false)

    const checkMessageSet = () => {
      if (formStateId && setFormSubmitOpen && setFormSubmitMessage) {
        if (formStateId === formStates.SpdSec) {
          setFormSubmitMessage('Thank you for submitting your Roster Form.')
          setFormSubmitOpen(true)
        }
      }
    }

    const { mutate } = useApiSend(
      approveRosterForm,
      (data) => {
        alertMessage("Roster successfully approved.")
        alertSeverity("success")
        alertOpen()
        checkMessageSet()
        setTimeout(() => {
          if (data?.parent_update) {
            refetchParent()
          }
          setLoading(false)
        }, 1000)
      },
      (error) => {
        alertMessage("Error approving roster. If the problem persists, please contact support.")
        alertSeverity("error")
        alertOpen()
        console.log(error)
        setLoading(false)
      },
    )
  
    const handleButtonClick = () => {
      setLoading(true)
      mutate({id: id})
    }
    return (
      <Tooltip title="Completes the current section (tab), moving the process to the next step." placement='top-end'>
        <LoadingButton
          key='approve'
          color="green"
          size='small'
          disabled={disabled}
          variant="contained"
          loading={loading}
          sx={{ fontWeight: 'bold' }}
          onClick={() => handleButtonClick()}
        >
          {text}
        </LoadingButton>
      </Tooltip>
    )
  }