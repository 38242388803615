import React from 'react'
import { Select, MenuItem, OutlinedInput, FormControl, InputLabel, Skeleton } from '@mui/material'
import { useApiGet } from '../utils/httpClient'
import { getIrsStates } from '../endpoints/annualReports'


export default function IRSStateSelector ({formState, handleStateChange, onClose, label="Step"}) {
    const { data } = useApiGet(
      ['irs-states'],
      getIrsStates,
      {
        enabled: true,
        retry: 1,
      }
    )
  
    return (
        data ? (
        <FormControl fullWidth={true} variant="outlined" size='small'>
            <InputLabel id="formState">{label}</InputLabel>
            <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                fullWidth={true}
                value={formState}
                variant='standard'
                size='small'
                input={<OutlinedInput label={label} />}
                label={label}
                onClose={onClose}
                onChange={(event) => handleStateChange(event.target.value)}
            >
                <MenuItem value={'all'}>All</MenuItem>
                {data?.map((status) => (
                    <MenuItem key={status.id} value={status.id}>{status.name}</MenuItem>))}
            </Select>
        </FormControl>
        ) : (
            <Skeleton>
                <Select label="Step" />
            </Skeleton>
        )
    )
}